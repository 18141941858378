<div>
  <div class="aio_heading">
    <h2>{{pageTitle}}</h2>

    <button mat-raised-button class="primary_btn"
      routerLink="/leads/{{hotelId}}">
      {{'leads.back' | translate}}
    </button>
  </div>
  <mat-divider></mat-divider>

  <div class="container-fluid common_content">
    <form class="create_form shadow-sm" [formGroup]="leadsForm" *ngIf="leadsForm">
      <div class="input_group">

        <div>
          <label>{{'leads.type' | translate}}<span class="text-danger">*</span></label>
          <select class="form-control form-control-sm" formControlName="type">
            <option *ngFor="let type of types" [value]="type">{{type}}</option>
          </select>
        </div>

        <div>
          <label>{{'leads.status' | translate}}<span class="text-danger">*</span></label>
          <select class="form-control form-control-sm" formControlName="status">
              <option *ngFor="let status of statuses"
                [value]="status.value">
                {{status.name}}
              </option>
          </select>
        </div>

        <div *ngIf="!showRooms">
          <label>
            {{leadsForm.controls.type.value === 'Rooms' ? 'Checkin' : ' Date'}}
            <span class="text-danger">*</span>
          </label>
          <input type="date" class="form-control form-control-sm" formControlName="startDate">
        </div>

        <div *ngIf="!showRooms && leadsForm.controls.type.value === 'Rooms'">
          <label>
            {{'leads.checkout' | translate}}
            <span class="text-danger">*</span>
          </label>
          <input type="date" class="form-control form-control-sm" formControlName="endDate">
        </div>
  
        <div *ngIf="!showRooms">
          <label>{{'leads.guest_name' | translate}} <span class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-sm" formControlName="guestName" maxlength="64">
        </div>

        <div *ngIf="!showRooms">
          <label>{{'leads.pax' | translate}}</label>
          <input type="number" inputmode="numeric" class="form-control form-control-sm" formControlName="pax" maxlength="8">
        </div>

        <div *ngIf="!showRooms">
          <label>{{'leads.amount' | translate}} <span class="text-danger">*</span></label>
          <input type="number" inputmode="decimal" class="form-control form-control-sm" formControlName="totalAmount">
        </div>

        <div>
          <label>{{'leads.expiration_date' | translate}}</label>
          <input type="date" class="form-control form-control-sm" formControlName="expirationDate">
        </div>

        <div *ngIf="!showRooms">
          <label>{{'leads.phone' | translate}}</label>
          <input type="number" inputmode="tel" class="form-control form-control-sm" formControlName="phone" maxlength="15">
        </div>

        <div *ngIf="!showRooms">
          <label>{{'leads.email' | translate}}</label>
          <input type="text" class="form-control form-control-sm" formControlName="email" maxlength="64">
        </div>

        <div>
          <label>{{'leads.advance' | translate}}</label>
          <input type="number" inputmode="decimal" class="form-control form-control-sm" formControlName="advancePayment">
        </div>

        <div>
          <label>{{'leads.created_by' | translate}} <span class="text-danger">*</span></label>
          <input type="text" class="form-control form-control-sm" formControlName="createdBy" maxlength="64">
        </div>

        <div>
          <label>{{'leads.comments' | translate}}</label>
          <input type="text" class="form-control form-control-sm" formControlName="comments" maxlength="64">
        </div>

      </div>
      
      <div class="action_btns" *ngIf="!showRooms">
        <button mat-raised-button class="primary_btn"
          (click)="submit()"
          [ngClass]="{'disable_button': submitting}">
          {{mode  === 'new' ? 'Create' : 'Update'}}
        </button>
      </div>

      <app-create-group-booking *ngIf="showRooms"
        [hotelId]="hotelId"
        [leadsProps]="{status: leadsForm.controls.status.value, valid: leadsForm.valid, errors: leadsForm}"
        [mode]="mode"
        [payload]="payload"
        (reservationPayload)="onRoomsBooked($event)"
        >
      </app-create-group-booking>

    </form>
  
  </div>
</div>
