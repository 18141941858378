import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceIssueService {
  baseUrl = `${environment.apiUrl}/maintenance-issue`;

  constructor(private http: HttpClient) {}

  // getMaintenanceConfig(hotelId): Observable<any> {
  //   return this.http.get(
  //     `${environment.apiUrl}/hotels/${hotelId}/products/maintenance-issue`
  //   );
  // }

  getMaintenanceConfig(hotelId): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/fetch-maintenance-config-and-hotels/${hotelId}`
    );
  }

  newIssue(hotelId: string, body: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/${hotelId}`, body);
  }

  editIssue(hotelId: string, issueId, body: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${hotelId}/${issueId}`, body);
  }

  getIssues(hotelId: string, params: any, groupId = ''): Observable<any> {
    params['groupId'] = groupId;
    const httpParams = new HttpParams({
      fromObject: params,
    });
    return this.http.get(`${this.baseUrl}/${hotelId}`, { params: httpParams });
  }

  getIssueUpdate(hotelId: string, issueId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${hotelId}/${issueId}/issue-update`);
  }

  issueUpdate(hotelId: string, issueId: string, body: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/${hotelId}/${issueId}/issue-update`,
      body
    );
  }

  deleteIssue(hotelId: string, issueId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${hotelId}/${issueId}`);
  }

  getRoomNumbers(hotelId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/room-numbers/${hotelId}`);
  }

  getHousekeepingInfo(hotelId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fetch-housekeeping-data/${hotelId}`);
  }

  getLaundryInfo(hotelId: string, startDate, endDate, status): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fetch-laundry/${hotelId}`, {params:{'startDate': startDate, 'endDate': endDate, 'status': status}});
  }

  addLaundry(hotelId: string, data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/add-laundry/${hotelId}`, data);
  }

  updateLaundry(hotelId: string, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/update-laundry/${hotelId}`, data);
  }

  deleteLaundry(hotelId: string, laundryId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/delete-laundry/${hotelId}/${laundryId}`);
  }

  downloadLaundrySummary(hotelId, date): Observable<any> {
    return this.http.get(`${environment.apiUrl}/download-laundry-summary/${hotelId}`, {params:{'date': date}, responseType: 'blob'});
  }

  fetchLaundryStockDetails(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/fetch-laundry-stock/${hotelId}`);
  }

  createLostAndFoundEntry(hotelId: string, data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/create-lost-and-found/${hotelId}`, data);
  }

  fetchLostAndFoundItems(hotelId, fromDate, toDate, status): Observable<any>{
    return this.http.get(`${environment.apiUrl}/fetch-lost-and-found-entries/${hotelId}`, {params:{'fromDate': fromDate, 'toDate': toDate, 'status': status}})
  }

  deleteLostAndFoundEntry(hotelId: string, itemId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/delete-lost-and-found-entry/${hotelId}/${itemId}`);
  }
}
