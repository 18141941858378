<div>
  <div class="heading">
    <h2>{{ "pos.title" | translate }}</h2>
    <p *ngIf="jspmDownload.error">
      {{ "pos.install_jspm" | translate }}
      <a [attr.href]="jspmDownload.link" target="_blank">{{
        "pos.here" | translate
      }}</a>
    </p>
  </div>
  <mat-divider></mat-divider>
  <div class="container-fluid">

    <div class="first-row pt-1 pb-2 bg-white">
      <div>
        <label for="posname">{{ "pos.pos_name" | translate }}</label>
        <select
          class="form-control"
          id="posname"
          [(ngModel)]="posName"
          (change)="
            posName = $event.target.value; onPosChange($event.target.value)
          "
        >
          <option *ngFor="let name of posNames" [value]="name">
            {{ name }}
          </option>
        </select>
      </div>

      <div>
        <label for="From Date">{{ "pos.from_date" | translate }}:</label>
        <input
          type="date"
          class="form-control"
          id="from-date"
          [(ngModel)]="fromDate"
          (change)="getOrders()"
        />
      </div>
      
      <div>
        <label for="To Date">{{ "pos.to_date" | translate }}:</label>
        <input
          type="date"
          class="form-control"
          id="to-date"
          min="{{ fromDate }}"
          [(ngModel)]="toDate"
          (change)="getOrders()"
        />
      </div>

      <div class="mid-buttons">
        <div>
          <button mat-stroked-button
            class="active" (click)="orderView()">
            Order View
          </button>
        </div>
  
        <div>
          <button mat-stroked-button
            class="active" (click)="tableView()">
            Table View
          </button>
        </div>
      </div> 

      <div *ngIf="posName"
        [ngStyle]="{ 'margin-left': 'auto', display: 'flex' }" >
        <div style="display: flex; align-items: flex-end">
          <label></label>
          <div>
            <a (click)="openLogsPage()"  [ngStyle]="{ margin: '20px', 'font-size': '12px', cursor: 'pointer' }">
              <u>{{ "pos.logs" | translate }}</u>
            </a>
          </div>
        </div>
        <div>
          <label for="POS report" [ngStyle]="{ visibility: 'hidden' }">
            {{ "pos.report" | translate }}
          </label>
          <select class="form-control" [(ngModel)]="reportName">
            <option>{{ "pos.item_consumption" | translate }}</option>
            <option>{{ "pos.payments" | translate }}</option>
          </select>
        </div>
        <div style="display: flex; align-items: flex-end">
          <label></label>
          <div>
            <button
              mat-stroked-button
              class="active"
              [ngStyle]="{ margin: '0 5px', 'font-size': '12px' }"
              (click)="openReport(reportName)"
            >
              {{ "pos.report" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="top-section mt-2">
        <div class="grid-container">
          <div class="grid-item" id="sales">
            <div class="flexColumn">
              <span class="h5">Today's Sales</span>
              <span class="value">{{
                posDashData?.sales | currency : currencyCode
              }}</span>
            </div>
          </div>
          <div class="grid-item" id="mtd">
            <div class="flexColumn">
              <span class="h5">MTD Sales</span>
              <span class="value">{{
                posDashData?.mtd_sales | currency : currencyCode
              }}</span>
              <span
                [ngStyle]="{
                  'font-size': '10px',
                  position: 'absolute',
                  bottom: '0',
                  left: '3%',
                  color: '#292929'
                }"
              >
                {{ firstDay | date : "MMM dd" }} to
                {{ today | date : "MMM dd" }}
              </span>
            </div>
          </div>
          <div class="grid-item" id="orders">
            <div class="flexColumn">
              <span class="h5">No. of Orders</span>
              <span class="value">{{ posDashData?.no_of_orders }}</span>
            </div>
          </div>
          <div class="grid-item" id="average">
            <div class="flexColumn">
              <span class="h5">Avg Order Value</span>
              <span class="value">{{
                posDashData?.average_sales | currency : currencyCode
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mid-section mt-4 bg-white">
        <div class="head-row">
          <h3>{{ "pos.current_orders" | translate }}</h3>
          <div>
            <button
              *ngIf="posName"
              mat-stroked-button
              class="active small-btn"
              (click)="openOrder('new')"
            >
              {{ "pos.create" | translate }}
            </button>

            <button
              mat-stroked-button
              class="active small-btn"
              (click)="openComplimentary()"
            >
              {{ "pos.complimentary" | translate }}
            </button>

            <input
              id="search-bar"
              type="text"
              placeholder="{{ 'pos.search' | translate }} . . ."
              (input)="onSearch($event.target.value, 'unbilled')"
            />
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="table-container">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th>{{ "pos.date" | translate }}</th>
                <th>{{ "pos.order_id" | translate }}</th>
                <th>{{ "pos.details" | translate }}</th>
                <th>{{ "pos.order_amount" | translate }}</th>
                <th>{{ "pos.balance" | translate }}</th>
                <th [ngStyle]="{ width: '100px' }">
                  {{ "pos.action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of unBilledData">
                <td>{{ order.date | date : "dd MMM, h:mm a" }}</td>
                <td>{{ order.order_id }}</td>
                <td>{{ order.details }}</td>
                <td>{{ order.total_amount | currency : currencyCode }}</td>
                <td>{{ order.balance | currency : currencyCode }}</td>
                <td
                  class="action"
                  [ngStyle]="{ display: 'flex', 'border-bottom': 'none' }"
                >
                  <div class="d-flex justify-content-between align-items-center ">
                    <mat-icon
                      (click)="openOrder('edit', order)"
                      matTooltip="Edit order"
                      [hidden]="!showEditOrderOption">
                      edit
                    </mat-icon>
                    
                    <mat-icon (click)="viewBill(order)" matTooltip="View order">
                      visibility
                    </mat-icon>

                    <mat-icon
                      class="text-danger"
                      (click)="deleteOrder(order.invoice_id, order.order_id)"
                      matTooltip="Delete order"
                      [hidden]="!showDeleteOrderOption"
                    >
                    delete
                    </mat-icon>

                    <svg (click)="orderReadyToggle(order)"
                      [matTooltip]="order.status === 'ready' ? 'Mark Not Ready' : 'Mark Ready'"
                      xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" id="order-ready-icon">
                      <path [ngStyle]="order.status === 'ready' && {'fill': '#03c303'}"
                        d="M88 0C74.7 0 64 10.7 64 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C120.5 112.3 128 119.9 128 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C119.5 47.7 112 40.1 112 24c0-13.3-10.7-24-24-24zM32 192c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96H288c53 0 96-43 96-96h16c61.9 0 112-50.1 112-112s-50.1-112-112-112H352 32zm352 64h16c26.5 0 48 21.5 48 48s-21.5 48-48 48H384V256zM224 24c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C232.5 112.3 240 119.9 240 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C231.5 47.7 224 40.1 224 24z" />
                    </svg>

                    <mat-icon
                      (click)="promptKotPrint(order)">
                      print
                    </mat-icon>
                  </div>

                  <button
                    mat-stroked-button
                    class="active small-btn"
                    (click)="openOrder('bill', order)"
                    [disabled]="!showPOSBillOption"
                    matTooltip="Bill order"
                  >
                    {{ "pos.bill" | translate }}
                  </button>
                  <button
                    mat-stroked-button
                    class="active small-btn"
                    [disabled]="!showAddPaymentOption"
                    (click)="
                      goToAddPayment(order.invoice_id, order.balance, false)
                    "
                    matTooltip="Add payment"
                  >
                    {{ "pos.payment" | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mid-section mt-4 bg-white">
        <div class="head-row">
          <h3>{{ "pos.billed_orders" | translate }}</h3>
          <div>
            <input
              id="search-bar2"
              type="text"
              placeholder="{{ 'pos.search' | translate }} . . ."
              (input)="onSearch($event.target.value, 'billed')"
            />
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="table-container">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th>{{ "pos.date" | translate }}</th>
                <th>{{ "pos.invoice_no" | translate }}.</th>
                <th>{{ "pos.order_id" | translate }}</th>
                <th>{{ "pos.details" | translate }}</th>
                <th>{{ "pos.billed_amount" | translate }}</th>
                <th>{{ "pos.balance" | translate }}</th>
                <th [ngStyle]="{ width: '100px' }">
                  {{ "pos.action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of billedData">
                <td>{{ order.billed_date | date : "dd MMM, h:mm a" }}</td>
                <td>{{ order.invoice_num }}</td>
                <td>{{ order.order_id }}</td>
                <td>{{ order.details }}</td>
                <td>{{ order.total_amount | currency : currencyCode }}</td>
                <td>
                  {{ order.billedToRoom ? 0: (order.balance | currency : currencyCode) }}
                </td>
                <td
                  class="action"
                  [ngStyle]="{ display: 'flex', 'border-bottom': 'none' }"
                >
                  <mat-icon (click)="openOrder('view', order)">
                    visibility
                  </mat-icon>

                  <mat-icon
                    (click)="printBilledOrder(order)">
                    print
                  </mat-icon>

                  <button
                    mat-stroked-button
                    class="active small-btn"
                    [disabled]="!showAddPaymentOption"
                    (click)="
                      goToAddPayment(order.invoice_id, order.balance, true)
                    "
                  >
                    {{ "pos.payment" | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
