<div *ngIf = "showPage != true">
  <span>You Dont Have Access to view this Page!!!</span>
</div>
<div *ngIf = "showPage == true">
  <div class="mat-elevation-z8" *ngIf="mode==='housekeeping'">
    <div class="table-container">
      <table class="table table-bordered">
        <thead >
          <tr>
            <th class="thead-dark"> Room Type</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let info of overallHousekeepingInfo; let i = index">
            <tr>
              <td (click)="showHousekeepingInfo(info)" class="align-icon">{{info.name}}
                  <mat-icon>keyboard_arrow_{{ !!!info.shown ? "down" : "up" }}</mat-icon>
              </td>
            </tr>
            <tr [hidden]="!info.shown">
              <td class="alt-row" colspan="13">
                <div [@expandCollapse]="!info.shown ? 'collapsed' : 'expanded'">
                  <table mat-table [dataSource]="info.info" matSort class="table_wrapper">
                    <ng-container matColumnDef="roomNum" sticky>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Room Number </th>
                      <td mat-cell *matCellDef="let element"> {{element.roomNum}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="roomAvailabilityStatus">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                      <td mat-cell *matCellDef="let element" ><button mat-flat-button class="btn-sm" [ngClass]="{
                        'available' : element.roomAvailabilityStatus === 'Available',
                        'occupied' : element.roomAvailabilityStatus === 'Occupied',
                        'checking-out': element.roomAvailabilityStatus === 'Checking Out'
                     }"> {{element.roomAvailabilityStatus}} </button></td>
                    </ng-container>
              
                    <ng-container matColumnDef="guestName">
                      <th mat-header-cell *matHeaderCellDef> Guest Name </th>
                      <td mat-cell *matCellDef="let element"> {{element.guestName}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="roomCleanessStatus">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Clean Status </th>
                      <td mat-cell *matCellDef="let element"> <button *ngIf="element.roomCleanessStatus === 'clean'" type = "button" mat-stroked-button class="btn-sm clean-border-style" (click)="cleanDirtyToggle(element.roomNum, element.roomCleanessStatus, element.roomNumId)">
                        <img src="../../../../assets/svg/clean.svg" alt="" width="15px">  {{element.roomCleanessStatus}}
                        </button>
                        <button *ngIf="element.roomCleanessStatus === 'dirty'" type = "button" mat-stroked-button class="btn-sm dirty-border-style" (click)="cleanDirtyToggle(element.roomNum, element.roomCleanessStatus, element.roomNumId)"><img src="../../../../assets/svg/Broom.svg" alt="" width="15px">  {{element.roomCleanessStatus}}
                        </button>
                      </td>
                    </ng-container>
              
                    <ng-container matColumnDef="markOutOfOrder">
                      <th mat-header-cell *matHeaderCellDef> Mark Out Of Order </th>
                      <td mat-cell  *matCellDef="let element">
                        <button mat-stroked-button class="btn-sm pr-border mr-2" [disabled]="element.roomAvailabilityStatus !== 'Available'" (click)="markOutOfOrder(element, info)"> Out Of Order </button>
                      </td>
                    </ng-container>
              
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  
                  <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
                </div>
              </td>
            </tr>
          </ng-container>
          
        </tbody>
      </table>
    </div>
  </div>
  
  <div *ngIf="mode === 'outoforder'">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <h2>Out Of Order</h2>
      </div>
      <div class="navbar-end">
        <button mat-stroked-button class="btn-sm pr-border" (click)="close()">
          {{ "stayview.reservation.back" | translate }}
        </button>
      </div>
    </nav>
    
    <mat-divider></mat-divider>
    <div>
      <div class="form-row mt-4" style="margin-left: 0%; margin-right: 0%">
        <div class="form-group col-md-2 col-sm-2 col-xs-2">
          <label for="checkinDate"
            >{{ "maintenance.start_date" | translate }}:</label
          >
          <input
            type="date"
            class="form-control form-control-sm"
            onkeydown="return false"
            id="checkinDate"
            [(ngModel)]="checkinDate"
            (change)="days()"
            name="checkinDate"
            required
          />
        </div>
        <div class="form-group col-md-2 col-sm-2 col-xs-2">
          <label for="checkoutDate"
            >{{ "maintenance.end_date" | translate }}:</label
          >
          <input
            type="date"
            class="form-control form-control-sm"
            onkeydown="return false"
            id="checkoutDate"
            [(ngModel)]="checkoutDate"
            name="checkoutDate"
            (change)="days()"
            value=""
            required
          />
        </div>
        <div class="form-group col-md-1 col-sm-1 col-xs-1">
          <label for="nights"
            >{{ "stayview.reservation.nights" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-sm"
            id="nights"
            [(ngModel)]="nights"
            name="nights"
            value=""
            [disabled]="true"
          />
        </div>
        <div class="form-group col-md-1 col-sm-1 col-xs-1">
          <label for="roomNum"
            >{{ "stayview.reservation.room_no" | translate }}:</label
          >
          <input
            type="text"
            class="form-control form-control-sm"
            id="roomNum"
            [(ngModel)]="roomNum"
            name="roomNum"
            value=""
            [disabled]="true"
          />
          </div>
          <div class="form-group col-md-5 col-sm-5 col-xs-5">
            <label for="comment"
              >{{ "stayview.payment_detail.comments" | translate }}:</label
            >
            <input
              type="text"
              class="form-control form-control-sm"
              id="comment"
              [(ngModel)]="comment"
              name="comment"
              value=""
            />
        </div>
      </div>
      <div class="submitButton">
        <button
          *ngIf="submitting == false"
          type="submit"
          mat-stroked-button class="btn-sm pr-border"
          (click)="submit()"
        >
          {{ "stayview.reservation.edit_booking.submit" | translate }}
        </button>
        <button
          *ngIf="submitting == true"
          type="submit"
          mat-stroked-button class="btn-sm pr-border"
          disabled
        >
          {{ "stayview.reservation.edit_booking.submitting" | translate }}
        </button>
      </div>
  </div>  
</div>

