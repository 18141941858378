<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Add Company</h1>

        <svg [mat-dialog-close]="false" role="button"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
        </svg>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <form [formGroup]="companyForm">
            <div>
                <label> Name <span class="text-danger">*</span></label>
                <input class="form-control form-control-sm" formControlName="name" maxlength="64">
            </div>

            <div>
                <label> GST No.</label>
                <input class="form-control form-control-sm" formControlName="gstNum" maxlength="64">
            </div>

            <div>
                <label> Contact Person</label>
                <input class="form-control form-control-sm" formControlName="contactPerson" maxlength="64">
            </div>

            <div>
                <label> Email</label>
                <input class="form-control form-control-sm" formControlName="email" maxlength="64">
            </div>

            <div>
                <label> Phone</label>
                <input class="form-control form-control-sm" formControlName="phone" maxlength="64">
            </div>

            <div>
                <label> Address </label>
                <textarea class="form-control form-control-sm" formControlName="address" maxlength="150"></textarea>
            </div>
        </form>
        
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button
                [ngClass]="{'disable_button': companyForm.invalid}"
                class="primary_btn"
                (click)="submit()">
                Submit
            </button>
        </mat-dialog-actions>
    </div>

</div>