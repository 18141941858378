import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PosService } from 'src/app/core/pos.service';
import { UserService } from 'src/app/core/user.service';
import { Location } from '@angular/common';
import { PrintService } from 'src/app/core/print.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { Globals } from 'src/app/core/globals.service';
import { PaymentsDetailsComponent } from '../../stayview/payment-details/payment-details.component';
import { TransferOrderComponent } from '../transfer-order/transfer-order.component';
import { finalize } from 'rxjs/operators';
import { BillComponent } from '../bill/bill.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pos-create-order',
  templateUrl: './pos-create-order.component.html',
  styleUrls: ['./pos-create-order.component.scss']
})
export class PosCreateOrderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  hotelId: string;
  categories = [];
  serviceNames = [];
  selectedCategory: string;
  tables = [];
  infoTab: 'services' | 'payments' = 'services';
  servicesAdded = [];
  searchText = '';
  invoiceTotal = 0;
  invoiceBalance = 0;
  serviceConfigData = {};
  printers = [];
  posName = '';
  newOrderForm: FormGroup;
  orderInfo: any;
  title = 'Create Order';
  complimentary = false;
  clickedTable: any;
  discount = {type: '%', discount: null, discountApplied: false};
  deletedServices = [];
  generatingBill = false;
  transferring = false;
  selectedPrinter = {kot: '', bill: ''};
  backRoute: string;

  constructor(
    private posService: PosService,
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location,
    private printService: PrintService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    public globalService: Globals,
  ) { }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    this.hotelId = snapshot.params?.hotelId;
    this.posName = snapshot.queryParams?.posName;
    const billOrder = snapshot.queryParams?.billOrder;
    this.backRoute = snapshot.queryParams?.backRoute;

    const orderInfoStr = snapshot.queryParams?.orderInfo;
    if (orderInfoStr) {
      this.orderInfo = JSON.parse(orderInfoStr);
      this.setOrderInfo();
      this.title = this.orderInfo.status === 'Billed' ? 'View Order' : 'Edit Order';
    } else if (snapshot.queryParams?.table) {
      this.clickedTable = JSON.parse(snapshot.queryParams.table);
    }

    this.categories = this.posService.currentPosConfig?.categories;
    if (this.categories?.length) {
      this.getServiceNames(this.categories[0]);
    }
  
    this.initializeForm();
    this.getTables();
    this.getPrinters();
    this.subscriptions.push(
      this.posService.getBalance.subscribe(res => {
        this.getOrder();
      })
    );
    
    if (billOrder) {
      this.generateBillClick();
    }
  }

  getOrder() {
    this.userService.getInvoiceById(this.hotelId, this.orderInfo.invoice_id, 'True')
      .subscribe((data) => {
        this.orderInfo = {
          ...data.invoice, 
          payments: data.payments.data, 
          services_used: this.refineServicesUsed(data.services_used.data), 
          total_amount: data.invoice.total_amount,
          invoice_id: data.invoice.id
        }
        this.setOrderInfo();
    });
  }

  refineServicesUsed(servicesUsed) {
    servicesUsed.forEach(e => {
      const taxObj = {};
      e.tax.forEach(t => {
        taxObj[t.key] = t.value;
      })
      e.tax = taxObj
    });
    return servicesUsed;
  }

  setOrderInfo() {
    const servicesUsed = this.orderInfo.services_used;
    if (servicesUsed?.length) {
      this.servicesAdded = JSON.parse(JSON.stringify(servicesUsed));
      this.calculateTotal();
    }
    this.complimentary = this.orderInfo.is_complimentary;
  }

  initializeForm() {
    const posExtra = this.orderInfo?.posExtraData;
    const tableId = posExtra?.tableId ?? this.clickedTable?.id ?? null;
    const tableNum = posExtra?.tableNum ?? this.clickedTable?.tableNum ?? null;

    this.newOrderForm = this.fb.group({
      details: [this.orderInfo?.details, [Validators.required]],
      services: [null],
      invoiceId: [null],
      deleteServicesUsed: [null],
      user: [localStorage.getItem('user')],
      tableId: [tableId],
      tableNum: [tableNum],
      staff: [posExtra?.staff]
    });
  }
  
  getServiceNames(category) {
    this.selectedCategory = category;
    this.userService.getServiceNames(this.hotelId, category).subscribe(
      data => {
        this.serviceNames = data;
      }
    );
  }

  getTables() {
    this.posService.getTables(this.posName, this.hotelId).subscribe(res => {
      if (res?.success) {
        this.tables = res?.data;
      }   
    })
  }

  setTable(tableId) {
    const tableNum = this.tables.filter(e => e.id === tableId)[0].tableNum;
    this.newOrderForm.get('tableNum').setValue(tableNum);
  }

  changeInfoTab(tab) {
    this.infoTab = tab;
  }

  addService(serviceName) {
    if (this.generatingBill) {
      return;
    }
    if (!this.serviceConfigData.hasOwnProperty(serviceName)) {
      this.getServiceConfig(serviceName);
    } else {
      const data = this.serviceConfigData[serviceName];
      this.pushService(data.service_id, this.selectedCategory, data.total_amount, data.amount, 1, serviceName, data.tax);
      this.calculateTotal();
    }
  }

  pushService(serviceId, category, total, preTax, qty, name, tax, serviceUsedId?) {
    const index = this.servicesAdded.findIndex(item => item.service_id === serviceId);
    if (index > 0) {
      const existingItem = this.servicesAdded[index];
      this.changeQty(name, existingItem.quantity + 1, index);
    } else {
      this.servicesAdded.push({
        service_used_id: serviceUsedId,
        category,
        total_amount: total,
        service_id: serviceId,
        service_amount: preTax,
        quantity: qty,
        hotelId: this.hotelId,
        service_name: name,
        tax: tax,
      });
    }
  }

  getServiceConfig(name) {
    if (name.length > 0) {
      this.userService.getServiceConfig(this.hotelId, name, '3')
      .subscribe(data => {
        this.serviceConfigData[name] = data;
        if (this.complimentary) {
          this.pushService(data.service_id, this.selectedCategory, 0, 0, 1, name, {total: 0});
        } else {
          this.pushService(data.service_id, this.selectedCategory, data.total_amount, data.amount, 1, name, data.tax);
        }
        this.calculateTotal();
      });
    }
  }

  changeQty(name, qty, index) {
    if (qty < 1 ) {
      return;
    }
    
    if (this.complimentary) {
      this.servicesAdded[index].quantity = qty;
      return;
    }

    if (!this.serviceConfigData.hasOwnProperty(name)) {
      this.userService.getServiceConfig(this.hotelId, name, '3')
      .subscribe(data => {
        this.serviceConfigData[name] = data;
        this.setQty(name, qty, index)
      });
      return;
    }
    this.setQty(name, qty, index);
  }

  setQty(name, qty, index) {
    const config = this.serviceConfigData[name];
    const amount = config.tax_inclusive === 'No' ? config.amount : config.total_amount;
    this.calculateAmount(amount, qty, config, index);
  }

  calculateAmount(amount, qty, config, index) {
    if (!amount) {
      return;
    }

    const {tax_inclusive, tax_list, perc_total_tax, flat_total_tax} = config;
    const body = {
      amount: amount * qty,
      taxInclusive: tax_inclusive, 
      taxList: tax_list, 
      percTotalTax: perc_total_tax, 
      flatTotalTax: flat_total_tax
    };
    this.userService.calculateTax(body).subscribe(
    data => {
      this.servicesAdded[index].quantity = qty;
      this.servicesAdded[index].service_amount = data.amount;
      this.servicesAdded[index].total_amount = data.total_amount;
      this.servicesAdded[index].tax = data.tax;
      this.calculateTotal();
    });
  }

  removeService(service) {
    this.servicesAdded = this.servicesAdded.filter(s => s.service_id !== service.service_id);
    this.calculateTotal();
    if (service.service_used_id) {
      this.deletedServices.push({id: service.service_used_id, total_amount: service.total_amount, service_name: service.service_name});
    }
  }

  calculateTotal() {
    this.invoiceTotal = 0;
    this.servicesAdded.forEach(e => {
      const total_amount = typeof(e.total_amount) === 'string' ? parseFloat(e.total_amount) : e.total_amount;
      this.invoiceTotal += total_amount;
    });
    if (this.orderInfo?.status) {
      const paid = this.orderInfo.total_amount - this.orderInfo.balance;
      this.invoiceBalance = this.invoiceTotal - paid;
    } else {
      this.invoiceBalance = this.invoiceTotal;
    }
  }

  onSubmit() {
    const form = this.newOrderForm;
    const newServices = this.servicesAdded.filter(ele => ele.service_used_id === undefined);
    form.controls.services.setValue(newServices);

    // new order
    if (!this.orderInfo) {
      const body = {...form.value, complimentary: this.complimentary};
      this.posService.newPosOrder(body, this.hotelId, this.posName).subscribe(
        data => {
          this.goToPrevView();
      });
    } 
    // edit order
    else {
      form.controls.invoiceId.setValue(this.orderInfo.invoice_id);
      form.controls.deleteServicesUsed.setValue(this.deletedServices);
      const body = {...form.value, modifiedServices: this.getModifiedServices()}

      this.posService.editPosOrder(body, this.hotelId, this.posName).subscribe(
        data => {
          if (data && 'error' in data) {
            this.dialog.open(DialogComponent, {data: {title: 'ERROR', body: data.error, cancelBtn: 'Ok' }});
          } else {
            this.goToPrevView();
          }
        });
    }
  }

  applyDiscount() {
    if (!this.discount.discountApplied && this.discount.discount > 0 && this.discount.type) {
      const body = {discount: this.discount.discount, discountType: this.discount.type, servicesUsed: this.servicesAdded};
      this.posService.applyDiscount(this.hotelId, body).subscribe(
        data => {
          this.servicesAdded = data;
          this.discount.discountApplied = true;
          this.calculateTotal();
        });
    }
  }

  billOrder() {
    const body: any = {};
    body.invoiceId = this.orderInfo.invoice_id;
    body.discountApplied = this.discount.discountApplied;
    body.user = localStorage.getItem('user');
    body.complimentary = this.orderInfo.is_complimentary;
    if (this.discount.discountApplied) {
      body.discountType = this.discount.type;
      body.discount = this.discount.discount;
      body.servicesUsed = [];
      this.servicesAdded.forEach(ele => {
        body.servicesUsed.push({discounted_amount: ele.discounted_amount, tax: ele.tax, total_amount: ele.total_amount,
          service_used_id: ele.service_used_id});
      });
    }
    this.posService.billOrder(this.hotelId, this.posName, body).subscribe(
      data => {
        if (data && 'error' in data) {
          this.dialog.open(DialogComponent, {data: {title: 'ERROR', body: data.error, cancelBtn: 'Ok' }});
        }
        this.goToPrevView();
      }
    );
  }

  getModifiedServices() {
    if (!this.orderInfo?.services_used?.length) {
      return
    }
    const modifiedServices = [];
    for (const s1 of this.servicesAdded) {
      if (s1.service_used_id) {
        for (const s2 of this.orderInfo.services_used) {
          if (s1.service_used_id === s2.service_used_id && s1.quantity !== s2.quantity) {
            modifiedServices.push(s1);
          }
        }
      }
    }
    return modifiedServices;
  }

  toggleSelectAll(checked) {
    this.servicesAdded.forEach(s => s.selected = checked);
  }

  generateBillClick() {
    if (this.generatingBill) {
      this.billOrder();
    } else {
      const orderModified = JSON.stringify(this.servicesAdded) === JSON.stringify(this.orderInfo?.services_used) ? false : true;
      this.generatingBill = !orderModified;
    }
  }

  goToPrevView() {
    this.router.navigate([`/${this.backRoute}/${this.hotelId}`], 
      {queryParams: {
          posName: this.posName, 
          tables: JSON.stringify(this.tables),
          orders: null,
        }
      });
  }

  goToAddPayment() {
    this.dialog.open(PaymentsDetailsComponent, {
      disableClose: true,
      data: {
        allocId: null,
        invoiceId: this.orderInfo?.invoice_id,
        mode: 'newEntry',
        balance: this.orderInfo?.balance,
        pos: true,
        billed: this.orderInfo?.status === 'Billed' ? true : false,
        posName: this.posName,
      },
    });
  }

  transferServicesClick(newOrder = false) {
    const services = [];
    this.servicesAdded.forEach(e => {
      if (e.selected) {
        services.push(JSON.parse(JSON.stringify(e)));
      }
    });
  
    if (!services.length) {
      this.dialog.open(DialogComponent, {
        width: '400px',
        data:  {
          title: 'Oops !', body: `Please select a service to transfer`, confirmBtn: 'Ok'
        }
      });
      return;
    }
  
    if (newOrder) {
      this.transferServicesNew(services);
    } else {
      this.openTransferOrder(services);
    }
   }

   transferServicesNew(services: any[]) {
    const serviceUsedIds: string[] = services.map(e => e.service_used_id);
    const body = {
      serviceUsedIds,
      transferToInvoice: null,
      transferFromInvoice: this.orderInfo.invoice_id
    };
    this.transferServices(body);
   }

   transferServices(body) {
    this.transferring = true;
    this.posService.transferService(this.hotelId, this.posName, body)
      .pipe(finalize(() => this.transferring = false))
      .subscribe(data => {
        if (data?.success) {
          this.dialog.open(DialogComponent, {
            width: '400px',
            data: {title: 'Success', body: data.successMsg, confirmBtn: 'Ok'}
          });
          this.getOrder();
        }
    });
   }
  
   openTransferOrder(services: any[]) {
    const dRef = this.dialog.open(TransferOrderComponent, {
      width: '600px',
      maxWidth: '95%',
      data: {
        services,
        currentInvoiceId: this.orderInfo.invoice_id,
        hotelId: this.hotelId,
        posName: this.posName
      }
    });

    dRef.afterClosed().subscribe(res => {
      if (res.success) {
        this.transferServices(res.body);
      }
    });
  }

  printOrder(type) {
    const printer = type === 'kot' ? this.selectedPrinter.kot : this.selectedPrinter.bill;
    if (!this.printService.jspmWSStatus() || !printer) {
      return;
    }
  
    this.userService.getInvoiceById(this.hotelId, this.orderInfo.invoice_id, 'True')
      .subscribe((data) => {
        printOrder(data);
      });
  
    const printOrder = (currentOrder) => {
      this.printService.printer = printer;
      const mode = type === 'kot' ? 'edit' : 'view';
      this.posService.escPosPrint(mode, currentOrder, this.posService.currentPosConfig);
    }
  }

  viewBill() {
    const order = JSON.parse(JSON.stringify({...this.orderInfo, services_used: {data: this.orderInfo.services_used}}))
    const data = { order, config: this.posService.currentPosConfig };
    this.dialog.open(BillComponent, { data });
  }

  getPrinters() {
    if (this.printService.jspmWSStatus()) {
      this.printers = this.printService.printers;
      const kotPrinter = localStorage.getItem('posKotDefaultPrinter');
      const billPrinter = localStorage.getItem('posDefaultPrinter');
      this.selectedPrinter.kot = this.printers.includes(kotPrinter) ? kotPrinter : '';
      this.selectedPrinter.bill = this.printers.includes(billPrinter) ? billPrinter : '';
    }
  }

  setPrinter(type: 'kot' | 'bill', value) {
    const key = type === 'kot' ? 'posKotDefaultPrinter' : 'posDefaultPrinter';
    localStorage.setItem(key, value);
  }

  complimentaryToggle() {
    this.servicesAdded.forEach(s => {
      const conf =  this.serviceConfigData[s.service_name];
      s.total_amount =  this.complimentary ? 0 : conf.total_amount;
      s.service_amount = this.complimentary ? 0 : conf.amount;
      s.tax = this.complimentary ? {total: 0} : conf.tax;
    })
  }

  goBack() {
    this.location.back()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s?.unsubscribe())
  }
}
