import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { Guestdata } from './guestdata';
import { ReservationService } from '../../../core/reservation.service';
import { Location } from '@angular/common';
import { UserService } from '../../../core/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../_shared/dialog/dialog.component';
import { Globals } from '../../../core/globals.service';
import { ReservationdataService } from 'src/app/core/reservationdata.service';
import { formatNumber } from '@angular/common';
import { saveAs } from 'file-saver-es';
import { CommunicationService } from 'src/app/core/communication.service';
import { AdditionalGuestComponent } from '../../additional-guest/additional-guest.component';
import { EditInvoiceComponent } from '../../stayview/edit-invoice/edit-invoice.component';
import { SendVoucherEmailComponent } from '../../stayview/send-voucher-email/send-voucher-email.component';
import { EditBookingIdComponent } from '../edit-booking-id/edit-booking-id.component';
import { LogsComponent } from '../../logs/logs.component';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { GuestSearchTableComponent } from '../guest-search-table/guest-search-table.component';
import { CompanySearchTableComponent } from '../company-search-table/company-search-table.component';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css'],
  animations: [
    trigger('rotate', [
      state('initial', style({ transform: 'rotate(180deg)' })),
      transition('initial <=> final', animate(200)),
    ]),
  ],
})
export class ReservationComponent implements OnInit {
  @Input() mode: string;
  @Input() allocId: string;
  @Input() hoteliD: string;
  @Input() editStayAccess = true;
  @Input() showDeleteReservation = false;
  @ViewChild('country') country: ElementRef
  @ViewChild('city') city: ElementRef
  @ViewChild('state') state: ElementRef

  countries = this.guestService.getCountries();
  states = null;
  cities = null;

  selectedCountry;
  selectedState;
  selectedCity;

  guest: Guestdata = {
    checkin: '',
    checkout: '',
    nights: '',
    rooms: '',
    numguest: '',
    roomtype: '',
    rateplan: '',
    business: '',
    billto: '',
    PaymentMode: '',
    currencycode: '',
    amount: 0,
    taxinclusive: false,
    tax: 0,
    room_num: '',
    name: '',
    email: null,
    phone: null,
    idproof: null,
    dob: '',
    gender: null,
    inputAddress: null,
    city: null,
    state: null,
    zipcode: null,
    country: null,
    special_request: null,
    id_detail: null,
    bookedby: null,
    extraChild: '0',
    prePaymentMode: null,
    prePaidAmount: 0,
    billtoForPrePaid: null,
    commentsPrepaid: null,
    editedChannel: 'PMS',
    expirationMins: '',
    purposeOfVisit: null,
    comingFrom: null,
    goingTo: null,
    vipGuest: false,
    internalComments: null,
    discountType: "percentage",
    discountValue: 0,
    sendPaymentLink: false,
    paymentLinkAmount: 0
  };
  allowExtraChild = false;
  extraChildArray: string[];
  extraChildValue = 0;
  fetchinglogs = false;
  userFeatures: any;
  allowAddRate = true;
  newFile: any;
  AnimationStates = {'Extras' : 'final'};
  nights: string = '1';
  currentuploadedFiles = [];
  allowToCreatePreviousReservation = true;
  allowToCreateComplimentary = true;
  allowToCreateOutofOrder = true;
  allowToCreateGroupReservations = true;
  allowDeletePhotoId: boolean = false;
  showCityDropDown = false;

  constructor(
    private guestService: ReservationService,
    public location: Location,
    private datePipe: DatePipe,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public matdialog: MatDialog,
    public globals: Globals,
    private reservdataService: ReservationdataService,
    private commS: CommunicationService
  ) {}

  form: FormGroup;
  documents: any = null;
  // for disabling the tax options if tax included is true
  disableSelect: boolean = false;
  timezone: string;
  // disable fields
  disableField = false;
  nextdaydate = new Date();
  payment_mode_disable = false;
  // Global Variables declaration
  occupancydata = {
    s: 1,
    d: 2,
    t: 3,
    q: 4,
    p: 5,
    h: 6,
    hp: 7,
    o: 8,
    n: 9,
    de: 10,
  };
  num_to_occupancy = {
    1: 's',
    2: 'd',
    3: 't',
    4: 'q',
    5: 'p',
    6: 'h',
    7: 'hp',
    8: 'o',
    9: 'n',
    10: 'de',
  };
  channel = '';
  staynights = [];
  room_types: Guestdata[] = [];
  MealPlan = [];
  roomsArray = [];
  guests = [];
  fetchedguestdata: Guestdata[] = [];
  submitting = false;
  optionselected: any;
  fullfetcheddata: any;
  fileselected = [];
  rateperroom = {};
  rateperroomlist = [];
  Guest: string = 'Guest';
  hotelRoute: any;
  hotelID: any;
  outOfOrder = false;
  managementBlock = false;
  taxinclusiveval: boolean = false;
  companydata = [];
  room_numbers = [];
  selectedroom_num: any = '';
  requiredState: boolean;
  morethanone: boolean = false;
  specialrequest = [];
  total_cost = '0';
  taxinclusiveval1: boolean = false;
  rateplanid: any;
  currentUser: string;
  editStay = false;
  disable_delete = false;
  selectedcompanydetails = [];
  currentDate: any;
  dateinvalid: boolean = false;
  businessSegments: any;
  identityList: [];
  paymentModes = [];
  requiredBookingInfo: any;
  disableAmountEdit: boolean;
  allocationData: any;
  printDisabled = false;
  extraServiceAmount = 0.0;
  enableEditInvoice: boolean = false;
  showTravelData : boolean = false;
  prevReservation = false;
  currencyList = [];
  customChannels = [];
  purposeOfVisitList = [];

  ngOnInit() {
    this.hotelRoute = this.route.params.subscribe((params) => {
      this.hotelID = params.hotelId;
    });
    this.hotelRoute = this.route.queryParams.subscribe((queryParams) => {
      this.currentUser = queryParams.user;
    });
    this.getUserFeatures();
    this.sortCountries();
    this.AnimationStates['Extras'] = 'final';
    if (this.mode === 'editStay') {
      this.hotelID = this.hoteliD;
      this.fetchRoomAllocationData();
    }
    this.optionselected = this.location.getState();
    if (this.optionselected['modify'] == 'true') {
      this.fetchData_for_provided_bookingId();
    } else {
      this.outOfOrder = this.optionselected['outOfOrder'];
      this.guest.name = this.optionselected['Customer_Name'];
    }
    if (this.mode !== 'editStay' && this.optionselected['modify'] != 'true') {
      this.guest.business = 'Walk-in';
      this.guest.PaymentMode = 'Cash';
      this.guest.billto = 'Guest';
    }
    this.getTimeZone();
    this.disablefields();
    if (this.optionselected['outOfOrder'] == true) {
      this.requiredState = false;
    } else {
      this.requiredState = true;
    }
    this.guestService.fetchCurrencies().subscribe(success=>{this.currencyList = success.currencyList})
    this.userService.getProductConfig(this.hotelID).subscribe(res => {
      this.guest.sendPaymentLink = res?.conf?.sendPaymentLink;
      this.guest.expirationMins = res?.conf?.paymentLinkExpirationTime;
    })
  }

  // get user access data
  getUserFeatures() {
    this.userService.userFeatures$.subscribe(res => {
      this.userFeatures = res?.live;
      if (this.userFeatures?.hasOwnProperty('addRate')) {
        this.allowAddRate = this.userFeatures.addRate;
      }
      if (this.userFeatures?.hasOwnProperty('previousReservations')) {
        this.allowToCreatePreviousReservation = this.userFeatures.previousReservations;
      }
      if (this.userFeatures?.hasOwnProperty('complimentary')) {
        this.allowToCreateComplimentary = this.userFeatures.complimentary;
      }
      if (this.userFeatures?.hasOwnProperty('outofOrder')) {
        this.allowToCreateOutofOrder = this.userFeatures.outofOrder;
      }
      if (this.userFeatures?.hasOwnProperty('groupReservations')) {
        this.allowToCreateGroupReservations = this.userFeatures.groupReservations;
      }
    })
  }

  sortCountries(){
    this.countries.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  onCountryChange($event): void {
    this.selectedCountry = this.guestService.getShortNameForCountry($event.target.value, this.countries)
    this.states = this.guestService.getStatesByCountry(this.selectedCountry);
    this.city = null;
  }

  onStateChange($event): void {
    this.cities = this.guestService.getCitiesByState(this.selectedCountry, $event.target.value);
  }

  // API call to get currency code for selected hotelid
  getTimeZone(): void {
    this.guestService.getTimeZone(this.hotelID).subscribe((success) => {
      this.timezone = success['timezone'];
      this.getCurrentDate();
    });
    this.guestService
      .getCurrencycode(this.hotelID)
      .subscribe((currency) => (this.guest.currencycode = currency));
  }

  getCurrentDate() {
    this.guestService.getCurrentDatetTime(this.hotelID).subscribe(
      (data) => {
        this.currentDate = new Date(data.currentdatetime);
        this.setMinCheckindateToCurrentDateAndFetchData();
      },
      (err) => {}
    );
  }

  setMinCheckindateToCurrentDateAndFetchData(){
    (<HTMLInputElement>document.getElementById('checkinDate')).min = this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd');
    let today = new Date(this.currentDate);
    let nextday = new Date(this.currentDate);
    nextday.setDate(nextday.getDate() + 1);
    if (this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd') == this.datePipe.transform( new Date().toLocaleString('en-US', { timeZone: this.timezone }), 'yyyy-MM-dd')) {
      if ( this.mode != 'editStay' && this.optionselected['modify'] != 'true' ) {
        (<HTMLInputElement>document.getElementById('checkoutDate')).min = today.toISOString().split('T')[0];
        this.guest.checkin = this.datePipe.transform(new Date().toLocaleString('en-US', { timeZone: this.timezone }), 'yyyy-MM-dd');
        this.guest.checkout = this.datePipe.transform( nextday, 'yyyy-MM-dd' );
      }
    } else {
      if ( this.mode != 'editStay' && this.optionselected['modify'] != 'true' ) {
        (<HTMLInputElement>document.getElementById('checkoutDate')).min = today.toISOString().split('T')[0];
        this.guest.checkin = this.datePipe.transform(today, 'yyyy-MM-dd');
        this.guest.checkout = this.datePipe.transform( nextday, 'yyyy-MM-dd' );
        this.getBookingRequiredInfo( this.guest.checkin, this.guest.checkout );
      }
    }
    if ( this.mode != 'editStay' && this.optionselected['modify'] != 'true' ) {
      this.days();
    }
  }

  getPreviousDayReservationData(){
    this.prevReservation = true;
    let previousDay = new Date(this.currentDate);
    previousDay.setDate(previousDay.getDate() - 1);
    (<HTMLInputElement>document.getElementById('checkoutDate')).removeAttribute('min');
    (<HTMLInputElement>document.getElementById('checkinDate')).removeAttribute('min');
    (<HTMLInputElement>document.getElementById('checkinDate')).max = this.datePipe.transform(previousDay, 'yyyy-MM-dd');
    let today = new Date(this.currentDate);
    if (this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd') == this.datePipe.transform( new Date().toLocaleString('en-US', { timeZone: this.timezone }), 'yyyy-MM-dd')) {
      if ( this.mode != 'editStay' && this.optionselected['modify'] != 'true' ) {
        this.guest.checkin = this.datePipe.transform(previousDay.toLocaleString('en-US', { timeZone: this.timezone }), 'yyyy-MM-dd');
        this.guest.checkout = this.datePipe.transform( today, 'yyyy-MM-dd' );
      }
    } else {
      if ( this.mode != 'editStay' && this.optionselected['modify'] != 'true' ) {
        this.guest.checkin = this.datePipe.transform(previousDay, 'yyyy-MM-dd');
        this.guest.checkout = this.datePipe.transform( today, 'yyyy-MM-dd' );
        this.getBookingRequiredInfo( this.guest.checkin, this.guest.checkout );
      }
    }
    if ( this.mode != 'editStay' && this.optionselected['modify'] != 'true' ) {
      this.days();
    }
  }

  // To disable unnecessary fields if its Management block
  disablefields() {
    var onevar = this.location.getState();
    if (onevar['managementBlock'] == true || this.managementBlock == true) {
      this.disableSelect = true;
      this.payment_mode_disable = true;
      this.guest.business = 'Management Block';
      this.managementBlock = true;
    }
    if (onevar['outOfOrder'] == true || this.outOfOrder == true) {
      this.payment_mode_disable = true;
      this.disableSelect = true;
      this.guest.business = 'Out of Order';
      this.outOfOrder = true;
    }
  }
  clearform() {
    this.guest.billto = null;
  }
  searchCompany(): void {
    this.guestService
      .getcompanydetails(this.hotelID)
      .subscribe((companydata) => {
        this.companydata = companydata['companiesInfo'];
      });
  }

  fetchrate_forCompany(company) {
    if (this.guest.nights == '0'){
      this.nights = '1';
    }else{
      this.nights = this.guest.nights;
    }
    this.selectedcompanydetails = [];
    const length_ofdata = Object.keys(this.companydata).length;
    var companyPretaxAmount = 0;
    var companyTax = 0;
    for (var i = 0; i < length_ofdata; i++) {
      if (this.companydata[i]['companyName'] == company) {
        this.selectedcompanydetails.push(this.companydata[i]);
        if (this.mode !== 'editStay') {
          for ( var k = 0; k < Object.keys(this.companydata[i]['contractedRate']).length; k++) {
            if (this.guest['roomidselected'] == this.companydata[i]['contractedRate'][k]['roomId']) {
              if (this.guest['rateplan'] == this.companydata[i]['contractedRate'][k]['mealplan'] && this.guest['numguest'] == this.companydata[i]['contractedRate'][k]['numOfGuest']){
                companyPretaxAmount = Number(this.companydata[i]['contractedRate'][k]['perDayPretaxRate']);
                companyTax = Number(this.companydata[i]['contractedRate'][k]['perDaytax']);
              }if(!this.companydata[i]['contractedRate'][k]['mealplan'] && !this.companydata[i]['contractedRate'][k]['numOfGuest']){
                companyPretaxAmount = Number(this.companydata[i]['contractedRate'][k]['perDayPretaxRate']);
                companyTax = Number(this.companydata[i]['contractedRate'][k]['perDaytax']);
              }
            }
          }
        }
      }
    }
    if (this.mode !== 'editStay') {
      if (this.guest.billto != 'Guest' && this.guest.billto != this.guest.name) {
        if (companyPretaxAmount != 0) {
          if (this.guest.taxinclusive == false) {
            this.guest.amount = companyPretaxAmount;
            this.guest.tax = companyTax;
          } else {
            this.guest.amount = companyPretaxAmount + companyTax;
          }
          this.total_cost = ((companyPretaxAmount + companyTax) * Number(this.nights) * Number(this.guest.rooms)).toLocaleString();
        } else {
          this.changemealplan(this.guest.roomtype, this.guest.rateplan, this.guest.numguest, this.guest.rooms);
        }
      } else {
        this.selectedcompanydetails = [];
        this.changemealplan(this.guest.roomtype, this.guest.rateplan, this.guest.numguest, this.guest.rooms);
      }
    }
  }

  getBookingRequiredInfo(checkin, checkout, mode = 'new', bookingId = null) {
    this.extraServiceAmount = 0.0;
    this.guestService
      .getRequiredInfo(this.hotelID, checkin, checkout, mode, bookingId)
      .subscribe((data) => {
        this.showTravelData = data.showTravelData;
        this.allowExtraChild = data.allowExtraChild;
        this.showCityDropDown = data.showCityDropDown;
        let serviceAmount = 0.0;
        this.guest.billto = 'Guest';
        var onevar = this.location.getState();
        this.selectedroom_num = '';
        this.guest.room_num = '';
        this.requiredBookingInfo = data;
        this.roomsArray = ['0'];
        this.extraChildArray = ['0'];
        this.guest.rooms = this.roomsArray[0];
        this.guest.currencycode = data.currencyCode;
        this.room_types = data.roomtypes;
        this.companydata = data['companiesData'];
        this.businessSegments = data.businessSegments;
        this.paymentModes = data.paymentModes;
        this.identityList = data.identityList;
        this.customChannels= data.customChannels;
        this.purposeOfVisitList = data.purposeOfVisitList;
        for (var roomtypeIndex in data.roomtypes) {
          var roomscount = [];
          if (data.roomtypesInfo[roomtypeIndex]['availableRooms'] !== 0) {
            this.guest.roomtype = data.roomtypes[roomtypeIndex];
            this.guest['roomidselected'] = data.roomtypesInfo[roomtypeIndex]['roomId'];
            this.guest.taxinclusive = data.taxInclusive;
            this.MealPlan = data.roomtypesInfo[roomtypeIndex]['mealplans'];
            this.guest.rateplan = this.MealPlan[0];
            this.rateplanid = data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['rateplanId'];
            this.guests = data.roomtypesInfo[roomtypeIndex]['numGuestsAllowed'];
            this.guest.numguest = this.guests[0];
            for ( var extraChild = 1; extraChild < data.roomtypesInfo[roomtypeIndex]['numGuestsAllowed'][Object.keys(data.roomtypesInfo[roomtypeIndex]['numGuestsAllowed']).length - 1]; extraChild++) {
              this.extraChildArray.push(String(extraChild));
            }
            for ( var roomcount = 1; roomcount <= data.roomtypesInfo[roomtypeIndex]['availableRooms']; roomcount++) {
              roomscount.push(roomcount);
            }
            this.roomsArray = roomscount;
            this.guest.rooms = this.roomsArray[0];
            this.room_numbers = data.roomtypesInfo[roomtypeIndex]['availableRoomNumbers'];

            if ((onevar['managementBlock'] == true || onevar['outOfOrder'] == true) || (this.outOfOrder == true || this.managementBlock == true)) {
              this.guest.amount = 0;
              this.guest.tax = 0;
              this.total_cost = '0';
            } else {
              for (var servicedata in data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['extraServicesDetails']) {
                serviceAmount = serviceAmount +
                  data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['extraServicesDetails'][servicedata]['extraServiceTotalPretax'] +
                  data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['extraServicesDetails'][servicedata]['extraServiceTotalTax'];
              }
              this.extraServiceAmount = serviceAmount;

              if (data.taxInclusive == false) {
                this.guest.amount = data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['perDayPretaxRate'];
                this.guest.tax = data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['perDayTax'];
                this.taxinclusiveval1 = false;
                this.total_cost = ( data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['totalPretaxRate'] +
                  data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['totalTax'] + serviceAmount).toLocaleString();
              } else {
                this.taxinclusiveval1 = true;
                this.guest.amount = data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['perDayPretaxRate'] +
                  data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['perDayTax'];
                this.guest.tax = 0;
                this.total_cost = (
                  data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['totalPretaxRate'] +
                  data.roomtypesInfo[roomtypeIndex]['rateplans'][0]['totalTax']
                ).toLocaleString();
              }
            }
            break;
          }
        }
        if(this.guests.includes(2)){
          this.guest.numguest = '2'
          this.changemealplan(this.guest.roomtype, this.guest.rateplan, this.guest.numguest, this.guest.rooms);
        }
        if (this.guest.rooms == '0') {
          const WarningDialog = {
            title: 'Error !',
            body: `Sorry! No rooms left for the selected date, kindly change.`,
            cancelBtn: 'Dismiss',
          };
          const dialogRef = this.matdialog.open(DialogComponent, {
            data: WarningDialog,
          });
        }
        
        
      });
  }

  changeRoomtype(roomtype) {
    var onevar = this.location.getState();
    for (var roomtypeinfo in this.requiredBookingInfo.roomtypesInfo) {
      if (this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['displayName'] == roomtype) {
        this.selectedroom_num = '';
        this.guest.room_num = '';
        this.guest['roomidselected'] = this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['roomId'];
        if (this.mode !== 'editStay') {
          this.MealPlan = this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['mealplans'];
          this.guest.rateplan = this.MealPlan[0];
          this.rateplanid = this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['rateplanId'];
          this.guests = this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['numGuestsAllowed'];
          this.guest.numguest = this.guests[0];
          this.extraChildArray = [];
          this.guest.extraChild = '0';
          for (
            var extraChild = 0; 
            extraChild <= this.guests[Object.keys(this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['numGuestsAllowed']).length - 1];
            extraChild++
          ) {
            this.extraChildArray.push(String(extraChild));
          }
        } else if (this.mode == 'editStay') {
          this.guest.rateplan = this.allocationData.room.mealplan;
          if (this.allocationData.room.rateplanId) {
            var rateplan = this.allocationData.room.rateplanId.split('-').reverse();
            this.rateplanid = this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['roomId'] + '-' + rateplan[1] + '-' + rateplan[0];
          }
        }
        if (this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['availableRooms'] == 0) {
          const WarningDialog = {
            title: 'Error !',
            body: `Sorry! No rooms left for the selected roomtype, kindly change.`,
            cancelBtn: 'Dismiss',
          };
          const dialogRef = this.matdialog.open(DialogComponent, {
            data: WarningDialog,
          });
          this.roomsArray = ['0'];
          this.guest.rooms = this.roomsArray[0];
        } else {
          var roomscount = [];
          if (this.mode == 'editStay') {
            this.roomsArray = [1];
          } else {
            for (
              var roomcount = 1;
              roomcount <= this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['availableRooms'];
              roomcount++
            ) {
              roomscount.push(roomcount);
            }
            this.roomsArray = roomscount;
          }
          this.guest.rooms = this.roomsArray[0];
        }
        if ((onevar['managementBlock'] == true || onevar['outOfOrder'] == true) || (this.outOfOrder == true || this.managementBlock == true)) {
          this.guest.amount = 0;
          this.guest.tax = 0;
          this.total_cost = '0';
        } else {
          if (this.mode != 'editStay') {
            if (
              this.selectedcompanydetails.length > 0 &&
              this.selectedcompanydetails[0]['contractedRate'].length > 0
            ) {
              var amountExist = false;
              for (var details in this.selectedcompanydetails[0]['contractedRate']) {
                if (this.selectedcompanydetails[0]['contractedRate'][details]['roomId'] == this.guest['roomidselected']) {
                  if(this.selectedcompanydetails[0]['contractedRate'][details]['mealplan'] == this.MealPlan[0] &&
                  this.selectedcompanydetails[0]['contractedRate'][details]['numOfGuest'] == this.guests[0]){
                    amountExist = true;
                    this.setAmount(this.selectedcompanydetails[0]['contractedRate'][details]['perDayPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['perDaytax'], 1, this.extraServiceAmount, roomtype, this.MealPlan[0], this.guests[0], this.selectedcompanydetails[0]['contractedRate'][details]['totalPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['totalTax'])
                  }if(!this.selectedcompanydetails[0]['contractedRate'][details]['mealplan'] && !this.selectedcompanydetails[0]['contractedRate'][details]['numOfGuest']){
                    amountExist = true;
                    this.setAmount(this.selectedcompanydetails[0]['contractedRate'][details]['perDayPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['perDaytax'], 1, this.extraServiceAmount, roomtype, this.MealPlan[0], this.guests[0], this.selectedcompanydetails[0]['contractedRate'][details]['totalPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['totalTax'])
                  }
                }
              }
              if (amountExist == false){
                this.setAmount(this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['perDayPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['perDayTax'], 1, this.extraServiceAmount, roomtype, this.MealPlan[0], this.guests[0], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['totalPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['totalTax'])
              }
            } else {
              this.setAmount(this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['perDayPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['perDayTax'], 1, this.extraServiceAmount, roomtype, this.MealPlan[0], this.guests[0], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['totalPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][0]['totalTax'])
            }
            if(this.guests.includes(2)){
              this.guest.numguest = '2'
              this.changemealplan(this.guest.roomtype, this.guest.rateplan, this.guest.numguest, this.guest.rooms);
            }
          }
          
        }
        this.room_numbers = this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['availableRoomNumbers'];
      }
    }
  }

  changeGuestNum(guestNum) {
    this.extraChildArray = [];
    this.guest.extraChild = '0';
    for (
      var extraChild = 0;
      extraChild <= this.guests[Object.keys(this.guests).length - 1] - guestNum;
      extraChild++
    ) {
      this.extraChildArray.push(String(extraChild));
    }
  }

  changemealplan(roomtype, mealplan, numGuests, numRooms) {
    var onevar = this.location.getState();
    let serviceAmount = 0.0;
    this.extraServiceAmount = 0.0;

    if (numRooms > 1) {
      this.morethanone = true;
      this.selectedroom_num = '';
    } else {
      this.morethanone = false;
    }
    for (var roomtypeinfo in this.requiredBookingInfo.roomtypesInfo) {
      if (this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['displayName'] == roomtype) {
        for (var rateplanIndex in this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans']) {
          if (this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['mealplan'] == mealplan &&
            this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['numOfGuest'] == numGuests) {
            this.rateplanid = this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['rateplanId'];
            if ((onevar['managementBlock'] == true || onevar['outOfOrder'] == true) || (this.outOfOrder == true || this.managementBlock == true) ) {
              this.guest.amount = 0;
              this.guest.tax = 0;
              this.total_cost = '0';
            } else {
              if (this.mode != 'editStay') {
                if (this.guest.extraChild == '0') {
                  for (var servicedata in this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['extraServicesDetails']) {
                    var pax = 1;
                    if (this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['extraServicesDetails'][servicedata]['applyOnEachPax'] == true) {
                      pax = Number(numGuests);
                    }
                    serviceAmount = serviceAmount +
                      (this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['extraServicesDetails'][servicedata]['extraServiceTotalPretax'] +
                        this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['extraServicesDetails'][servicedata]['extraServiceTotalTax']) * pax;
                  }
                  this.extraServiceAmount = serviceAmount;
                }
                if (
                  this.selectedcompanydetails.length > 0 &&
                  this.selectedcompanydetails[0]['contractedRate'].length > 0
                ) {
                  var rateExist = false;
                  for (var details in this.selectedcompanydetails[0]['contractedRate']) {
                    if (this.selectedcompanydetails[0]['contractedRate'][details]['roomId'] == this.guest['roomidselected']) {
                      if(this.selectedcompanydetails[0]['contractedRate'][details]['mealplan'] == mealplan &&
                      this.selectedcompanydetails[0]['contractedRate'][details]['numOfGuest'] == numGuests){
                        rateExist = true;
                        this.setAmount(this.selectedcompanydetails[0]['contractedRate'][details]['perDayPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['perDaytax'], numRooms, serviceAmount, roomtype, mealplan, numGuests, this.selectedcompanydetails[0]['contractedRate'][details]['totalPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['totalTax'])
                      }if(!this.selectedcompanydetails[0]['contractedRate'][details]['mealplan'] && !this.selectedcompanydetails[0]['contractedRate'][details]['numOfGuest']){
                        rateExist = true;
                        this.setAmount(this.selectedcompanydetails[0]['contractedRate'][details]['perDayPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['perDaytax'], numRooms, serviceAmount, roomtype, mealplan, numGuests, this.selectedcompanydetails[0]['contractedRate'][details]['totalPretaxRate'], this.selectedcompanydetails[0]['contractedRate'][details]['totalTax'])
                      }
                    }
                  }
                  if (rateExist == false){
                    this.setAmount(this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['perDayPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['perDayTax'], numRooms, serviceAmount, roomtype, mealplan, numGuests, this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['totalPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['totalTax'])
                  }
                } else {
                  this.setAmount(this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['perDayPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['perDayTax'], numRooms, serviceAmount, roomtype, mealplan, numGuests, this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['totalPretaxRate'], this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][rateplanIndex]['totalTax'])
                }
              }
            }
          }
        }
      }
    }
  }

  setAmount(amount, tax, numRooms, serviceAmount, roomtype, mealplan, numGuests, totalpretax, totaltax){
    if (this.guest.extraChild == '0') {
      if (this.guest.taxinclusive == false) {
        this.guest.amount = amount;
        this.guest.tax = tax;
      } else {
        this.guest.amount = amount + tax;
        this.guest.tax = 0;
      }
      this.total_cost = ((totalpretax + totaltax + serviceAmount) * Number(numRooms)).toLocaleString();
    } else {
      this.extraChildChange(this.guest.extraChild, roomtype, mealplan, numGuests, numRooms);
    }
  }

  checkRoomNumId(roomNum, roomtype) {
    for (var roomtypeinfo in this.requiredBookingInfo.roomtypesInfo) {
      if (
        this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['displayName'] == roomtype
      ) {
        for (var room in this.requiredBookingInfo.roomtypesInfo[roomtypeinfo][
          'availableRoomNumbers'
        ]) {
          if (
            this.requiredBookingInfo.roomtypesInfo[roomtypeinfo][
              'availableRoomNumbers'
            ][room]['roomNum'] == roomNum
          ) {
            this.selectedroom_num =
              this.requiredBookingInfo.roomtypesInfo[roomtypeinfo][
                'availableRoomNumbers'
              ][room]['roomNumId'];
          }
        }
      }
    }
  }
  // fetching all data to edit reservation for selected booking id
  fetchData_for_provided_bookingId(): void {
    var onevar = this.location.getState();
    if (onevar['modify'] == 'true') {
      this.guestService.fetchallData(onevar['Booking_ID'], this.hotelID).subscribe((fullfetcheddata) => {
            this.fullfetcheddata = fullfetcheddata;
            this.guest['bookingId'] = onevar['Booking_ID'];
            if (fullfetcheddata['error']) {
              const WarningDialog = { title: 'Error !', body: fullfetcheddata['error'], cancelBtn: 'Dismiss',};
              const dialogRef = this.matdialog.open(DialogComponent, { data: WarningDialog,});
              dialogRef.disableClose = true;
              dialogRef.afterClosed().subscribe((result) => {
                if (result === 'false') {
                  this.location.back();
                }
              });
            } else {
              if (fullfetcheddata.bookingdata.disableEdit == true) {
                const WarningDialog = {
                  title: 'Error !',
                  body: `Sorry You can not edit this booking as it has multiple Room Types!`,
                  cancelBtn: 'Dismiss',
                };
                const dialogRef = this.matdialog.open(DialogComponent, {
                  data: WarningDialog,
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === 'false') {
                    this.location.back();
                  }
                });
              } else {
                this.guest.checkin = this.fullfetcheddata['bookingdata']['checkinDate'];
                this.guest.checkout = this.fullfetcheddata['bookingdata']['checkoutDate'];
                this.guest.nights = this.fullfetcheddata['bookingdata']['nights'];
                this.guest.bookedby = fullfetcheddata.bookingdata.bookedBy;
                this.guest['channel'] = fullfetcheddata.bookingdata.channel;
                this.guest['editedChannel'] = fullfetcheddata.bookingdata.channel;
                this.guest['source'] = fullfetcheddata.bookingdata.source;
                this.guest['pah'] = fullfetcheddata.bookingdata.pah;
                this.guest['oldAmountBreakup'] = fullfetcheddata.bookingdata.payload.amount;
                this.guest['oldRoomLevelInfo'] = fullfetcheddata.bookingdata.payload.rooms;
                this.guest.expirationMins = fullfetcheddata.bookingdata.expirationMins;
                this.guest['leadId'] = fullfetcheddata.bookingdata.leadId;
                this.guest.discountValue = 0;
                this.guestService.getRequiredInfo(this.hotelID, this.guest.checkin, this.guest.checkout, 'modify', onevar['Booking_ID']).subscribe((data) => {
                    this.showTravelData = data.showTravelData;  
                    this.purposeOfVisitList = data.purposeOfVisitList;
                    this.showCityDropDown = data.showCityDropDown;
                    this.allowExtraChild = data.allowExtraChild;
                    this.identityList = data.identityList;
                    this.companydata = data['companiesData'];
                    this.requiredBookingInfo = data;
                    this.guest.currencycode = data.currencyCode;
                    this.room_types = data.roomtypes;
                    this.guest.roomtype = this.fullfetcheddata['bookingdata']['roomName'];
                    this.guest['roomidselected'] = this.fullfetcheddata['bookingdata']['roomId'];
                    for (var roomtypeIndex in data.roomtypes) {
                      var roomscount = [];
                      if (this.fullfetcheddata['bookingdata']['segment'] == 'Management Block') {
                        this.optionselected['managementBlock'] = true;
                        this.managementBlock = true;
                        this.disableSelect = true;
                        this.guest.business = this.fullfetcheddata['bookingdata']['segment'];
                      } else {
                        this.businessSegments = data.businessSegments;
                        if (this.businessSegments.indexOf(this.fullfetcheddata['bookingdata']['segment']) == -1) {
                          this.businessSegments.push(this.fullfetcheddata['bookingdata']['segment']);
                        }
                        this.guest.business = this.fullfetcheddata['bookingdata']['segment'];
                      }
                      if (this.fullfetcheddata['bookingdata']['pmsPayment'].bill_to) {
                        this.Guest = this.fullfetcheddata['bookingdata']['pmsPayment']['bill_to'];
                        this.guest.billto = this.Guest;
                      } else {
                        this.guest.billto = this.fullfetcheddata['bookingdata']['pmsPayment'].billTo;
                      }
                      this.paymentModes = data.paymentModes;
                      this.customChannels = data.customChannels;
                      this.guest.PaymentMode = this.fullfetcheddata['bookingdata']['pmsPayment']['payment_mode'];
                      if (this.fullfetcheddata['bookingdata']['pmsPayment'].pre_payment_info){
                        this.guest.prePaidAmount = this.fullfetcheddata['bookingdata']['pmsPayment']['pre_payment_info']['amount'];
                        this.guest.prePaymentMode = this.fullfetcheddata['bookingdata']['pmsPayment']['pre_payment_info']['payment_mode'];
                        this.guest.billtoForPrePaid = this.fullfetcheddata['bookingdata']['pmsPayment']['pre_payment_info']['bill_to'];
                        this.guest.commentsPrepaid = this.fullfetcheddata['bookingdata']['pmsPayment']['pre_payment_info']['comments'];
                        this.guest['paymentReceipt'] = this.fullfetcheddata['bookingdata']['pmsPayment']['pre_payment_info']['attachment'];
                      }
                      if (this.fullfetcheddata['bookingdata']['segment'] == 'Management Block') {
                        this.guest.amount = 0.0;
                        this.guest.tax = 0.0;
                        this.total_cost = this.fullfetcheddata['bookingdata']['payload']['amount']['amountAfterTax'].toLocaleString();
                        this.taxinclusiveval1 = false;
                      } else {
                        if (
                          this.fullfetcheddata['bookingdata']['payload']['amount']['amountBeforeTax'] ==
                          Math.round(this.fullfetcheddata['bookingdata']['payload']['rooms'][0]['prices'][0]['sellRate'] *
                              Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms']).length *
                              Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms'][0]['prices']).length
                          )
                        ) {
                          this.guest.amount = this.fullfetcheddata['bookingdata']['payload']['amount']['amountBeforeTax'] /
                            Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms']).length /
                            Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms'][0]['prices']).length;
                          this.guest.tax = this.fullfetcheddata['bookingdata']['payload']['amount']['tax'] /
                            Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms']).length /
                            Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms'][0]['prices']).length;
                          this.total_cost = (this.fullfetcheddata['bookingdata']['payload']['amount']['amountAfterTax']).toLocaleString();
                          this.taxinclusiveval1 = false;
                        } else {
                          this.guest.amount = Math.round((this.fullfetcheddata['bookingdata']['payload']['amount']['amountAfterTax'] /
                                Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms']).length /
                                Object.keys(this.fullfetcheddata['bookingdata']['payload']['rooms'][0]['prices']).length) * 100) / 100;

                          this.guest.taxinclusive = true;
                          if (this.fullfetcheddata.roomChargesTaxInclusiveConfig == true) {
                            this.taxinclusiveval1 = true;
                          }
                          this.taxinclusiveval = true;
                          this.total_cost = (this.fullfetcheddata['bookingdata']['payload']['amount']['amountAfterTax']).toLocaleString();
                        }
                      }
                      if(this.fullfetcheddata['bookingdata']['roomName'] != null){
                        if (data.roomtypesInfo[roomtypeIndex]['displayName'] == this.fullfetcheddata['bookingdata']['roomName']) {
                          this.MealPlan = data.roomtypesInfo[roomtypeIndex]['mealplans'];
                          this.guest.rateplan = this.fullfetcheddata['bookingdata']['mealplan'];
                          this.rateplanid = this.fullfetcheddata['bookingdata']['rateplanId'];
                          var availablerooms = data.roomtypesInfo[roomtypeIndex]['availableRooms'];
                          this.guests = data.roomtypesInfo[roomtypeIndex]['numGuestsAllowed'];
                          this.guest.numguest = String(this.fullfetcheddata['bookingdata']['adults']);
                          this.extraChildArray = [];
                          for (var extraChild = 0; extraChild <= this.fullfetcheddata['bookingdata']['adults'] - 1; extraChild++) {
                            this.extraChildArray.push(String(extraChild));
                          }
                          this.guest.extraChild = String(this.fullfetcheddata['bookingdata']['children']);
                          data.roomtypesInfo[roomtypeIndex]['availableRoomNumbers'].push({
                            roomNum: this.fullfetcheddata['allocation'][0]['room_num'],
                            roomNumId: this.fullfetcheddata['allocation'][0]['room_num_id']});
                          this.room_numbers = data.roomtypesInfo[roomtypeIndex]['availableRoomNumbers'];
                          this.guest.room_num = this.fullfetcheddata['allocation'][0]['room_num'];
                          if (Number(this.fullfetcheddata['bookingdata']['numRooms']) > 1) {
                            this.morethanone = true;
                          } else {
                            this.morethanone = false;
                          }
                          if (availablerooms <= Number(this.fullfetcheddata['bookingdata']['numRooms'])) {
                            for (var roomcount = 1; roomcount <= Number(this.fullfetcheddata['bookingdata']['numRooms']); roomcount++) {
                              roomscount.push(roomcount);
                            }
                            this.roomsArray = roomscount;
                            this.guest.rooms = String(this.fullfetcheddata['bookingdata']['numRooms']);
                          } else {
                            for (var roomcount = 1; roomcount <= availablerooms; roomcount++) {
                              roomscount.push(roomcount);
                            }
                            this.roomsArray = roomscount;
                            this.guest.rooms = String(this.fullfetcheddata['bookingdata']['numRooms']);
                          }
                          
                        }
                      }else{
                        this.guests = [this.fullfetcheddata['bookingdata']['adults']];
                        this.guest.numguest = String(this.fullfetcheddata['bookingdata']['adults']);
                        this.roomsArray = [this.fullfetcheddata['bookingdata']['numRooms']];
                        this.guest.rooms = String(this.fullfetcheddata['bookingdata']['numRooms']);
                      }
                      
                    }
                  });
                this.guest.name = this.fullfetcheddata['guestdata']['name'];
                this.guest.email = this.fullfetcheddata['guestdata']['email'];
                this.guest.phone = this.fullfetcheddata['guestdata']['phone'];
                this.guest.idproof = this.fullfetcheddata['guestdata']['id_proof'];
                this.guest.id_detail = this.fullfetcheddata['guestdata']['id_detail'];
                const filesSelected = [];
                for (var i in this.fullfetcheddata['guestdata']['photo_id']) {
                  filesSelected.push(
                    this.fullfetcheddata['guestdata']['photo_id'][i]
                  );
                }
                this.fileselected = [...new Map(filesSelected.map((item) => [item['url'], item])).values(),];
                this.guest.dob = this.datePipe.transform(this.fullfetcheddata['guestdata']['dob'], 'yyyy-MM-dd');
                this.guest.gender = this.fullfetcheddata['guestdata']['gender'];
                this.guest.inputAddress = this.fullfetcheddata['guestdata']['address']['address'];
                this.guest.country = this.fullfetcheddata['guestdata']['address']['country'];
                this.selectedCountry = this.guestService.getShortNameForCountry(this.fullfetcheddata['guestdata']['address']['country'], this.countries)
                this.guest.zipcode = this.fullfetcheddata['guestdata']['address']['zip'];
                this.states = this.guestService.getStatesByCountry(this.selectedCountry);
                this.guest.state = this.fullfetcheddata['guestdata']['address']['state'];
                this.cities = this.guestService.getCitiesByState(this.selectedCountry, this.fullfetcheddata['guestdata']['address']['state'])
                this.guest.city = this.fullfetcheddata['guestdata']['address']['city'];
                this.specialrequest.push(this.fullfetcheddata['bookingdata']['preference']);
                this.guest.special_request = this.specialrequest[0];
                if(this.fullfetcheddata.guestdata.travel_data){
                  this.guest.purposeOfVisit = this.fullfetcheddata.guestdata.travel_data.purpose;
                  this.guest.comingFrom = this.fullfetcheddata.guestdata.travel_data.comingFrom;
                  this.guest.goingTo = this.fullfetcheddata.guestdata.travel_data.goingTo;
                }
                this.guest.internalComments = this.fullfetcheddata.guestdata.internal_comments;
                this.guest.vipGuest = this.fullfetcheddata.guestdata.vip_guest;
              }
            }
          },
          (err) => {
            const WarningDialog = {
              title: 'Error !',
              body: err['error']['error'],
              cancelBtn: 'Dismiss',
            };
            const dialogRef = this.matdialog.open(DialogComponent, {
              data: WarningDialog,
            });
          }
        );
    }
  }

  fetchRoomAllocationData() {
    this.guestService
      .getcompanydetails(this.hotelID)
      .subscribe((companydata) => {
        this.companydata = companydata['companiesInfo'];
      });
    this.userService
      .getRoomAllocationData(this.allocId, this.hotelID, this.currentUser)
      .subscribe(
        (allocData) => {
          this.allocationData = allocData;
          this.allowDeletePhotoId = allocData.allow_delete_photo_id;
          this.guest.bookedby = allocData.bookedBy;
          if (allocData.room.roomstatus != 'Checked out' && allocData.room.roomstatus != 'Cancelled' && allocData.room.roomstatus != 'No Show') {
            this.disableSelect = true;
            this.disableField = true;
            this.editStay = false;
            this.disable_delete = false;
            this.disableAmountEdit = true;
          } else {
            this.payment_mode_disable = true;
            this.disableAmountEdit = true;
            this.editStay = true;
            this.disableSelect = true;
            this.disableField = true;
            this.disable_delete = true;
            this.enableEditInvoice = true;
          }
          if (allocData.room.roomstatus == 'Checked in') {
            this.disable_delete = true;
            this.enableEditInvoice = true;
            if (allocData.segment != 'Management Block' && allocData.segment != 'Out of Order' && allocData.enableRateEdit == true) {
              this.disableAmountEdit = false;
            } else {
              this.disableAmountEdit = true;
            }
          }
          this.guest['old_allocation_data'] = allocData;
          this.guest.checkin = this.datePipe.transform(allocData.checkin_date, 'yyyy-MM-dd');
          this.guest.checkout = this.datePipe.transform(allocData.checkout_date, 'yyyy-MM-dd');
          (<HTMLInputElement>document.getElementById('checkoutDate')).min = new Date(allocData.current_date).toISOString().split('T')[0];
          this.guest.nights = allocData.room.nights;
          if (allocData.room.nights == '0'){
            this.nights = '1';
          }else{
            this.nights = allocData.room.nights;
          }
          var checkin = this.guest.checkin;
          var checkout = this.guest.checkout;
          if (this.guest.checkin == this.guest.checkout) {
            let nextday = new Date(this.guest.checkout);
            nextday.setDate(nextday.getDate() + 1);
            checkout = this.datePipe.transform(nextday, 'yyyy-MM-dd');
          }
          if (
            allocData.room.roomstatus == 'Checked in' &&
            this.guest.checkout >
              new Date(allocData.current_date).toISOString().split('T')[0]
          ) {
            checkin = new Date(allocData.current_date).toISOString().split('T')[0];
          }
          this.guestService.getRequiredInfo(this.hotelID, checkin, checkout, 'editStay', null, this.allocId).subscribe((requiredData) => {
              this.requiredBookingInfo = requiredData;
              this.showTravelData = requiredData.showTravelData;
              this.showCityDropDown = requiredData.showCityDropDown;
              this.purposeOfVisitList = requiredData.purposeOfVisitList;
              this.identityList = requiredData.identityList;
              this.paymentModes = requiredData.paymentModes;
              this.customChannels = requiredData.customChannels;
              if (allocData.pms_payment.billTo) {
                this.Guest = allocData.pms_payment.billTo;
                this.guest.billto = this.Guest;
                this.guest.PaymentMode = allocData.pms_payment.payment_mode;
              } else {
                this.Guest = allocData.pms_payment.bill_to;
                this.guest.billto = this.Guest;
                this.guest.PaymentMode = allocData.pms_payment.payment_mode;
              }
              this.guest.currencycode = requiredData.currencyCode;
              this.room_types = requiredData.roomtypes;
              this.guest.roomtype = allocData.room.roomType;
              this.guest['roomidselected'] = allocData.room.roomId;
              for (var roomtypeIndex in requiredData.roomtypes) {
                if (
                  requiredData.roomtypesInfo[roomtypeIndex]['displayName'] ==  allocData.room.roomType) {
                  this.MealPlan = requiredData.roomtypesInfo[roomtypeIndex]['mealplans'];
                  this.guest.rateplan = allocData.room.mealplan;
                  this.rateplanid = allocData.room.rateplanId;
                  this.guests = requiredData.roomtypesInfo[roomtypeIndex]['numGuestsAllowed'];
                  this.guest.numguest = allocData['no_of_guests'];
                  this.roomsArray.push(1);
                  this.guest.rooms = this.roomsArray[0];
                  requiredData.roomtypesInfo[roomtypeIndex][
                    'availableRoomNumbers'
                  ].push({
                    roomNum: allocData.room.roomNum,
                    roomNumId: allocData.room.roomNumId,
                  });
                  this.room_numbers = requiredData.roomtypesInfo[roomtypeIndex]['availableRoomNumbers'];
                  this.guest.room_num = allocData.room.roomNum;
                  this.guest['room_num_id_old'] = allocData.room.roomNumId;
                }
              }
            });
          this.currentDate = allocData.current_date;
          this.guest.name = allocData.name;
          this.guest.email = allocData.email;
          this.guest.phone = allocData.phone;
          this.guest.dob = this.datePipe.transform(allocData.date_of_birth, 'yyyy-MM-dd');
          this.guest.gender = allocData.gender;
          this.guest.idproof = allocData.id_proof;
          this.guest.id_detail = allocData.id_detail;
          if(allocData.travel_data){
            this.guest.purposeOfVisit = allocData.travel_data.purpose;
            this.guest.comingFrom = allocData.travel_data.comingFrom;
            this.guest.goingTo = allocData.travel_data.goingTo;
          }
          this.guest.vipGuest = allocData.vip_guest;
          this.guest.internalComments = allocData.internal_comments;
          this.rateperroom['pretax_amount_perday'] = allocData.total_price.total_pretax_amount / Number(this.nights);
          this.rateperroom['tax_amount_perday'] = allocData.total_price.total_tax / Number(this.nights);
          var total_pretax_from_daywise = 0.0;
          for (var pretax_amount in allocData.room_price_daywise) {
            total_pretax_from_daywise = total_pretax_from_daywise + allocData.room_price_daywise[pretax_amount]['pretax_amount'];
          }
          if ( allocData.total_price.total_pretax_amount != total_pretax_from_daywise || allocData.taxInclusive == true) {
            this.guest.taxinclusive = true;
            this.guest.amount = Number((Number(allocData.perdayPretaxRate) + Number(allocData.perdayTax)).toFixed(3));
            this.guest.tax = 0;
            if (allocData.roomChargesTaxInclusiveConfig == true) {
              this.taxinclusiveval1 = true;
            }
          } else {
            this.guest.amount = allocData.perdayPretaxRate;
            this.guest.tax = allocData.perdayTax;
          }
          this.total_cost = allocData.total_price.total_after_tax.toLocaleString();
          if (allocData.channel == 'PMS') {
            this.channel = allocData.channel;
            this.guest.business = allocData.segment;
            if (allocData.segment == 'Management Block') {
              this.disableSelect = true;
              this.payment_mode_disable = true;
              this.managementBlock = true;
            }
          }
          if (allocData.segment == 'Out of Order') {
            this.disableSelect = true;
            this.payment_mode_disable = true;
            this.guest.business = 'Out of Order';
            this.outOfOrder = true;
          } else {
            this.guest.inputAddress = allocData.address.address;
            this.selectedCountry = this.guestService.getShortNameForCountry(allocData.address.country, this.countries);
            this.guest.country = allocData.address.country;
            this.states = this.guestService.getStatesByCountry(this.selectedCountry);
            this.guest.state = allocData.address.state;
            this.cities = this.guestService.getCitiesByState(this.selectedCountry, allocData.address.state)
            this.guest.city = allocData.address.city;
            this.guest.zipcode = allocData.address.zip;
          }
          if (
            allocData.channel != 'PMS' &&
            allocData.segment != 'Out of Order'
          ) {
            this.guest.business = 'OTA';
            this.channel = allocData.channel;
          }

          this.guest['guestID'] = allocData.guest_id;
          this.guest['bookingId'] = allocData.bookingId;
          this.guest.special_request = allocData.room.specialRequest;
          const filesSelected = [];
          for (var i in allocData.photo_ID) {
            filesSelected.push(allocData.photo_ID[i]);
          }
          this.fileselected = [
            ...new Map(
              filesSelected.map((item) => [item['url'], item])
            ).values(),
          ];
        },
        (err) => {
          console.log(err);
        }
      );
  }

  // update the guest name in bill to form if guest option selected
  updatename(event: Event) {
    var billvalue = (<HTMLInputElement>document.getElementById('billto1'))
      .value;
    if (billvalue === 'Guest') {
      this.Guest = (<HTMLInputElement>event.target).value;
      this.guest.billto = this.Guest;
    }
  }
  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  // calculate the guest stay nights by selected date range
  days(checkinDateChanged = false) {
    this.staynights = [];
    var checkinValue = new Date(this.guest.checkin);
    var checkoutValue = new Date(this.guest.checkout);
    if (this.mode != 'editStay') {
      if ((this.guest.checkin > this.guest.checkout) || (this.guest.checkin < this.guest.checkout && checkinDateChanged == true && this.prevReservation == true)) {
        this.nextdaydate = this.addDays(checkinValue, 1);
        this.guest.checkout = this.nextdaydate.toISOString().split('T')[0];
        checkoutValue = this.nextdaydate;
      }
      // var checknextdate = this.addDays(checkinValue, 1);
      (<HTMLInputElement>document.getElementById('checkoutDate')).min =
      checkinValue.toISOString().split('T')[0];
    }
    const date1: any = new Date(this.guest.checkin);
    const date2: any = new Date(this.guest.checkout);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.staynights.push(diffDays.toString());
    this.guest.nights = this.staynights[0];
    if (this.staynights[0] == '0'){
      this.nights = '1';
    }else{
      this.nights = this.staynights[0];
    }
    if (this.mode != 'editStay' && this.optionselected['modify'] != 'true') {
      this.getBookingRequiredInfo(this.guest.checkin, this.guest.checkout);
    } else if (
      this.mode != 'editStay' &&
      this.optionselected['modify'] == 'true'
    ) {
      this.getBookingRequiredInfo(
        this.guest.checkin,
        this.guest.checkout,
        'modify',
        this.fullfetcheddata['bookingdata']['bookingId']
      );
    } else if (this.mode == 'editStay') {
      if (date2 < new Date(this.allocationData.checkout_date)) {
        var totalAmount: Number =
          this.allocationData.total_price['total_after_tax'];
        for (var day in this.allocationData.room_price_daywise) {
          var dateToRemove = new Date(
            this.allocationData.room_price_daywise[day]['date']
          ).setHours(0, 0, 0, 0);
          if (dateToRemove >= date2.setHours(0, 0, 0, 0) && date1.setHours(0, 0, 0, 0) != dateToRemove) {
            totalAmount =
              Number(totalAmount) -
              Number(
                this.allocationData.room_price_daywise[day]['pretax_amount']
              ) -
              Number(
                this.allocationData.room_price_daywise[day]['tax']['total']
              );
          }
        }
        this.total_cost = formatNumber(Number(totalAmount), 'en-US', '2.0-2');
      } else {
        this.amountChange();
      }
    }
  }

  amountChange() {
    if (this.guest.business == 'Management Block') {
      this.total_cost = '0';
    } else {
      if (this.guest.nights == '0'){
        this.nights = '1';
      }else{
        this.nights = this.guest.nights;
      }
      var amount = Number(this.guest.amount);
      this.guestService
        .getAutoAddExtraServicesInfo(
          this.hotelID,
          amount,
          this.guest.rateplan,
          this.nights,
          this.guest.numguest
        )
        .subscribe((extraServiceInfo) => {
          this.extraServiceAmount = extraServiceInfo['totalExtraServiceCharge'];
          if (this.guest.taxinclusive == false) {
            this.guestService
              .fetchtax(this.hotelID, amount)
              .subscribe((taxdata) => {
                this.guest.tax = taxdata['rounded_tax'];
                if (this.mode !== 'editStay') {
                  this.total_cost = (
                    (((Number(this.guest.amount) - (Number(this.guest.amount) * this.guest.discountValue/100.0)) + (taxdata['rounded_tax'] - (taxdata['rounded_tax'] * this.guest.discountValue/100.0))) *
                      Number(this.guest.rooms) *
                      Number(this.nights) +
                    this.extraServiceAmount * Number(this.guest.rooms))
                  ).toLocaleString();
                } else {
                  this.totalAmountCalcForAllocation(taxdata);
                }
              });
          } else {
            if (this.mode !== 'editStay') {
              this.total_cost = ((
                (Number(this.guest.amount) - (Number(this.guest.amount) * this.guest.discountValue/100.0)) *
                  Number(this.guest.rooms) *
                  Number(this.nights) +
                this.extraServiceAmount * Number(this.guest.rooms))
              ).toLocaleString();
            } else {
              this.totalAmountCalcForAllocation();
            }
          }
        });
    }
  }

  extraChildChange(extraChild, roomtype, mealplan, numGuests, rooms) {
    if (this.guest.nights == '0'){
      this.nights = '1';
    }else{
      this.nights = this.guest.nights;
    }
    for (var roomtypeinfo in this.requiredBookingInfo.roomtypesInfo) {
      if (
        this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['displayName'] == roomtype
      ) {
        for (var rateplanIndex in this.requiredBookingInfo.roomtypesInfo[
          roomtypeinfo
        ]['rateplans']) {
          if (
            this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][
              rateplanIndex
            ]['mealplan'] == mealplan &&
            this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][
              rateplanIndex
            ]['numOfGuest'] == numGuests
          ) {
            this.extraChildValue =
              this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][
                rateplanIndex
              ]['extraChildRate'];
            var amount =
              this.requiredBookingInfo.roomtypesInfo[roomtypeinfo]['rateplans'][
                rateplanIndex
              ]['perDayPretaxRate'];
            this.guest.amount = amount + this.extraChildValue * extraChild;
            this.guestService
              .getAutoAddExtraServicesInfo(
                this.hotelID,
                amount + this.extraChildValue * extraChild,
                this.guest.rateplan,
                this.nights,
                this.guest.numguest
              )
              .subscribe((extraServiceInfo) => {
                this.extraServiceAmount =
                  extraServiceInfo['totalExtraServiceCharge'];
                if (this.guest.taxinclusive == false) {
                  this.guestService
                    .fetchtax(
                      this.hotelID,
                      amount + this.extraChildValue * extraChild
                    )
                    .subscribe((taxdata) => {
                      this.guest.tax = taxdata['rounded_tax'];
                      if (this.mode !== 'editStay') {
                        this.total_cost = (
                          (Number(amount + this.extraChildValue * extraChild) +
                            taxdata['rounded_tax']) *
                            Number(this.guest.rooms) *
                            Number(this.nights) +
                          this.extraServiceAmount * Number(this.guest.rooms)
                        ).toLocaleString();
                      }
                    });
                } else {
                  if (this.mode !== 'editStay') {
                    this.total_cost = (
                      Number(amount + this.extraChildValue * extraChild) *
                        Number(this.guest.rooms) *
                        Number(this.nights) +
                      this.extraServiceAmount * Number(this.guest.rooms)
                    ).toLocaleString();
                  }
                }
              });
          }
        }
      }
    }
  }

  taxinclusiveChange(perDayAmount, taxinclusive) {
    if (taxinclusive == true) {
      this.guest.tax = 0;
      this.amountChange();
    } else {
      this.amountChange();
    }
  }

  totalAmountCalcForAllocation(taxdata = null) {
    if (this.guest.nights == '0'){
      this.nights = '1';
    }else{
      this.nights = this.guest.nights;
    }
    const date1: any = new Date(this.guest.checkin);
    const date2: any = new Date(this.allocationData.current_date);
    const date3: any = new Date(this.guest.checkout);
    const date4: any = new Date(this.allocationData.checkout_date);
    const diffTime1 = Math.abs(date2 - date1);
    const diffDays1 = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24));
    const diffTime2 = Math.abs(date3 - date2);
    var diffDays2 = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));
    if (
      new Date(date2.setHours(0, 0, 0, 0)) <=
      new Date(date1.setHours(0, 0, 0, 0))
    ) {
      diffDays2 = Number(this.nights);
    }
    var currentDate = new Date(
      new Date(this.allocationData.current_date).toDateString()
    );
    var totalAmount = 0.0;
    var extraServiceAmountTotal = 0.0;

    if (
      (
        this.allocationData.perdayPretaxRate + this.allocationData.perdayTax
      ).toFixed(2) ==
        (Number(this.guest.amount) + Number(this.guest.tax)).toFixed(2) &&
      this.guest.checkout > this.allocationData.checkout_date
    ) {
      const diffTime = Math.abs(date3 - date4);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      extraServiceAmountTotal =
        this.allocationData.extraServiceAmountPerDay * diffDays;
    }
    for (var day in this.allocationData.room_price_daywise) {
      var date = new Date(this.allocationData.room_price_daywise[day]['date']);
      if (
        new Date(date.setHours(0, 0, 0, 0)) <
          new Date(currentDate.setHours(0, 0, 0, 0)) &&
        this.guest.checkout != this.allocationData.current_date.slice(0, 10)
      ) {
        totalAmount =
          totalAmount +
          this.allocationData.room_price_daywise[day]['pretax_amount'] +
          this.allocationData.room_price_daywise[day]['tax']['total'];
      }
    }
    if (taxdata) {
      this.total_cost = (
        totalAmount +
        (Number(this.guest.amount) + Number(taxdata['rounded_tax'])) *
          diffDays2 +
        extraServiceAmountTotal
      ).toLocaleString();
    } else {
      this.total_cost = (
        totalAmount +
        Number(this.guest.amount) * diffDays2 +
        extraServiceAmountTotal
      ).toLocaleString();
    }
  }

  searchemailid(emailid): void {
    this.guestService
      .searchprovidedemail(this.hotelID, emailid)
      .subscribe((fetchedguestdata1) => {
        this.fetchedguestdata = fetchedguestdata1;
        var length_ofdata = Object.keys(this.fetchedguestdata).length;
        if (length_ofdata == 0) {
          const WarningDialog = {
            title: 'Error !',
            body: `Sorry!, could not find this in our records!!. You can either enter full email or try searching with different name.`,
            cancelBtn: 'Dismiss',
          };
          const dialogRef = this.matdialog.open(DialogComponent, {
            data: WarningDialog,
          });
        } else {
          this.fetchemaildata(emailid);
        }
      });
  }
  fetchemaildata(email) {
    for (
      var emailid = 0;
      emailid < Object.keys(this.fetchedguestdata).length;
      emailid++
    ) {
      if (this.fetchedguestdata[emailid]['email_id'] == email) {
        this.guest.name = this.fetchedguestdata[emailid]['name'];
        this.guest.phone = this.fetchedguestdata[emailid]['phone_number'];
        this.guest.idproof = this.fetchedguestdata[emailid]['id_proof'];
        this.guest.dob = this.fetchedguestdata[emailid]['dob'];
        this.guest.gender = this.fetchedguestdata[emailid]['gender'];
        this.guest.inputAddress =
          this.fetchedguestdata[emailid]['address']['address'];
        this.guest.city = this.fetchedguestdata[emailid]['address']['city'];
        this.guest.state = this.fetchedguestdata[emailid]['address']['state'];
        this.guest.country =
          this.fetchedguestdata[emailid]['address']['country'];
        this.guest.zipcode = this.fetchedguestdata[emailid]['address']['zip'];
        this.specialrequest.push(
          this.fetchedguestdata[emailid]['preference'][0]
        );
        this.guest.special_request = this.specialrequest[0];
        this.guest.id_detail = this.fetchedguestdata[emailid]['id_detail'];
        const filesSelected = [];
        for (var id in this.fetchedguestdata[emailid]['photo_id']) {
          filesSelected.push(this.fetchedguestdata[emailid]['photo_id'][id]);
        }if (filesSelected.length > 0){
          this.fileselected = [
            ...new Map(filesSelected.map((item) => [item['url'], item])).values(),
          ];
        }
      }
    }
  }
  searchphone(phonenum): void {
    this.guestService
      .searchprovidedphonenum(this.hotelID, phonenum)
      .subscribe((fetchedguestdata2) => {
        this.fetchedguestdata = fetchedguestdata2;
        var length_ofdata = Object.keys(this.fetchedguestdata).length;
        if (length_ofdata == 0) {
          const WarningDialog = {
            title: 'Error !',
            body: `Sorry!, could not find this in our records!!`,
            cancelBtn: 'Dismiss',
          };
          const dialogRef = this.matdialog.open(DialogComponent, {
            data: WarningDialog,
          });
        } else {
          this.fetchphone(phonenum);
        }
      });
  }
  fetchphone(phonenum): void {
    for (
      var phone_rec = 0;
      phone_rec < Object.keys(this.fetchedguestdata).length;
      phone_rec++
    ) {
      if (this.fetchedguestdata[phone_rec]['phone_number'] == phonenum) {
        
        this.guest.name = this.fetchedguestdata[phone_rec]['name'];
        this.guest.idproof = this.fetchedguestdata[phone_rec]['id_proof'];
        this.guest.email = this.fetchedguestdata[phone_rec]['email_id'];
        this.guest.dob = this.fetchedguestdata[phone_rec]['dob'];
        this.guest.gender = this.fetchedguestdata[phone_rec]['gender'];
        this.guest.inputAddress =
          this.fetchedguestdata[phone_rec]['address']['address'];
        this.guest.city = this.fetchedguestdata[phone_rec]['address']['city'];
        this.guest.state = this.fetchedguestdata[phone_rec]['address']['state'];
        this.guest.country =
          this.fetchedguestdata[phone_rec]['address']['country'];
        this.guest.zipcode = this.fetchedguestdata[phone_rec]['address']['zip'];
        this.specialrequest.push(
          this.fetchedguestdata[phone_rec]['preference'][0]
        );
        this.guest.special_request = this.specialrequest[0];
        this.guest.id_detail = this.fetchedguestdata[phone_rec]['id_detail'];
        const filesSelected = [];
        for (var id in this.fetchedguestdata[phone_rec]['photo_id']) {
          filesSelected.push(this.fetchedguestdata[phone_rec]['photo_id'][id]);
        }if (filesSelected.length > 0){
          this.fileselected = [
            ...new Map(filesSelected.map((item) => [item['url'], item])).values(),
          ];
        }
        
      }
    }
  }

  handleInput(event) {
    var filelength = Object.keys(event.files).length;
    this.currentuploadedFiles = []
    for (var i = 0; i < filelength; i++) {
      let randomnumber = crypto.getRandomValues(new Uint32Array(1));
      var filename = randomnumber +'-'+ event.files[i].name
      this.guestService
        .uploadselectedfiles(this.hotelID, event.files[i], filename)
        .subscribe(
          (success) => {
            this.fileselected.push(success);
            this.currentuploadedFiles.push(success);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  removeImage(url) {
    if (this.currentuploadedFiles.length>0 && this.currentuploadedFiles.findIndex(function (i) {
      return i === url;
    }) >=0 || this.allowDeletePhotoId == true){
      this.fileselected.splice(
        this.fileselected.findIndex(function (i) {
          return i === url;
        }),
        1
      );
    }
  }

  goToAdditionlGuests() {
    const hotelId = this.hotelID;
    const idList = this.identityList;
    var guestId = null;
    var allocId = this.allocId;
    if (this.allocationData) {
      guestId = this.allocationData.guest_id;
    } else if (this.fullfetcheddata) {
      guestId = this.fullfetcheddata['allocation'][0]['guest_id'];
    }
    this.matdialog.open(AdditionalGuestComponent, {
      width: '90%',
      autoFocus: false,
      maxHeight: '390vh',
      data: {
        idList,
        hotelId,
        guestId,
        allocId,
        queryParams: { user: this.currentUser },
        
      },
    });
  }

  editInvoice() {
    this.userService
      .getInvoiceDetails(this.allocId, this.hotelID)
      .subscribe((data) => {
        this.matdialog.open(EditInvoiceComponent, {
          data: {
            data,
            roomAllocationId: this.allocId,
            user: this.currentUser,
            hotelId: this.hotelID,
            status: 'checked-in',
          },
        });
      });
  }

  editBookingId() {
    var oldBookingId = this.guest['bookingId'];
    this.matdialog.open(EditBookingIdComponent, {
      data: {
        oldId: oldBookingId,
        roomAllocationId: this.allocId,
        user: this.currentUser,
        hotelId: this.hotelID,
        currentChannel: this.channel,
      },
    });
  }

  showGuestList() {
    var keyword = '';
    if(this.guest.email){
      keyword = this.guest.email;
    }else if(this.guest.phone){
      keyword = this.guest.phone;
    }else if (this.guest.name){
      keyword = this.guest.name;
    }
    const dialogRef = this.matdialog.open(GuestSearchTableComponent, {
      data: {
        roomAllocationId: this.allocId,
        user: this.currentUser,
        hotelId: this.hotelID,
        keyword: keyword
      },
      width: '95%',
      height: '80%',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['data']){
        this.guest['guestID'] = result['data'].guestId;
        this.guest.name = result['data']['name'];
        this.guest.idproof = result['data']['idProof'];
        this.guest.email = result['data']['email'];
        this.guest.phone = result['data']['phone'];
        this.guest.dob = result['data']['dob'];
        this.guest.gender = result['data']['gender'];
        this.guest.inputAddress = result['data']['address']['address'];
        this.guest.city = result['data']['address']['city'];
        this.guest.state = result['data']['address']['state'];
        this.guest.country = result['data']['address']['country'];
        this.guest.zipcode = result['data']['address']['zip'];
        this.specialrequest.push(
          result['data']['preference'][0]
        );
        this.guest.special_request = this.specialrequest[0];
        this.guest.id_detail = result['data']['idDetail'];
        const filesSelected = [];
        for (var id in result['data']['photoId']) {
          filesSelected.push(result['data']['photoId'][id]);
        }if (filesSelected.length > 0){
          this.fileselected = [
            ...new Map(filesSelected.map((item) => [item['url'], item])).values(),
          ];
        }
      }
      
    });
  }

  showCompaniesList() {
    var keyword = '';
    if(this.guest.billto && this.guest.billto!='Guest'){
      keyword = this.guest.billto;
    }
    const dialogRef = this.matdialog.open(CompanySearchTableComponent, {
      data: {
        roomAllocationId: this.allocId,
        user: this.currentUser,
        hotelId: this.hotelID,
        keyword: keyword,
        companyData: this.companydata
      },
      width: '95%',
      height: '80%',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['data']){
        this.guest.billto = result['data']['name'];
        this.fetchrate_forCompany(result['data']['name'])
      }
      
    });
  }

  fetchLogs(roomAllocationId) {
    this.fetchinglogs = true;
    this.reservdataService
      .fetchLogsOfAllocId(roomAllocationId, this.hotelID)
      .subscribe((result) => {
        this.fetchinglogs = false;
        this.matdialog.open(LogsComponent, {
          data: {
            logs: result.logs,
            user: this.currentUser,
            hotelId: this.hotelID,
          },
        });
      });
  }

  // CHANGE ANIMATION STATE
  changeState(val) {
    this.AnimationStates[val] =
      this.AnimationStates[val] === 'initial' ? 'final' : 'initial';
  }

  changePage(outOfOrder = false, managementBlock = false){
    this.outOfOrder = outOfOrder;
    this.managementBlock = managementBlock;
    this.getTimeZone();
    this.disablefields();
    this.optionselected['managementBlock'] = managementBlock;
    this.optionselected['outOfOrder'] = outOfOrder;
    this.requiredState = !outOfOrder
  }
  
  fileChange(event) {
    this.newFile = event.files[0];
  }

  onSubmit(f: NgForm): void {
    if (!this.guest.paymentLinkAmount) {
      this.guest.paymentLinkAmount = parseFloat(this.total_cost.replace(/,/g, ''));
    }
    if (this.guest.nights == '0'){
      this.nights = '1';
    }else{
      this.nights = this.guest.nights;
    }
    if (this.guest.checkin > this.guest.checkout) {
      this.dateinvalid = true;
      const WarningDialog = {
        title: 'Error',
        body: `Checkout date is less than Checkin date. Kindly correct it and submit`,
        cancelBtn: 'Dismiss',
      };
      this.matdialog.open(DialogComponent, { data: WarningDialog });
    } else {
      this.guest['company_details'] = this.selectedcompanydetails;
      this.guest['files'] = this.fileselected;
      this.guest['hotelId'] = this.hotelID;
      this.guest['room_num_id'] = this.selectedroom_num;
      this.guest['rateplan_id'] = this.rateplanid;
      this.guest['user'] = this.currentUser;
      if (typeof this.guest['files'] === 'undefined') {
        this.guest['files'] = [];
      }
      if (this.mode === 'editStay') {
        this.guest['room_num_id_new'] = this.selectedroom_num;
        this.guest['allocation_id'] = this.allocId;
        this.guest['channel'] = this.channel;
        this.guest['action'] = 'modify allocation';
        if (
          this.rateplanid != null &&
          this.rateplanid.includes(this.guest['roomidselected'])
        ) {
          this.guest['selected_rateplan'] = this.rateplanid;
        } else {
          this.guest['selected_rateplan'] = null;
        }
        if (
          this.allocationData.perdayPretaxRate +
            this.allocationData.perdayTax ==
          Number(this.guest.amount) + Number(this.guest.tax)
        ) {
          this.guest['rateEdited'] = false;
        } else {
          this.guest['rateEdited'] = true;
        }
        const dialogBody = {
          title: 'Modify Allocation',
          body: 'Are you sure you want to Submit ?',
          confirmBtn: 'Submit',
          cancelBtn: 'Cancel',
        };
        const dialogRef = this.matdialog.open(DialogComponent, {
          data: dialogBody,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'true') {
            this.submitting = true;
            this.guestService.modifyGuest(this.hotelID, this.guest).subscribe(
              (response1) => {
                this.submitting = false;
                var WarningDialog = {};
                if (response1['error']) {
                  WarningDialog = {
                    title: 'Error !',
                    body: response1['error'],
                    cancelBtn: 'Dismiss',
                  };
                } else {
                  WarningDialog = {
                    title: 'Done !',
                    body: `You Have successfully modified the data`,
                    cancelBtn: 'Dismiss',
                  };
                }
                const dialogRef = this.matdialog.open(DialogComponent, {
                  data: WarningDialog,
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === 'false') {
                    this.matdialog.closeAll();
                    window.location.reload();
                  }
                });
              },
              (err) => {
                const WarningDialog = {
                  title: 'ERROR !',
                  body: 'Something went wrong!! kindly refresh the page',
                  cancelBtn: 'Dismiss',
                };
                this.matdialog.open(DialogComponent, { data: WarningDialog });
                this.submitting = false;
              }
            );
          }
        });
      } else if (this.optionselected['modify'] == 'true') {
        if (this.guest.prePaidAmount !== 0 && this.guest.prePaymentMode == null){
          const WarningDialog = {
            title: 'Error !',
            body: `Kindly select the payment Mode for pre payment`,
            cancelBtn: 'Dismiss',
          };
          this.matdialog.open(DialogComponent, { data: WarningDialog });
          return;
        }
        if (this.guest.prePaymentMode == 'Bill to Company' && this.guest.billtoForPrePaid == null){
          const WarningDialog = {
            title: 'Error !',
            body: `Kindly select the company`,
            cancelBtn: 'Dismiss',
          };
          this.matdialog.open(DialogComponent, { data: WarningDialog });
          return;
        }
        const dialogBody = {
          title: 'Modify Reservation',
          body: 'Are you sure you want to Submit ?',
          confirmBtn: 'Submit',
          cancelBtn: 'Cancel',
        };
        const dialogRef = this.matdialog.open(DialogComponent, {
          data: dialogBody,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'true') {
            this.submitting = true;
            this.guest['booking_id'] = this.optionselected['Booking_ID'];
            this.guest['action'] = 'modify';
            this.guest.amount =
              Number(this.guest.amount) *
              Number(this.nights) *
              Number(this.guest.rooms);
            this.guest.tax =
              Number(this.guest.tax) *
              Number(this.nights) *
              Number(this.guest.rooms);
            this.guest['billto'] = (<HTMLInputElement>(
              document.getElementById('billto1')
            )).value;
            if (this.newFile){
              const ts = new Date().getTime();
              this.userService
                .uploadFile(this.newFile, `${this.hotelID}/${ts}-${this.newFile.name}`)
                .subscribe(
                  (img) => {
                    this.guest['paymentReceipt'] = img.url;
                    this.modifyReservationInfo();
                  })
            }else{
              this.modifyReservationInfo();
            } 
          }
        });
      } else if (this.optionselected['outOfOrder'] == true || this.outOfOrder == true) {
        const dialogBody = {
          title: 'Block room',
          body: 'Are you sure you want to Block the room ?',
          confirmBtn: 'Yes',
          cancelBtn: 'Cancel',
        };
        const dialogRef = this.matdialog.open(DialogComponent, {
          data: dialogBody,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'true') {
            this.submitting = true;
            this.guestService
              .outoforderBlock(this.hotelID, this.guest)
              .subscribe(
                (success) => {
                  var WarningDialog = {};
                  if (success['error']) {
                    WarningDialog = {
                      title: 'ERROR !',
                      body: success['error'],
                      cancelBtn: 'Dismiss',
                    };
                  } else {
                    WarningDialog = {
                      title: 'Blocked !',
                      body: `You Have successfully Blocked the room`,
                      cancelBtn: 'Dismiss',
                    };
                    this.location.back();
                  }
                  this.matdialog.open(DialogComponent, { data: WarningDialog });
                  this.submitting = false;
                },
                (err) => {
                  const WarningDialog = {
                    title: 'ERROR !',
                    body: err['error'],
                    cancelBtn: 'Dismiss',
                  };
                  this.matdialog.open(DialogComponent, { data: WarningDialog });
                  this.submitting = false;
                }
              );
          }
        });
      } else {
        if (this.guest.prePaidAmount !== 0 && this.guest.prePaymentMode == null){
          const WarningDialog = {
            title: 'Error !',
            body: `Kindly select the payment Mode for pre payment`,
            cancelBtn: 'Dismiss',
          };
          this.matdialog.open(DialogComponent, { data: WarningDialog });
          return;
        }
        if (this.guest.prePaymentMode == 'Bill to Company' && this.guest.billtoForPrePaid == null){
          const WarningDialog = {
            title: 'Error !',
            body: `Kindly select the company`,
            cancelBtn: 'Dismiss',
          };
          this.matdialog.open(DialogComponent, { data: WarningDialog });
          return;
        }
        const dialogBody = {
          title: 'Submit New Reservation',
          body: 'Are you sure you want to Submit ?',
          confirmBtn: 'Submit',
          cancelBtn: 'Cancel',
        };

        const dialogRef = this.matdialog.open(DialogComponent, {
          data: dialogBody,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'true') {
            this.guest.amount =
              Number(this.guest.amount) *
              Number(this.nights) *
              Number(this.guest.rooms);
            this.guest.tax =
              Number(this.guest.tax) *
              Number(this.nights) *
              Number(this.guest.rooms);
            this.submitting = true;
            this.guest['action'] = 'book';
            this.guest['billto'] = (<HTMLInputElement>(
              document.getElementById('billto1')
            )).value;
            if (this.newFile){
              const ts = new Date().getTime();
              this.userService
                .uploadFile(this.newFile, `${this.hotelID}/${ts}-${this.newFile.name}`)
                .subscribe(
                  (img) => {
                    this.guest['paymentReceipt'] = img.url;
                    this.addGuestData();
                  })
            }else{
              this.addGuestData();
            } 
          }
        });
      }
    }
  }


  addGuestData(){
    this.guestService.addGuest(this.guest).subscribe(
      (success) => {
        const WarningDialog = {
          title: 'Thank you !',
          body: `You Have successfully made new reservation`,
          cancelBtn: 'Dismiss',
        };
        this.matdialog.open(DialogComponent, { data: WarningDialog });
        this.submitting = false;
        // this.commS.emitStayViewReload();
        this.location.back();
      },
      (err) => {
        const WarningDialog = {
          title: 'ERROR !',
          body: 'Something went wrong!! Your data might be submitted, kindly check in reservations list',
          cancelBtn: 'Dismiss',
        };
        this.matdialog.open(DialogComponent, { data: WarningDialog });
        this.submitting = false;
        // this.commS.emitStayViewReload();
        this.location.back();
      }
    );
  }

  modifyReservationInfo(){
    this.guestService.modifyReservation(this.guest).subscribe((response) => {
      var WarningDialog = {};
      if (response['error']) {
        WarningDialog = {
          title: 'ERROR !',
          body: 'Something went wrong!! Your data might be submitted, kindly check in reservations list',
          cancelBtn: 'Dismiss',
        };
      } else {
        WarningDialog = {
          title: 'Modify Reservation !',
          body: `You Have successfully modified reservation`,
          cancelBtn: 'Dismiss',
        };
      }
      this.matdialog.open(DialogComponent, { data: WarningDialog });
      this.submitting = false;
      this.commS.emitStayViewReload();
      this.location.back();
    });
  }

  deleteStay() {
    if (this.guest['room_num_id_old'] === 'undefined') {
      this.guest['room_num_id_old'] = null;
    }
    var checkin = this.guest.checkin;
    var checkout = this.guest.checkout;
    var room_num_id = this.guest['room_num_id_old'];
    if (this.guest.business == 'Out of Order') {
      const dialogBody = {
        title: 'DELETE',
        body: `Are you sure you want to delete this allocation?`,
        confirmBtn: 'Yes',
        cancelBtn: 'No',
        width: '400px',
        height: '150px',
      };
      const dialogRef = this.matdialog.open(DialogComponent, {
        data: dialogBody,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'true') {
          const body = {
            checkin,
            checkout,
            roomNumId: room_num_id,
            user: this.currentUser,
            status: 'Out of Order',
          };
          this.userService
            .deleteRoomAllocation(this.allocId, this.hotelID, body)
            .subscribe(
              (success) => {
                this.matdialog.closeAll();
                window.location.reload();
              },
              (err) => {
                window.location.reload();
              }
            );
        }
      });
    } else {
      this.userService
        .getNumRooms(this.hotelID, this.guest['bookingId'])
        .subscribe(
          (num) => {
            if (num.num_rooms === 1) {
              const dialogBody = {
                title: 'DELETE',
                body: 'Do you want to delete the entry and cancel the reservation ?',
                confirmBtn: 'Yes',
                cancelBtn: 'No',
                width: '400px',
                height: '170px',
              };
              const dialogRef = this.matdialog.open(DialogComponent, {
                data: dialogBody,
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result === 'true') {
                  const body = {
                    bookingid: this.guest['bookingId'],
                    checkin: this.guest['checkin'],
                    checkout: this.guest['checkout'],
                    amount: this.total_cost,
                    user: this.currentUser,
                  };
                  this.reservdataService
                    .fetchpayload(body, this.hotelID)
                    .subscribe((response) => {
                      response['payload']['user'] = this.currentUser;
                      var payloadlist = [response['payload']];
                      this.reservdataService
                        .cancelReservation(payloadlist)
                        .subscribe(
                          (success) => {
                            if (success['error']) {
                              const WarningDialog = {
                                title: 'ERROR !',
                                body:
                                  success['error'] +
                                  ` kindly refresh the page.`,
                                cancelBtn: 'Dismiss',
                              };
                              this.matdialog.open(DialogComponent, {
                                data: WarningDialog,
                              });
                            } else {
                              this.matdialog.closeAll();
                              window.location.reload();
                            }
                          },
                          (err) => {
                            const WarningDialog = {
                              title: 'ERROR !',
                              body:
                                err['error']['error'] +
                                ` kindly refresh the page.`,
                              cancelBtn: 'Dismiss',
                            };
                            this.matdialog.open(DialogComponent, {
                              data: WarningDialog,
                            });
                          }
                        );
                    });
                }
              });
            } else if (num.num_rooms > 1) {
              const dialogBody = {
                title: 'DELETE',
                body: `This reservation involves multiple rooms.
            If you wish to cancel the entire reservation, please go to Reservations section and cancel the entire 
            reservation. Are you sure you want to delete the current room only ?`,
                confirmBtn: 'Yes',
                cancelBtn: 'No',
                width: '500px',
                height: '215px',
              };
              const dialogRef = this.matdialog.open(DialogComponent, {
                data: dialogBody,
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result === 'true') {
                  const body = {
                    checkin,
                    checkout,
                    roomNumId: room_num_id,
                    user: this.currentUser,
                    bookingId: this.guest['bookingId'],
                  };
                  this.userService
                    .deleteRoomAllocation(this.allocId, this.hotelID, body)
                    .subscribe(
                      (success) => {
                        this.matdialog.closeAll();
                        window.location.reload();
                      },
                      (err) => {
                        const WarningDialog = {
                          title: 'ERROR !',
                          body:
                            err['error']['error'] + ` kindly refresh the page.`,
                          cancelBtn: 'Dismiss',
                        };
                        this.matdialog.open(DialogComponent, {
                          data: WarningDialog,
                        });
                      }
                    );
                }
              });
            }
          },
          (err) => {
            const WarningDialog = {
              title: 'ERROR !',
              body: err['error']['error'] + ` kindly refresh the page.`,
              cancelBtn: 'Dismiss',
            };
            this.matdialog.open(DialogComponent, { data: WarningDialog });
          }
        );
    }
  }
  cleardata() {
    this.ngOnInit();
    this.guest.name = null;
    this.guest.email = null;
    this.guest.phone = null;
    this.guest.idproof = null;
    this.fileselected = [];
    this.guest.dob = '';
    this.guest.gender = null;
    this.guest.city = null;
    this.guest.inputAddress = null;
    this.guest.state = null;
    this.guest.country = null;
    this.guest.zipcode = null;
    this.guest.special_request = null;
    this.guest.id_detail = null;
  }

  getGrc() {
    this.printDisabled = true;
    this.userService.getGrc(this.hotelID, this.allocId).subscribe((data) => {
      let blob: any = new Blob([data], { type: 'text/pdf; charset=utf-8' });
      let fileName = `grc-${this.guest.name}.pdf`;
      saveAs(blob, fileName);
    });

    setTimeout(() => {
      this.printDisabled = false;
    }, 5000);
  }

  sendVoucher(emailId) {
    this.matdialog.open(SendVoucherEmailComponent, {
      data: {
        roomAllocationId: this.allocId,
        emailId: emailId,
        user: this.currentUser,
        hotelId: this.hotelID,
        bookingId: this.guest['bookingId'],
        source: this.guest['source'],
        groups: false
      },
      width: '600px',
      height: '250px',
    });
  }

  ngOnDestroy() {
    this.hotelRoute.unsubscribe();
  }
}
