<div class="p-2">
  <h2 mat-dialog-title>{{formHeading}}</h2>
  <mat-divider></mat-divider>

  <div class="body">
    <form [formGroup]="issueForm">
      <div class="d-flex flex-column gap-2">
        <label> {{'maintenance.heading' | translate}} <span class="text-danger">*</span> </label>
        <input type="text" class="form-control form-control-sm" maxlength="64" formControlName="heading">
      </div>

      <div class="d-flex flex-column gap-2">
        <label> {{'maintenance.area' | translate}} <span class="text-danger">*</span></label>
        <select class="form-control form-control-sm"  
          formControlName="area">
          <option *ngFor="let area of data?.config?.areas" [value]="area">
            {{area}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column gap-2">
        <label> {{'maintenance.type_of_operation' | translate}} <span class="text-danger">*</span></label>
        <select name="Type"  class="form-control form-control-sm"
          formControlName="typeOfOperation">
          <option *ngFor="let type of data?.config?.typeOfOperations" [value]="type">
            {{type}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column gap-2">
        <label>
          {{'maintenance.room_no' | translate}}.
          <span [hidden]="!issueForm.controls.roomNo.validator?.name" class="text-danger">*</span> 
        </label>
        <ng-select class="form-control form-control-sm" formControlName="roomNo">
          <ng-option 
            *ngFor="let room of data?.roomNumbers" 
            [value]="room.roomNumId">
            {{room.roomNum}}
          </ng-option>
       </ng-select>

        <!-- <select class="form-control form-control-sm"  
          formControlName="roomNo">
          <option value="101">101</option>
        </select> -->
      </div>

      <div class="d-flex flex-column gap-2">
        <label>{{'maintenance.status' | translate}} <span class="text-danger">*</span></label>
        <select name="Status"  class="form-control form-control-sm"
          formControlName="status">
          <option *ngFor="let status of data?.config?.statuses" [value]="status">
            {{status}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column gap-2">
        <label>
          {{'maintenance.common_area' | translate}}
          <span [hidden]="!issueForm.controls.commonArea.validator?.name" class="text-danger">*</span> 
        </label>
        <input type="text" class="form-control form-control-sm" maxlength="64"
          formControlName="commonArea">
      </div>

      <div class="d-flex flex-column gap-2">
        <label>{{'maintenance.priority' | translate}} <span class="text-danger">*</span></label>
        <select class="form-control form-control-sm" name="Priority" 
          formControlName="priority">
          <option value="Low">{{'maintenance.low' | translate}}</option>
          <option value="Medium">{{'maintenance.medium' | translate}}</option>
          <option value="High">{{'maintenance.high' | translate}}</option>
        </select>
      </div>

      <div class="d-flex flex-column gap-2">
        <label> {{'maintenance.assigned_to' | translate}} </label>
        <input type="text" class="form-control form-control-sm"   maxlength="64"
        formControlName="assignedTo">
      </div>

      <div class="d-flex flex-column gap-2 grid-full">
        <label> {{'maintenance.comment' | translate}} </label>
        <input type="text" class="form-control form-control-sm" maxlength="64"
        formControlName="description">
      </div>

      <div class="d-flex flex-column gap-2 grid-full">
        <label> Image </label>
        <div class="d-flex gap-2 align-items-center">
          <input 
            type="file"
            accept="image/*"
            class="form-control form-control"
            (change)="fileChange($event.target)">

          <img *ngIf="issueForm.controls.imgUrl.value" 
            [src]="issueForm.controls.imgUrl.value" width="50" 
            (click)="openTab(issueForm.controls.imgUrl.value)"
          >
        </div>
      </div>

      <ng-container *ngIf="data?.mode === 'issue-update'">
        <div class="d-flex flex-column gap-2">
          <label>
            {{'maintenance.comment' | translate}}
          </label>
          <input type="text" class="form-control form-control-sm" maxlength="64"
            formControlName="comment">
        </div>
  
        <div class="d-flex flex-column gap-2">
          <label>
            {{'maintenance.comment_by' | translate}}
          </label>
          <input type="text" class="form-control form-control-sm" maxlength="64"
            formControlName="commentBy">
        </div>
  
      </ng-container>
      
      <div class="d-flex justify-content-end gap-1r grid-full">
        <button (click)="submitClick()"
          type="button"
          mat-stroked-button class="px-3 btn-sm pr-border"
          [disabled]="!issueForm.valid || loading"
          >
          {{'maintenance.submit' | translate}}
          <span [hidden]="!loading" class="spinner-border spinner-border-sm"></span>
        </button>
        <button (click)="closeDialog()"
          type="button"
          mat-dialog-close
          mat-stroked-button class="px-3 btn-sm border border-danger">
          {{'maintenance.cancel' | translate}}
        </button>
      </div>

    </form>
  </div>
</div>
