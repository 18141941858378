<div>
    <div class="heading">
        <h2>{{'reports.title' | translate}}</h2>
    </div>
    <mat-divider></mat-divider>
    <div class="container-fluid">
        <div class="row pt-1 pb-2 bg-white">
            <div class="col-sm-auto">
                <label for="posname">{{'reports.select_report' | translate}}</label>
                <select class="form-control" [(ngModel)] ='reportName' (change)="onSelect(reportName);">
                    <optgroup *ngFor ='let grp of dataSource' label="{{grp.group}}">
                        <option *ngFor = 'let item of grp.items' [value]="item.name" [hidden]="!item.status" >{{item.name}}</option> 
                    </optgroup>
                </select>
            </div>
            <div class="col-sm-auto" *ngIf="groupSelect">
                <label for="chainName">{{'reports.select_chain' | translate}}: </label>
                <select class="form-control" id="posname" 
                    (change)="selectChain=($event.target.value); onSelect(reportName)">
                    <option *ngFor="let chain of hotelChains" [value]=chain>
                        {{chain}}
                    </option>
                </select>
            </div>
            <div class="col-sm-auto" *ngIf="reportName == 'Group Payments Report' || reportName == 'Group Expense Report' || reportName == 'Group Reconciliation Report' || reportName == 'Payments Report' ">
                <label for="chainName">Select Payment Type: </label>
                <select class="form-control" id="posname" 
                    (change)="selectPaymentType=($event.target.value);">
                    <option *ngFor="let paymentType of paymentTypes" [value]=paymentType>
                        {{paymentType}}
                    </option>
                </select>
            </div>
            <div class="col-sm-auto" *ngIf="reportName == 'Payments Report' ">
                <label for="chainName">Select User: </label>
                <select class="form-control" id="username" 
                    (change)="selectUser=($event.target.value);">
                    <option *ngFor="let user of users" [value]=user>
                        {{user}}
                    </option>
                </select>
            </div>
            <div></div>
            <div class="col-sm-auto form-group" *ngIf="reportName == 'Room Status Report' " >
                <label style="margin-bottom: 4px;" >Select Meal Plan: </label>
                <mat-form-field appearance="outline" style="font-size: 10px;">
                    <mat-select placeholder="Select" name="MealPlan" [(ngModel)]="selectMealPlan" style="font-size: 14px;" multiple #selectionModel="ngModel">
                      <mat-option *ngFor="let mealplan of mealplanList" [value]="mealplan"> {{mealplan}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div  class="col-sm-auto" *ngIf="reportName == 'Room Status Report' ">
                <label for="mealplan">Select Status: </label>
                <select class="form-control" id="status" 
                    (change)="selectStatus=($event.target.value);">
                    <option *ngFor="let status of statusList" [value]=status>
                        {{status}}
                    </option>
                </select>
            </div>

            <div class="col-sm-auto" *ngIf="reportName == 'Group Laundry Report'">
                <label for="chainName">Select Option: </label>
                <select class="form-control" id="posname"
                    (change)="selectOptionsType=($event.target.value);">
                    <option value="Sent">Sent</option>
                    <option value="Received">Received</option>
                    <option value="Balance">Balance</option>
                    <option value="Total">Total</option>
                </select>
            </div>

            <ng-container *ngIf="reportName !== 'Monthly Performance Report' && reportName !== 'Monthly P&L' && reportName !== 'Group P&L' ">
                <div class="col-sm-auto" *ngIf="!singleDateSelect">
                    <label for="From Date">{{'reports.from_date' | translate}}:</label>
                    <input type="date" class="form-control" id="from-date" max="{{toDate}}" [(ngModel)]="fromDate">
                </div>
                <div class="col-sm-auto" *ngIf="!singleDateSelect">
                    <label for="To Date">{{'reports.to_date' | translate}}:</label>
                    <input type="date" class="form-control" id="to-date" min="{{fromDate}}" [(ngModel)]="toDate">
                </div>
                <div class="col-sm-auto">
                    <br>
                    <button *ngIf="reportName == 'Item Consumption Report' && !summaryShow"mat-stroked-button class="active small-btn" (click)="showSummary(summaryShow)">{{'reports.summary' | translate}}</button>
                    <button *ngIf="reportName == 'Item Consumption Report' && summaryShow"mat-stroked-button class="active small-btn" (click)="showSummary(summaryShow)">{{'reports.list_btn' | translate}}</button>
                </div>
                <div class="col-sm-auto" *ngIf="singleDateSelect">
                    <label for="To Date">{{'reports.select_date' | translate}}:</label>
                    <input type="date" class="form-control" id="from-date" [(ngModel)]="fromDate">
                </div>
            </ng-container>
            <ng-container *ngIf="reportName === 'Monthly Performance Report' || reportName === 'Monthly P&L' ">
                <div class="col-sm-auto">
                    <label for="From Date">{{'reports.from_month' | translate}}:</label>
                    <input type="month" class="form-control" id="from-date" max="{{toMonth}}" [(ngModel)]="fromMonth">
                </div>
                <div class="col-sm-auto">
                    <label for="From Date">{{'reports.to_month' | translate}}:</label>
                    <input type="month" class="form-control" id="from-date" min="{{fromMonth}}" max="{{maxMonth}}" [(ngModel)]="toMonth">
                </div>
            </ng-container>
            <ng-container *ngIf="reportName === 'Group P&L' ">
                <div class="col-sm-auto">
                    <label for="From Date">Select Month:</label>
                    <input type="month" class="form-control" id="from-date" max="{{toMonth}}" [(ngModel)]="fromMonth">
                </div>
            </ng-container>
            <div class="col-sm-auto">
                <br>
                <button *ngIf="!companyDetails" class="btn btn-info" (click)="onReportsChange();">{{'reports.submit' | translate}}</button>
                <button *ngIf="reportName == 'Cancelled Reports' " class="btn btn-info btn-sm" [disabled]="reportTabName == 'Deleted Reservations' " (click)="reportTab('Deleted Reservations');">Deleted Reservations</button>
                <!-- <button *ngIf="reportName == 'Cancelled Reports' " class="btn btn-info btn-sm" [disabled]="reportTabName == 'Deleted Services' " (click)="reportTab('Deleted Services');">Deleted Services</button> -->
                <button *ngIf="reportName == 'Cancelled Reports' " class="btn btn-info btn-sm" [disabled]="reportTabName == 'Skipped Invoice Details' " (click)="reportTab('Skipped Invoice Details');">Skipped Invoice Details</button>
            </div>
            <div class="col-sm-auto ml-auto"></div>
            <div>
                <br>
                <button  *ngIf="reportName == 'Night Audit Report' "  mat-stroked-button class="active small-btn" (click)="refreshNightAudit()">Refresh <mat-icon>refresh</mat-icon> </button>
                <button *ngIf="reportName == 'Night Audit Report' && pmsConfiguration.tallyInterface" mat-stroked-button class="active small-btn pull-left" (click)="downloadFile('tally')">{{'reports.download_tally_report_btn' | translate}} <mat-icon>download</mat-icon></button>
                <button  *ngIf="excelDownloadList.includes(reportName)"  mat-stroked-button class="active small-btn" (click)="downloadFile('excel')">{{'reports.download_excel' | translate}} <mat-icon>download </mat-icon> </button>
                <button  *ngIf="pdfDownloadReports.includes(reportName)"  mat-stroked-button class="active small-btn" (click)="downloadFile('pdf')">Download <mat-icon>picture_as_pdf</mat-icon> </button>
                <button *ngIf="reportName == 'Group Laundry Report'"  mat-stroked-button class="active small-btn" (click)="downloadFile('excel')">{{'reports.download_excel' | translate}} <mat-icon>download </mat-icon> </button>
            </div>
        </div>
        
        <div class="content" *ngIf="reportName == 'Night Audit Report' " >
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3><b>{{'reports.daily_summary' | translate}} </b></h3>
                </div>
                <div>
                    <table class=" table-bordered" style="width: 60%">
                        <thead class="thead-dark">
                            <tr height="20px">
                                <th></th>
                                <th nowrap=""> {{ reportData.day }} </th>
                                <th nowrap=""> {{ reportData.mon }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="border: 0px solid #e7e7e7;">
                                <td nowarap="">{{'reports.no_of_rooms_occupied' | translate}} </td>
                                <td nowarap=""> {{reportData.dayOcc}} </td>
                                <td nowarap=""> {{reportData.monOcc}} </td>
                            </tr>
                            <tr>
                                <td nowarap="">{{'reports.occupancy' | translate}} </td>
                                <td nowarap=""> {{reportData.dayOccPer}} % </td>
                                <td nowarap=""> {{reportData.monOccPer}} % </td>
                            </tr>
                            <tr>
                                <td nowarap=""> {{'reports.arr' | translate}} ({{currencyCode}}) </td>
                                <td nowarap=""> {{reportData.dayArr}} </td>
                                <td nowarap=""> {{reportData.monArr}} </td>
                            </tr>
                            <tr>
                                <td nowarap=""> <b>{{'reports.total_sales' | translate}} ({{currencyCode}})</b> </td>
                                <td nowarap=""> {{reportData.dayTotal}} </td>
                                <td nowarap=""> {{reportData.monTotal}} </td>
                            </tr>
                            <ng-container *ngFor="let pos of reportData.posList">
                                <tr>
                                    <td nowrap=""> <b> {{pos.name}} </b> </td>
                                    <td nowrap=""> {{pos.dayValueTotal}} </td>
                                    <td nowrap=""> {{pos.monValueTotal}} </td>
                                </tr>
                                <tr *ngFor="let posData of pos.data">
                                    <td nowrap="" style="padding-left: 30px;">{{posData.name}} </td>
                                    <td nowrap="" style="padding-left: 20px;"> {{posData.dayValue}} </td>
                                    <td nowrap="" style="padding-left: 20px;"> {{posData.monValue}} </td>
                                </tr>
                            </ng-container>
                            <br>
                            <ng-container *ngFor="let segment of reportData.segmentList">
                                <tr style="background-color:  #bfbfbf">
                                    <td nowrap="" > <b> {{segment.name}} </b> </td>
                                    <td nowrap=""> <b> {{segment.dayValueTotal}} </b> </td>
                                    <td nowrap=""> <b> {{segment.monValueTotal}} </b> </td>
                                </tr>
                                <tr *ngFor="let segmentData of segment.data">
                                    <td nowrap="" style="padding-left: 30px;">{{segmentData.name}} </td>
                                    <td nowrap="" style="padding-left: 20px;"> {{segmentData.dayValue}} </td>
                                    <td nowrap="" style="padding-left: 20px;"> {{segmentData.monValue}} </td>
                                </tr>
                            </ng-container>
                            <tr class="font-weight-bold" *ngIf="reportData.dayMgtBlockCountStatus" style="background-color:#bfbfbf">
                                <td nowarap=""> {{'reports.management_block' | translate}} </td>
                                <td nowarap=""> {{reportData.dayMgmtBlock}} </td>
                                <td nowarap=""> {{reportData.monMgmtBlock}} </td>
                            </tr>
                            <tr class="font-weight-bold" *ngIf="reportData.dayOutCountStatus" style="background-color:#bfbfbf">
                                <td nowarap=""> {{'reports.out_of_order' | translate}}  </td>
                                <td nowarap=""> {{reportData.dayOutOfOrder}} </td>
                                <td nowarap=""> {{reportData.monOutOfOrder}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>
            <div *ngIf="reportData.nightAuditEventStatus" class="mid-section  bg-white">
                <div class="head-row">
                    <h3><b>{{'reports.night_Audit_events' | translate}}</b></h3>
                </div>
                <div>
                    <table *ngIf="reportData.nightAuditEventStatus" class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.checkin' | translate}} </th>
                                <th> {{'reports.checkout' | translate}} </th>
                                <th> {{'reports.nights' | translate}} </th>
                                <th nowrap=""> {{'reports.source' | translate}} </th>
                                <th> {{'reports.amount' | translate}} </th>
                                <th> {{'reports.tax' | translate}} ({{currencyCode}}) &emsp;</th>
                                <th> {{'reports.total_amount' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.marked_by' | translate}} </th>
                            </tr>
                        </thead>
                        <b *ngIf="reportData.autoPaymentDataStatus" nowrap=""> {{'reports.automatic_payments_added' | translate}} :</b>
                        <tr></tr>
                        <tbody *ngIf="reportData.autoPaymentDataStatus">
                            <tr *ngFor="let autoPay of reportData.autoPaymentData">
                                <td> {{autoPay.name}} </td>
                                <td> {{autoPay.checkin}} </td>
                                <td> {{autoPay.checkout}} </td>
                                <td> {{autoPay.nights}} </td>
                                <td nowrap=""> {{autoPay.source}} </td>
                                <td nowrap=""> {{autoPay.amt}} </td>
                                <td nowrap=""> {{autoPay.tax}} </td>
                                <td nowrap=""> {{autoPay.totalAmt}} </td>
                                <td> {{autoPay.markedBy}} </td>
                            </tr>
                        </tbody>
                        <b *ngIf="reportData.autoCheckoutDataStatus" nowrap=""> {{'reports.automatic_checkout' | translate}}  :</b>
                        <tbody *ngIf="reportData.autoCheckoutDataStatus">
                            <tr *ngFor="let autoCheckout of reportData.autoCheckoutData">
                                <td> {{autoCheckout.name}} </td>
                                <td> {{autoCheckout.checkin}} </td>
                                <td> {{autoCheckout.checkout}} </td>
                                <td> {{autoCheckout.nights}} </td>
                                <td nowrap=""> {{autoCheckout.source}} </td>
                                <td nowrap=""> {{autoCheckout.amt}} </td>
                                <td nowrap=""> {{autoCheckout.tax}} </td>
                                <td nowrap=""> {{autoCheckout.totalAmt}} </td>
                                <td> {{autoCheckout.markedBy}} </td>
                            </tr>
                        </tbody>
                        <b *ngIf="reportData.markNoShowBookingsStatus" nowrap=""> {{'reports.no_shows' | translate}} :</b>
                        <tbody *ngIf="reportData.markNoShowBookingsStatus">
                            <tr *ngFor="let noShow of reportData.markNoShowBookings">
                                <td> {{ noShow.name }} </td>
                                <td> {{noShow.checkin}} </td>
                                <td> {{noShow.checkout}} </td>
                                <td> {{noShow.nights}} </td>
                                <td nowrap=""> {{noShow.source}} </td>
                                <td nowrap=""> {{noShow.amt}} </td>
                                <td nowrap=""> {{noShow.tax}} </td>
                                <td nowrap=""> {{noShow.totalAmt}} </td>
                                <td> {{noShow.markedBy}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>

            <div class="content" *ngIf="reportData.allocationData && reportData.allocationData.length > 0">
                <div class="mid-section mt-4 bg-white">
                    <div class="head-row">
                        <h3><b>{{'reports.checked_out' | translate}}</b></h3>
                    </div>
                    <div class="">
                        <table class="table-bordered" width="100%">
                            <thead class="thead-dark">
                                <tr>
                                    <th> {{'reports.guest_name' | translate}} </th>
                                    <th> {{'reports.room_no' | translate}} </th>
                                    <th> {{'reports.source' | translate}} </th>
                                    <th> {{'reports.checkin' | translate}} </th>
                                    <th> {{'reports.checkout' | translate}} </th>
                                    <th> {{'reports.no_of_guests' | translate}} </th>
                                    <th> {{'reports.status' | translate}} </th>
                                    <th> {{'reports.total' | translate}} ({{currencyCode}}) &emsp;</th>
                                    <th nowrap="">{{'reports.folio_no' | translate}} </th>
                                    <th nowrap="" >{{'reports.invoice_no' | translate}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let allocation of reportData.allocationData">
                                    <td> {{ allocation.guestName }} </td>
                                    <td> {{ allocation.roomNo }} </td>
                                    <td> {{allocation.source}} </td>
                                    <td nowrap=""> {{allocation.checkInTime}} </td>
                                    <td nowrap=""> {{allocation.checkOutTime}} </td>
                                    <td> {{allocation.pax}} </td>
                                    <td> {{allocation.status}} </td>

                                    <td nowrap=""> {{allocation.total}} </td>
                                    <td nowrap="">
                                        <!-- <button (click)="getInvoice(allocation.roomAllocationId)" *ngIf="allocation.folioNo"> {{allocation.folioNo}} </button> -->
                                        <a href= {{allocation.url}} target="_blank"> {{allocation.folioNo}}</a>
                                    </td>
                                    <td nowrap="">
                                        <button (click)="getInvoice(allocation.roomAllocationId)" *ngIf="allocation.invoiceNo"> {{allocation.invoiceNo}} </button>
                                        <!-- <a href= {{allocation.url}} target="_blank"> {{allocation.invoiceNo}}</a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br>

            <div class="mid-section  bg-white" *ngIf="reportData.roomServices">
                <div class="head-row">
                    <h3><b>{{'reports.revenue_details' | translate}}</b></h3>
                </div>
                <div class="">
                    <table role="grid" width="auto" class="table-responsive table-bordered">
                        <b>{{'reports.rooms' | translate}}:</b>
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th nowrap=""> {{'reports.checkin' | translate}} </th>
                                <th nowrap=""> {{'reports.checkout' | translate}} </th>
                                <th> {{'reports.source' | translate}} </th>
                                <th> {{'reports.amount' | translate}} </th>
                                <th> {{'reports.tax' | translate}} ({{currencyCode}}) &emsp;</th>
                                <th> {{'reports.total' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.balance' | translate}} ({{currencyCode}}) </th>
                                <th> {{'reports.status' | translate}} </th>
                                <th nowrap=""> {{'reports.invoice_no' | translate}} </th>
                                <th nowrap=""> {{'reports.folio_no' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let room of reportData.roomServices[0].data">
                                <td> {{ room.roomNo }} </td>
                                <td> {{room.guestName}} </td>
                                <td nowrap=""> {{room.checkin}} </td>
                                <td nowrap=""> {{room.checkout}} </td>
                                <td> {{room.source}} </td>
                                <td> {{room.amount}} </td>
                                <td> {{room.tax}} </td>
                                <td> {{room.totalAmount}} </td>
                                <td> {{room.balance}} </td>
                                <td> {{room.status}} </td>
                                <td nowrap="">
                                    <button (click)="getInvoice(room.roomAllocationId)" *ngIf="room.invoiceNo"> {{room.invoiceNo}} </button>
                                    <!-- <a href= {{room.invoiceUrl}} target="_blank"> {{room.invoiceNo}}</a> -->
                                </td>
                                <td nowrap="">
                                    <!-- <button (click)="getInvoice(room.roomAllocationId)" *ngIf="room.invoiceNo"> {{room.invoiceNo}} </button> -->
                                    <a href= {{room.invoiceUrl}} target="_blank"> {{room.folioNo}}</a>
                                </td>
                            </tr>
                            <tr *ngFor="let roomStay of reportData.roomServices[0].dataStayed">
                                <td nowrap=""> {{roomStay.roomNo}}  </td>
                                <td> {{roomStay.guestName}}  </td>
                                <td nowrap=""> {{roomStay.checkin}}  </td>
                                <td nowrap=""> {{roomStay.checkout}}  </td>
                                <td nowrap=""> {{roomStay.source}}  </td>
                                <td nowrap=""> {{roomStay.amount}}  </td>
                                <td nowrap=""> {{roomStay.tax}}  </td>
                                <td nowrap=""> {{roomStay.totalAmount}}  </td>
                                <td nowrap=""> {{roomStay.balance}}  </td>
                                <td nowrap=""> {{roomStay.status}}  </td>
                                <td nowrap="">
                                    <button (click)="getInvoice(roomStay.roomAllocationId)" *ngIf="roomStay.invoiceNo"> {{roomStay.invoiceNo}} </button>
                                    <!-- <a href= {{roomStay.invoiceUrl}} target="_blank">{{roomStay.invoiceNo}}</a> -->
                                </td>
                                <td nowrap="">
                                    <!-- <button (click)="getInvoice(roomStay.roomAllocationId)" *ngIf="roomStay.folioNo"> {{roomStay.folioNo}} </button> -->
                                    <a href= {{roomStay.invoiceUrl}} target="_blank">{{roomStay.folioNo}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td nowrap=""><b> {{reportData.roomServices[0].totalAmount}} </b></td>
                                <td nowrap=""><b> {{reportData.roomServices[0].taxTotal}} </b></td>
                                <td nowrap=""><b> {{reportData.roomServices[0].totalPostTax}} </b></td>
                                <td colspan="4"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="" *ngIf="reportData.otherServicesListStatus">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.service_details' | translate}} </th>
                                <th> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                                <th> {{'reports.tax' | translate}}  ({{currencyCode}})</th>
                                <th> {{'reports.total' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.invoice_no' | translate}} ({{currencyCode}}) &emsp;</th>
                                <th> {{'reports.folio_no' | translate}} ({{currencyCode}})</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let other of reportData.otherServicesList">
                                <tr>
                                    <td colspan="8"><b>{{other.name}}</b></td>
                                </tr>
                                <tr *ngFor="let otherSer of other.data">
                                    <td> {{ otherSer.roomNo }} </td>
                                    <td> {{otherSer.guestName}} </td>
                                    <td> {{otherSer.serviceName}} </td>
                                    <td> {{otherSer.amount}} </td>
                                    <td nowrap=""> {{otherSer.tax}} </td>
                                    <td nowrap=""> {{otherSer.totalAmount}} </td>
                                    <td nowrap="">
                                        <button (click)="getInvoice(otherSer.roomAllocationId)" *ngIf="otherSer.invoiceNo"> {{otherSer.invoiceNo}} </button>
                                        <!-- <a href= {{otherSer.invoiceUrl}} target="_blank"> {{otherSer.invoiceNo}}</a> -->
                                    </td>
                                    <td nowrap="">
                                        <!-- <button (click)="getInvoice(otherSer.roomAllocationId)" *ngIf="otherSer.folioNo"> {{otherSer.folioNo}} </button> -->
                                        <a href= {{otherSer.invoiceUrl}} target="_blank"> {{otherSer.folioNo}}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                    <td nowrap=""><b> {{other.totalAmount}} </b></td>
                                    <td nowrap=""><b> {{other.taxTotal}} </b></td>
                                    <td nowrap=""><b> {{other.totalPostTax}} </b></td>
                                    <td colspan="2"></td>
                                </tr>
                            </ng-container>

                        </tbody>
                    </table>
                </div>
            </div>
            <br>


            <div class="mid-section  bg-white" *ngIf="reportData.taxGroupListStatus">
                <div class="head-row">
                    <h3><b>{{'reports.tax_summary' | translate}}</b></h3>
                </div>
                <div class="">
                    <table class="table-bordered" width="60%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.name' | translate}}</th>
                                <th> {{'reports.description' | translate}} </th>
                                <th> {{'reports.pretax' | translate}} ({{currencyCode}}) &emsp;</th>
                                <th> {{'reports.amount' | translate}} ({{currencyCode}})</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let taxGroup of reportData.taxGroupList">
                                <tr>
                                    <td> <b>{{ taxGroup.groupName }}</b> </td>
                                    <td> <b>{{taxGroup.desc}}</b> </td>
                                    <td> <b>{{taxGroup.groupTotalPreTax}}</b> </td>
                                    <td> <b>{{taxGroup.groupTotal}}</b> </td>
                                </tr>
                                <tr *ngFor="let tax of taxGroup.taxList">
                                    <td style="padding-left: 30px;"> {{ tax.name }} </td>
                                    <td> {{tax.desc}} </td>
                                    <td>  </td>
                                    <td> {{tax.total}} </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>


            <div class="mid-section  bg-white" *ngIf="reportData.dayPaymentsListStatus">
                <div class="head-row">
                    <h3><b>{{'reports.total_receipts' | translate}}</b></h3>
                </div>
                <div class="">
                    <table class="table-bordered" style="width: 60%">
                        <b nowrap=""> {{'reports.receipts_summary' | translate}} :</b>
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.payment_type' | translate}} </th>
                                <th> {{'reports.value' | translate}} ({{currencyCode}}) &emsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of reportData.paymentsSummary">
                                <td> {{ payment.name }} </td>
                                <td> {{ payment.amt}} </td>
                            </tr>
                            <tr>
                                <td> <b>{{'reports.total' | translate}}</b> </td>
                                <td> <b>{{ reportData.totalReceiptsPayment}}</b> </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>

                    <table class="table-bordered" width="100%">
                        <b nowrap=""> {{'reports.receipts' | translate}} :</b>
                        <thead class="thead-dark">
                            <tr>
                                <th nowrap=""> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.invoice_number' | translate}} </th>
                                <th> {{'reports.folio_number' | translate}} </th>
                                <th> {{'reports.invoice_amount' | translate}}({{currencyCode}}) </th>
                                <th> {{'reports.payment_amount' | translate}}({{currencyCode}})</th>
                                <th> {{'reports.balance' | translate}} ({{currencyCode}}) &emsp;</th>
                                <th> {{'reports.mode_of_payment' | translate}} </th>
                                <th> {{'reports.time' | translate}} </th>
                                <th> {{'reports.comments' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payee of reportData.dayPaymentsList">
                                <td> {{ payee.roomNo }} </td>
                                <td> {{payee.guestName}} </td>
                                <td nowrap="">
                                    <button (click)="getInvoice(payee.roomAllocationId)" *ngIf="payee.invoiceNo"> {{payee.invoiceNo}} </button>
                                    <!-- <a href= {{payee.invoiceUrl}} target="_blank"> {{payee.invoiceNo}}</a> -->
                                </td>
                                <td nowrap="">
                                    <!-- <button (click)="getInvoice(payee.roomAllocationId)" *ngIf="payee.folioNo"> {{payee.folioNo}} </button> -->
                                    <a href= {{payee.invoiceUrl}} target="_blank"> {{payee.folioNo}}</a>
                                </td>
                                <td> {{payee.invAmount}} </td>
                                <td> {{payee.payAmount}} </td>
                                <td> {{payee.balance}} </td>
                                <td nowrap=""> {{payee.typeOfPayment}} </td>
                                <td> {{payee.time}} </td>
                                <td> {{payee.comments}} </td>
                            </tr>
                            <tr>
                                <td colspan="4" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td nowrap=""><b> {{reportData.totalReceiptsInvoice}} </b></td>
                                <td nowrap=""><b> {{reportData.totalReceiptsPayment}} </b></td>
                                <td nowrap=""><b> {{reportData.totalReceiptsBalance}} </b></td>
                                <td colspan="3"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>
        </div>


        <div class="content" *ngIf="reportName == 'Arrival Report' || reportName == 'Departure Report' || reportName == 'Checkout based Accounting Report'" >
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.allocationData?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.allocationData?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} !!!</b></h3>
                </div>
                <div class="" *ngIf="reportData && reportData.allocationData?.length > 0" class="table-bordered table-responsive">
                    <table role="grid" width="100%" *ngIf="reportName == 'Arrival Report' || reportName == 'Departure Report'">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.sn' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.source' | translate}} </th>
                                <th nowrap=""> {{'reports.room_no' | translate}} </th>
                                <th nowrap=""> {{'reports.checkin' | translate}} </th>
                                <th nowrap=""> {{'reports.checkout' | translate}} </th>
                                <th> {{'reports.pax' | translate}} </th>
                                <th> {{'reports.status' | translate}} </th>
                                <th *ngIf="reportName == 'Arrival Report'"> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                                <th *ngIf="reportName == 'Arrival Report'"> {{'reports.tax' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.total' | translate}} ({{currencyCode}}) &emsp;</th>
                                <th nowrap=""> Payments ({{currencyCode}})</th>
                                <th *ngIf="reportName == 'Departure Report'"> {{'reports.balance' | translate}} ({{currencyCode}})</th>
                                <th nowrap="">{{'reports.folio_no' | translate}} </th>
                                <th nowrap="" *ngIf="reportName == 'Departure Report'">{{'reports.invoice_no' | translate}} </th>
                                <th> {{'reports.mobile' | translate}} </th>
                                <th nowrap=""> {{'reports.email' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let allocationData of reportData.allocationData; index as i">
                                <td> {{i+1}}. </td>
                                <td> {{ allocationData.guestName }} </td>
                                <td> {{ allocationData.source }} </td>
                                <td nowrap=""> {{ allocationData.roomNo }} </td>
                                <td nowrap=""> {{ allocationData.checkIn }} </td>
                                <td nowrap=""> {{ allocationData.checkOut }} </td>
                                <td> {{ allocationData.pax }} </td>
                                <td nowrap="" > {{allocationData.status}} </td>
                                <td nowrap="" *ngIf="reportName == 'Arrival Report'"> {{allocationData.amount}} </td>
                                <td nowrap="" *ngIf="reportName == 'Arrival Report'"> {{allocationData.tax}} </td>
                                <td nowrap=""> {{allocationData.total}} </td>
                                <td nowrap=""> {{ allocationData.paymentsAmount }} </td>
                                <td nowrap="" *ngIf="reportName == 'Departure Report'"> {{allocationData.balance}} </td>
                                <td nowrap=""><a href= {{allocationData.url}} target="_blank"> {{allocationData.folioNo}}</a></td>
                                <td nowrap=""  *ngIf="reportName == 'Departure Report'"> <a href= {{allocationData.url}} target="_blank"> {{allocationData.invoiceNo}}</a></td>
                                <td> {{ allocationData.mobile }} </td>
                                <td nowrap=""> {{ allocationData.email }} </td>
                            </tr>
                        </tbody>
                    </table>

                    <table role="grid" width="auto" class="table-responsive table-bordered"  *ngIf="reportName == 'Checkout based Accounting Report'" >
                        <thead class="thead-dark">
                            <tr>
                                <th nowrap="" rowspan="2"> {{'reports.guest_name' | translate}} </th>
                                <th rowspan="2"> {{'reports.pax' | translate}} </th>
                                <th nowrap="" rowspan="2" >
                                    <span (click)="sortType = 'roomNo'; sortReverse = !sortReverse; sortData('roomNo', sortReverse)"> {{'reports.room_no' | translate}} 
                                        <mat-icon class="icon-display" [hidden]="sortType == 'roomNo' && !sortReverse">arrow_downward</mat-icon>
                                        <mat-icon class="icon-display" [hidden]="sortType == 'roomNo' && sortReverse">arrow_upward</mat-icon>
                                    </span>
                                </th>
                                <th nowrap="" rowspan="2"> 
                                    <a (click)="sortType = 'checkInTime'; sortReverse = !sortReverse; sortData('checkInTime', sortReverse)"> {{'reports.checkin' | translate}}  
                                        <mat-icon class="icon-display" [hidden]="sortType == 'checkInTime' && !sortReverse">arrow_downward</mat-icon>
                                        <mat-icon class="icon-display" [hidden]="sortType == 'checkInTime' && sortReverse">arrow_upward</mat-icon>
                                    </a>
                                </th>
                                <th nowrap="" rowspan="2"> 
                                    <a (click)="sortType = 'checkOutTime'; sortReverse = !sortReverse; sortData('checkOutTime', sortReverse)"> {{'reports.checkout' | translate}} 
                                        <mat-icon class="icon-display" [hidden]="sortType == 'checkOutTime' && !sortReverse">arrow_downward</mat-icon>
                                        <mat-icon class="icon-display" [hidden]="sortType == 'checkOutTime' && sortReverse">arrow_upward</mat-icon>
                                    </a>
                                </th>
                                <th rowspan="2"> {{'reports.source' | translate}} </th>
                                <th rowspan="2" nowrap=""> {{'reports.booking_id' | translate}} </th>
                                <th nowrap="" rowspan="2"> 
                                    <a (click)="sortType = 'invoiceNo'; sortReverse = !sortReverse; sortData('invoiceNo', sortReverse)"> {{'reports.invoice_no' | translate}} 
                                        <mat-icon class="icon-display" [hidden]="sortType == 'invoiceNo' && !sortReverse">arrow_downward</mat-icon>
                                        <mat-icon class="icon-display" [hidden]="sortType == 'invoiceNo' && sortReverse">arrow_upward</mat-icon>
                                    </a>
                                </th>
                                <th rowspan="2"> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                                <th rowspan="2"> {{'reports.pretax' | translate}} ({{currencyCode}})</th>
                                <th [attr.colspan]="taxLength"> {{'reports.taxes' | translate}} ({{currencyCode}})</th>
                                <th nowrap="" rowspan="2" *ngIf="currencyCode == 'INR'"> {{'reports.tcs' | translate}} ({{currencyCode}})</th>
                                <th nowrap="" rowspan="2" *ngIf="currencyCode == 'INR'"> {{'reports.tds' | translate}} ({{currencyCode}})</th>
                                <th nowrap="" rowspan="2"> {{'reports.commission' | translate}} ({{currencyCode}})</th>
                                <th nowrap="" rowspan="2"> Bill To </th>
                                <th nowrap="" rowspan="2"> {{'reports.tax_no' | translate}} </th>
                            </tr>
                            <tr>
                                <th nowrap="" *ngFor="let taxName of reportData.taxNames"> {{taxName}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let accountData of reportData.allocationData">
                                <td nowrap=""> {{accountData.guestName}} </td>
                                <td> {{ accountData.pax }} </td>
                                <td nowrap=""> {{ accountData.roomNo }} </td>
                                <td nowrap=""> {{accountData.checkInTime}} </td>
                                <td nowrap=""> {{accountData.checkOutTime}} </td>
                                <td> {{ accountData.source }} </td>
                                <td nowrap=""> {{ accountData.bookingId }} </td> 
                                <td nowrap=""> <a href= {{accountData.url}} target="_blank"> {{accountData.invoiceNo}}</a></td>
                                <td> {{ accountData.accountingTotal }} </td>
                                <td> {{ accountData.accountingAmount }} </td>
                                <td nowrap="" *ngFor="let taxValue of accountData.accountingTax"> {{taxValue}}</td>
                                <td nowrap="" *ngIf="currencyCode == 'INR'"> {{ accountData.tcs }} </td>
                                <td nowrap="" *ngIf="currencyCode == 'INR'"> {{ accountData.tds }} </td>
                                <td> {{ accountData.commision }} </td>
                                <!-- <td> {{ accountData.coompanyName }} </td> -->
                                <td> {{ accountData.billTo }} </td>
                                <td nowrap=""> {{ accountData.taxNum }} </td>
                            </tr>
                            <tr>
                                <td colspan="1" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td colspan="1"><b> {{reportData.totalPax}} </b></td>
                                <td colspan="6"><b>  </b></td>
                                <td *ngFor="let total of reportData.totalList"> <b>{{total}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Police Enquiry Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData.length > 0"><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div class="table-responsive table-bordered" *ngIf="reportData.length > 0" id="pdfTable">
                    <table role="grid" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.mobile' | translate}} </th>
                                <th> {{'reports.email' | translate}} </th>
                                <th> Gender </th>
                                <th  nowrap=""> {{'reports.dob' | translate}} </th>
                                <th> City </th>
                                <th> {{'reports.country' | translate}} </th>
                                <th> {{'reports.address' | translate}} </th>
                                <th nowrap=""> {{'reports.checkin' | translate}} &emsp;</th>
                                <th nowrap=""> {{'reports.checkout' | translate}} &emsp;</th>
                                <th>{{'reports.id_type' | translate}} </th>
                                <th>Id Details </th>
                                <th nowrap="">{{'reports.id_link' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let stayData of reportData">
                                <td> {{ stayData.roomNo }} </td>
                                <td> {{stayData.name}} </td>
                                <td> {{stayData.mobile}} </td>
                                <td> {{stayData.email}} </td>
                                <td> {{stayData.gender}} </td>
                                <td  nowrap=""> {{stayData.dob}} </td>
                                <td> {{stayData.city}} </td>
                                <td> {{stayData.country}} </td>
                                <td> {{stayData.address}} </td>
                                <td nowrap=""> {{stayData.checkIn}} </td>
                                <td nowrap=""> {{stayData.checkOut}} </td>
                                <td> {{stayData.idType}} </td>
                                <td> {{stayData.idDetails}} </td>
                                <td *ngIf="stayData.idLink != '-' " nowrap=""> <a *ngFor="let link of stayData.idLink; index as i" href= {{link.url}} target="_blank"><img src={{link.url}}  width="20" height="20"> &emsp;</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'Management Block Report' || reportName == 'Out of Order Room Report'">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData.length > 0"><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div class="" *ngIf="reportData.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th nowrap=""> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.checkin' | translate}} </th>
                                <th> {{'reports.checkout' | translate}} </th>
                                <th *ngIf="reportName == 'Management Block Report'"> {{'reports.no_of_Guests' | translate}} </th>
                                <th *ngIf="reportName == 'Out of Order Room Report'"> {{'reports.no_of_nights' | translate}} </th>
                                <th> {{'reports.status' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let blockData of reportData">
                                <td nowrap=""> {{ blockData.roomNo }} </td>
                                <td> {{blockData.name}} </td>
                                <td> {{blockData.checkIn}} </td>
                                <td> {{blockData.checkOut}} </td>
                                <td *ngIf="reportName == 'Management Block Report'"> {{blockData.noOfGuests}} </td>
                                <td *ngIf="reportName == 'Out of Order Room Report'"> {{blockData.noOfNights}} </td>
                                <td> {{blockData.status}} </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="mid-section mt-4 bg-white" *ngIf="reportName == 'Item Consumption Report'">
            <div class="head-row">
                <h3 *ngIf="reportData.length > 0"><b>{{reportName}}</b></h3>
                <h3 *ngIf="reportData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
            </div>
            <div *ngIf="reportData.length > 0" class="table-responsive table-bordered">
                <table *ngIf="summaryShow" class="table-bordered" width="100%">
                    <thead class="thead-dark" >
                        <tr class="font-weight-bold bg-light-grey">
                            <td colspan="5"> {{'reports.summary' | translate}}</td>
                        </tr>
                        <tr>
                            <th> {{'reports.service_name' | translate}} </th>
                            <th> {{'reports.qty_consumed' | translate}} </th>
                            <th nowrap=""> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                            <th> {{'reports.tax' | translate}}  ({{currencyCode}})</th>
                            <th> {{'reports.total' | translate}} ({{currencyCode}})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let other of reportData">
                            <tr>
                                <td colspan="5"><b>{{other.name}}</b></td>
                            </tr>
                            <tr *ngFor="let otherSer of other.data1">
                                <td style="padding-left: 30px;"> {{ otherSer.serviceName }} </td>
                                <td> {{otherSer.quantity}} </td>
                                <td nowrap=""> {{otherSer.amount}} </td>
                                <td > {{otherSer.tax}} </td>
                                <td> {{otherSer.totalAmount}} </td>
                            </tr>
                            <tr>
                                <td colspan="1" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td nowrap=""><b> {{other.totalQty}} </b></td>
                                <td nowrap=""><b> {{other.totalAmount}} </b></td>
                                <td nowrap=""><b> {{other.taxTotal}} </b></td>
                                <td nowrap=""><b> {{other.totalPostTax}} </b></td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <br id="summary">
                </table>
                
                <table *ngIf="!summaryShow" class="table-bordered" width="100%" >
                    <thead class="thead-dark">
                        <tr>
                            <th> {{'reports.service_name' | translate}} </th>
                            <th> {{'reports.date' | translate}} </th>
                            <th> {{'reports.guest_name' | translate}} </th>
                            <th> {{'reports.room_no' | translate}} </th>
                            <th> {{'reports.qty_consumed' | translate}} </th>
                            <th nowrap=""> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                            <th> {{'reports.tax' | translate}}  ({{currencyCode}})</th>
                            <th> {{'reports.total' | translate}} ({{currencyCode}})</th>
                            <th> {{'reports.invoice_no' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let other of reportData">
                            <tr>
                                <td colspan="8"><b>{{other.name}}</b></td>
                            </tr>
                            <tr *ngFor="let otherSer of other.data">
                                <td style="padding-left: 30px;"> {{ otherSer.serviceName }} </td>
                                <td nowrap=""> {{otherSer.date}} </td>
                                <td nowrap=""> {{otherSer.guestName}} </td>
                                <td> {{otherSer.roomNo}} </td>
                                <td> {{otherSer.quantity}} </td>
                                <td nowrap=""> {{otherSer.amount}} </td>
                                <td > {{otherSer.tax}} </td>
                                <td> {{otherSer.totalAmount}} </td>
                                <td nowrap=""> <a href= {{otherSer.invoiceUrl}} target="_blank"> {{otherSer.invoiceNo}}</a></td>
                            </tr>
                            <tr>
                                <td colspan="5" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td nowrap=""><b> {{other.totalAmount}} </b></td>
                                <td nowrap=""><b> {{other.taxTotal}} </b></td>
                                <td nowrap=""><b> {{other.totalPostTax}} </b></td>
                                <td colspan="1"></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        

        <div class="mid-section mt-4 bg-white" *ngIf="reportName == 'Payments Report' || reportName == 'Group Payments Report'  ">
            <div class="head-row">
                <h3 *ngIf="reportData.paymentsList?.length > 0"><b>{{reportName}}</b> </h3> 
                <h3 *ngIf="reportData.grandTotal && reportData.paymentsList?.length > 0 " class="pull-left">Total : <b  class="pull-left">{{reportData.grandTotal}}</b> </h3> 
                <h3 *ngIf="reportData.paymentsList?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b> </h3>
            </div>

            <div *ngIf="reportData.paymentsList?.length > 0" class="table-responsive table-bordered">
                <table role="grid" width="100%" >
                    <thead class="thead-dark">
                        <tr>
                            <th nowrap=""> {{'reports.date' | translate}} </th>
                            <th> {{'reports.payment_amount' | translate}} ({{currencyCode}}) </th>
                            <th> {{'reports.source' | translate}} </th>
                            <th> {{'reports.guest_name' | translate}} </th>
                            <th nowrap=""> {{'reports.check_in' | translate}} </th>
                            <th nowrap=""> {{'reports.check_out' | translate}} </th>
                            <th> {{'reports.room_no' | translate}} </th>
                            <th> Booking Id </th>
                            <th nowrap=""> {{'reports.folio_no' | translate}} </th>
                            <th nowrap=""> {{'reports.invoice_no' | translate}}</th>
                            <th> {{'reports.comments' | translate}} </th>
                            <th> {{'reports.user' | translate}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let payment of reportData.paymentsList">
                            <tr>
                                <td colspan="12"><b>{{payment.name}}</b></td>
                            </tr>
                            <tr *ngFor="let paymentData of payment.data">
                                <td nowrap=""> {{paymentData.date}} </td>
                                <td> {{paymentData.payAmount}} </td>
                                <td> {{paymentData.source}} </td>
                                <td> {{paymentData.guestName}} </td>
                                <td nowrap=""> {{paymentData.checkin}} </td>
                                <td nowrap=""> {{paymentData.checkout}} </td>
                                <td> {{paymentData.roomNo}} </td>
                                <td> {{paymentData.bookingId}} </td>
                                <td nowrap=""> <a href= {{paymentData.invoiceUrl}} target="_blank"> {{paymentData.folioNo}}</a></td>
                                <td nowrap=""> <a href= {{paymentData.invoiceUrl}} target="_blank"> {{paymentData.invoiceNo}}</a></td>
                                <td> {{paymentData.comments}} </td>
                                <td> {{paymentData.changedBy}} </td>
                            </tr>
                            <tr>
                                <td colspan="1" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td colspan="9"><b> {{payment.paymentTypeTotal}} </b></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>


        <div class="mid-section mt-4 bg-white" *ngIf="reportName == 'POS Report'">
            <div class="head-row">
                <h3 *ngIf="reportData.posData && reportData.posData.length > 0"><b>{{reportName}}</b></h3>
                <h3 *ngIf="reportData.posData && reportData.posData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
            </div>

            <div class="table-responsive table-bordered" *ngIf="reportData.posData && reportData.posData.length > 0" >
                <table class="table-bordered" width="100%">
                    <thead class="thead-dark">
                        <tr>
                            <th rowspan="2"> {{'reports.date' | translate}} </th>
                            <th rowspan="2" nowrap=""> {{'reports.order_id' | translate}} </th>
                            <th rowspan="2" nowrap=""> {{'reports.invoice_no' | translate}} </th>
                            <th rowspan="2" nowrap=""> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                            <th [attr.colspan]="taxLength"> {{'reports.taxes' | translate}} ({{currencyCode}}) </th>
                            <th rowspan="2"> {{'reports.tax' | translate}} ({{currencyCode}}) </th>
                            <th rowspan="2" nowrap=""> {{'reports.total' | translate}} ({{currencyCode}}) </th>
                            <th rowspan="2" nowrap=""> {{'reports.balance' | translate}} ({{currencyCode}})</th>
                            <th *ngIf="paymentsLength" [attr.colspan]="paymentsLength"> {{'reports.payments' | translate}} ({{currencyCode}}) </th>
                            <th rowspan="2"> Company Name </th>
                        </tr>
                        <tr>
                            <th nowrap="" *ngFor="let taxName of reportData.taxNames"> {{taxName}}</th>
                            <th nowrap="" *ngFor="let PaymentName of reportData.PaymentNames"> {{PaymentName}}</th>
                        </tr>

                    </thead>
                    <tbody>
                        <ng-container *ngFor="let pos of reportData.posData">
                            <tr>
                                <td colspan="5"><b>{{pos.posName}} :</b></td>
                            </tr>
                            <tr *ngFor="let posData of pos.data">
                                <td nowrap=""> {{posData.date}} </td>
                                <td nowrap=""> {{posData.orderId}} </td>
                                <td nowrap=""> {{posData.invoiceNum}} </td>
                                <td> {{posData.amount | number : '1.2-2'}} </td>
                                <td *ngFor="let taxValue of posData.taxes"> {{taxValue}}</td>
                                <td> {{posData.tax | number : '1.2-2' }} </td>
                                <td> {{posData.totalAmount | number : '1.2-2' }} </td>
                                <td > {{posData.balance}} </td>
                                <td *ngFor="let paymentType of posData.paymentTypes"> {{paymentType}}</td>
                                <td nowrap=""> {{posData.companyName}} </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td><b> {{pos.totalAmount | number : '1.2-2' }} </b></td>
                                <td [attr.colspan]="taxLength"></td>
                                <td><b> {{pos.totalTax | number : '1.2-2' }} </b></td>
                                <td><b> {{pos.total | number : '1.2-2' }} </b></td>
                                <td><b> {{pos.totalBalance | number : '1.2-2' }} </b></td>
                                <td *ngFor="let totalPaymentValue of pos.totalPaymentsValues"> <b> {{totalPaymentValue}} </b></td>
                                <td></td>
                             </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Group Sales Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.salesData"><b>{{reportName}}</b></h3>
                    <h3 *ngIf !="reportData && reportData.salesData"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div class="" *ngIf="reportData.salesData">
                    <table class="table-bordered" width="100%">
                        <tbody>
                            <tr style="background-color:#37474f !important;color:white;text-align: center">
                                <th rowspan="2"> {{'reports.hotel_name' | translate}} </th>
                                <th colspan="4"> {{'reports.business_closed' | translate}} </th>
                                <th colspan="3"> {{'reports.pick_up' | translate}} </th>
                                <th rowspan="2" nowrap="" *ngFor="let pos of reportData.allPos"> {{pos}} ({{currencyCode}})</th>
                            </tr>
                            <tr style="background-color:#c4e6f5;">
                                <th> {{'reports.rooms_closed' | translate}} </th>
                                <th> {{'reports.occupancy' | translate}} </th>
                                <th> {{'reports.revenues' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.arr' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.rooms_nights' | translate}} </th>
                                <th> {{'reports.room_revenues' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.arr' | translate}} ({{currencyCode}})</th>
                            </tr>
                            <tr *ngFor="let sales of reportData.salesData">
                                <td> {{sales.hotelName}} </td>
                                <td> {{sales.roomsOccupied}} </td>
                                <td> {{sales.occPer}} %</td>
                                <td> {{sales.roomSales}} </td>
                                <td> {{sales.arr}} </td>
                                <td> {{sales.pickUp}} </td>
                                <td> {{sales.pickUpRev}} </td>
                                <td> {{sales.pickUpArr}} </td>
                                <td *ngFor="let posValue of sales.posList"> {{posValue}}</td>
                            </tr>
                            <tr>
                                <td style="text-align: right"><b>{{'reports.total' | translate}} :</b></td>
                                <td *ngFor="let total of reportData.totals"><b>{{total}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <table class="table-bordered" width="100%" *ngIf="reportData.otaSalesData.length > 0">
                        <tbody>
                            <tr style="background-color:#37474f !important;color:white;text-align: center">
                                <th rowspan="2"> {{'reports.channel_name' | translate}} </th>
                                <th colspan="3"> {{'reports.business_closed' | translate}} </th>
                                <th colspan="3"> {{'reports.pick_up' | translate}} </th>
                            </tr>
                            <tr style="background-color:#c4e6f5;">
                                <th> {{'reports.rooms_closed' | translate}} </th>
                                <th> {{'reports.room_revenues' | translate}}({{currencyCode}})</th>
                                <th> {{'reports.arr' | translate}} ({{currencyCode}})</th>

                                <th> {{'reports.rooms_nights' | translate}} </th>
                                <th> {{'reports.room_revenues' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.arr' | translate}} ({{currencyCode}})</th>
                            </tr>
                            <tr *ngFor="let sales of reportData.otaSalesData">
                                <td> {{sales.channel}} </td>
                                <td> {{sales.roomNights}} </td>
                                <td> {{sales.roomRev}} </td>
                                <td> {{sales.arr}} </td>
                                <td> {{sales.pickUp}} </td>
                                <td> {{sales.pickUpRev}} </td>
                                <td> {{sales.pickUpArr}} </td>
                            </tr>
                            <tr>
                                <td style="text-align: right"><b>{{'reports.total' | translate}} :</b></td>
                                <td *ngFor="let total of reportData.otaTotalData"><b>{{total}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Hotel Sales Report' " id="content">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row" >
                    <h3><b>{{'reports.hotel_sales_report' | translate}}</b></h3>
                </div>
                <div class="">
                    <table class=" table-bordered" style="width: 50%">
                        <thead class="thead-dark">
                            <tr height="20px">
                                <th></th>
                                <th nowrap="" colspan="3"> {{ reportData.mon }} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="border: 0px solid #e7e7e7;">
                                <td nowarap=""> {{'reports.room_nights_sold' | translate}} </td>
                                <td nowarap="" colspan="3"> {{reportData.monOcc}} </td>
                            </tr>
                            <tr>
                                <td nowarap=""> {{'reports.occupancy' | translate}} </td>
                                <td nowarap="" colspan="3"> {{reportData.monOccPer}} % </td>
                            </tr>
                            <tr>
                                <td nowarap=""> {{'reports.arr' | translate}} ({{currencyCode}}) </td>
                                <td nowarap="" colspan="3"> {{reportData.monArr}} </td>
                            </tr>
                            <!-- <tr>
                                <td nowarap=""> Total PAX </td>
                                <td nowarap="" colspan="3"> {{reportData.dayPax}} </td>
                            </tr> -->
                            <tr>
                                <td nowarap=""> <b>{{'reports.total_revenues' | translate}} ({{currencyCode}})</b> </td>
                                <td nowarap="" colspan="3"> {{reportData.monTotal}} </td>
                            </tr>
                            <ng-container *ngFor="let pos of reportData.posList">
                                <tr>
                                    <td nowrap=""> <b> {{pos.name}} </b> </td>
                                    <td nowrap="" colspan="3"> <b> {{pos.monValueTotal }} </b> </td>
                                </tr>
                                <tr *ngFor="let posData of pos.data">
                                    <td nowrap="" style="padding-left: 30px;">{{posData.name}} </td>
                                    <td nowrap="" colspan="3"> {{posData.monValue}} </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="reportData.mealsList?.length >0" class="font-weight-bold">   &nbsp;&nbsp;&nbsp;{{'reports.meal_plans' | translate}}: </tr>
                            <ng-container *ngFor="let meal of reportData.mealsList">
                                <tr>
                                    <td nowrap="" style="padding-left: 30px;"> {{meal.name}} </td>
                                    <td nowrap="" colspan="3"> {{meal.value}} </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="reportData.segmentList?.length >0" class="font-weight-bold"> 
                                <td> {{'reports.source_of_business' | translate}}:  </td>
                                <td> {{'reports.revenue' | translate}}  </td>
                                <td> {{'reports.rns' | translate}} </td>
                                <td> {{'reports.arr' | translate}} </td>
                            </tr>
                            <ng-container *ngFor="let segment of reportData.segmentList">
                                <tr class="font-weight-bold" style="background-color:  #bfbfbf">
                                    <td nowrap=""> {{segment.name}}  </td>
                                    <td nowrap=""> {{segment.monValueAmount}}   </td>
                                    <td> {{segment.monValueTotal}} </td>
                                    <td> {{segment.monValueArr}} </td>
                                </tr>
                                <tr *ngFor="let segmentData of segment.data">
                                    <td nowrap="" style="padding-left: 30px;">{{segmentData.name}} </td>
                                    <td nowrap=""> {{segmentData.monAmount}} </td>
                                    <td> {{segmentData.monValue}} </td>
                                    <td> {{segmentData.monArr}} </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="reportData.monMgtBlockCountStatus">
                                <td nowarap=""> &emsp;{{'reports.management_block' | translate}} </td>
                                <td nowarap="" colspan="3"> {{reportData.monMgmtBlock}} </td>
                            </tr>
                            <tr *ngIf="reportData.monOutCountStatus">
                                <td nowarap=""> &emsp;{{'reports.out_of_order' | translate}}  </td>
                                <td nowarap="" colspan="3"> {{reportData.monOutOfOrder}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'No Show Report' ">
            <div class="mid-section mt-4 bg-white" >
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData?.length > 0"><b>{{'reports.no_show_report' | translate}}</b></h3>
                    <h3 *ngIf="reportData && reportData?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData.length >0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.sn' | translate}} </th>
                                <th> {{'reports.segment' | translate}} </th>
                                <th> {{'reports.source' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}}  </th>
                                <!-- <th> Booking Date </th> -->
                                <th> {{'reports.checkin_date' | translate}} </th>
                                <th> {{'reports.checkout_date' | translate}} </th>
                                <th> {{'reports.#_room_nights' | translate}} </th>
                                <th> {{'reports.amount' | translate}} ({{currencyCode}}) &emsp;</th>
                                <th> {{'reports.marked_by' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let noShow of reportData; index as i">
                                <td> {{i+1}}. </td>
                                <td> {{noShow.segment}} </td>
                                <td> {{noShow.source}} </td>
                                <td> {{noShow.name}} </td>
                                <!-- <td> {{noShow.bookingDate}} </td> -->
                                <td> {{noShow.checkin}} </td>
                                <td> {{noShow.checkout}} </td>
                                <td> {{noShow.nights}} </td>
                                <td> {{noShow.totalAmt}} </td>
                                <td> {{noShow.markedBy}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Company Performance' && !companyDetails ">
            <div class="mid-section mt-4 bg-white" >
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.length > 0"><b>{{'reports.company_performance' | translate}}</b></h3>
                    <h3 *ngIf="reportData && reportData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.sn' | translate}} </th>
                                <th> {{'reports.company_name' | translate}} </th>
                                <th> {{'reports.tax_no' | translate}} </th>
                                <th> {{'reports.room_nights' | translate}} </th>
                                <th> {{'reports.business_generated' | translate}} ({{currencyCode}}) </th>
                                <th> {{'reports.arr' | translate}} </th>
                                <th> {{'reports.total_outstanding' | translate}} ({{currencyCode}}) </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let company of reportData; index as i">
                                <td> {{i+1}}. </td>
                                <td> 
                                    <a href="" (click)="getCompanyDetails(company.name, company.guestWiseList);" onClick="return false;">{{company.name}}</a>
                                </td>
                                <td> {{company.taxNo}} </td>
                                <td> {{company.roomNights}} </td>
                                <td> {{company.business}} </td>
                                <td> {{company.arr}} </td>
                                <td> {{company.outstanding}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Company Performance' && companyDetails ">
            <div class="mid-section mt-4 bg-white" >
                <div class="head-row">
                    <h3 *ngIf="guestData && guestData.length > 0"><b>Guest Details ({{comapanyName}})</b></h3>
                    <h3 *ngIf="guestData && guestData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                    <button *ngIf="reportName == 'Company Performance' && companyDetails"  mat-stroked-button class="active small-btn" (click)="backButton()"><mat-icon>arrow_back</mat-icon>Back</button>
                </div>
                <div *ngIf="guestData && guestData.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.sn' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.checkin' | translate}} </th>
                                <th> {{'reports.checkout' | translate}} </th>
                                <th> {{'reports.pax' | translate}} </th>
                                <th> {{'reports.room_nights' | translate}} </th>
                                <th> {{'reports.source' | translate}} </th>
                                <th nowrap=""> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                                <th> {{'reports.invoice_no' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let guest of guestData; index as i">
                                <td> {{i+1}}. </td>
                                <td> {{guest.guestName}} </td>
                                <td> {{guest.checkIn}} </td>
                                <td> {{guest.checkOut}} </td>
                                <td> {{guest.pax}} </td>
                                <td> {{guest.nights}} </td>
                                <td> {{guest.source}} </td>
                                <td> {{guest.amount}} </td>
                                <td> <a href= {{guest.invoiceUrl}} target="_blank">{{guest.invoiceNum}} </a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Payment Void Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData[0]?.data.length > 0"><b>{{'reports.payment_void_report' | translate}}</b></h3>
                    <h3 *ngIf="reportData && reportData[0]?.data.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData[0]?.data.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.sn' | translate}} </th>
                                <th> {{'reports.date' | translate}} </th>
                                <th> {{'reports.type_of_payment' | translate}}  </th>
                                <th nowrap=""> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th nowrap=""> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.folio_number' | translate}} </th>
                                <th> {{'reports.user' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let void of reportData[0].data; index as i">
                                <td> {{i+1}}. </td>
                                <td nowrap=""> {{void.date}} </td>
                                <td> {{void.typeOfPayment}} </td>
                                <td> {{void.payAmount}} </td>
                                <td> {{void.guestName}} </td>
                                <td> {{void.roomNo}} </td>
                                <td nowrap=""><a href= {{void.invoiceUrl}} target="_blank"> {{void.folioNo}}</a></td>
                                <td> {{void.changedBy}} </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: right"><b>{{'reports.total' | translate}} :</b></td>
                                <td> <b>{{ reportData[0].totalVoidAmount}}</b> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'Expense Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData[0]?.data.length > 0"><b>{{'reports.expense_report' | translate}}</b></h3>
                    <h3 *ngIf="reportData && reportData[0]?.data.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData[0]?.data.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.sn' | translate}} </th>
                                <th nowrap=""> {{'reports.date' | translate}} </th>
                                <th nowrap=""> Expense Date </th>
                                <th> {{'reports.type_of_payment' | translate}} </th>
                                <th> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                                <th nowrap=""> Invoice No. </th>
                                <th nowrap="">Expense Category </th>
                                <th> {{'reports.comments' | translate}} </th>
                                <th> {{'reports.user' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let expense of reportData[0].data; index as i">
                                <td> {{i+1}}. </td>
                                <td nowrap=""> {{expense.date}} </td>
                                <td nowrap=""> {{expense.expenseDate}} </td>
                                <td> {{expense.typeOfPayment}} </td>
                                <td> {{expense.payAmount}} </td>
                                <td> {{expense.invoiceNo}} </td>
                                <td> {{expense.expenseCategory}} </td>
                                <td> {{expense.comment}} </td>
                                <td> {{expense.changedBy}} </td>
                            </tr>
                            <tr>
                                <td colspan="4" style="text-align: right"><b>{{'reports.total' | translate}} :</b></td>
                                <td> <b>{{ reportData[0].totalExpenseAmount}}</b> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="mid-section mt-4 bg-white" *ngIf="reportName == 'Group Expense Report'  ">
            <div class="head-row">
                <h3 *ngIf="reportData.paymentsList?.length > 0"><b>{{reportName}}</b> </h3> 
                <h3 *ngIf="reportData.grandTotal && reportData.paymentsList?.length > 0 " class="pull-left">Total : <b  class="pull-left">{{reportData.grandTotal}}</b> </h3> 
                <h3 *ngIf="reportData.paymentsList?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b> </h3>
            </div>

            <div *ngIf="reportData.paymentsList?.length > 0"  class="table-responsive table-bordered">
                <table role="grid" width="100%">
                    <thead class="thead-dark">
                        <tr>
                            <th nowrap=""> {{'reports.date' | translate}} </th>
                            <th nowrap=""> Expense Date </th>
                            <th> {{'reports.type_of_payment' | translate}} </th>
                            <th> {{'reports.amount' | translate}} ({{currencyCode}}) </th>
                            <th nowrap=""> Invoice No. </th>
                            <th nowrap="">Expense Category </th>
                            <th> {{'reports.comments' | translate}} </th>
                            <th> {{'reports.user' | translate}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let payment of reportData.paymentsList">
                            <tr>
                                <td colspan="9"><b>{{payment.name}}</b></td>
                            </tr>
                            <tr *ngFor="let expense of payment.data">
                                <td nowrap=""> {{expense.date}} </td>
                                <td nowrap=""> {{expense.expenseDate}} </td>
                                <td> {{expense.typeOfPayment}} </td>
                                <td> {{expense.payAmount}} </td>
                                <td> {{expense.invoiceNo}} </td>
                                <td> {{expense.expenseCategory}} </td>
                                <td> {{expense.comment}} </td>
                                <td> {{expense.changedBy}} </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td colspan="9"><b> {{payment.paymentTypeTotal}} </b></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'Room Status Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.data?.length"><b>{{'reports.room_status_report' | translate}}</b></h3>
                    <h3 *ngIf="reportData && reportData.data?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.data?.length">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.status' | translate}} </th>
                                <th> {{'reports.pax' | translate}} </th>
                                <th> {{'reports.meal_plan' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.source' | translate}} </th>
                                <th> {{'reports.checkin' | translate}} </th>
                                <th> {{'reports.checkout' | translate}} </th>
                                <th> {{'reports.houseKeeping' | translate}} </th>
                                <th> Bill To </th>
                                <th> Booked By  </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of reportData?.data; index as i">
                                <td> {{item.roomNum}} </td>
                                <td> {{item.status}} </td>
                                <td> {{item.pax}} </td>
                                <td> {{item.mealPlan}} </td>
                                <td> {{item.guestName}} </td>
                                <td> {{item.source}} </td>
                                <td> {{item.checkin}} </td>
                                <td> {{item.checkout}} </td>
                                <td> {{item.houseKeepingStatus}} </td>
                                <td> {{item.billTo}}</td>
                                <td> {{item.bookedBy}} </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td colspan="9"><b> {{reportData.totalPax}} </b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'City Ledger Payment Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.data?.length > 0"><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.data?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.data?.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.sn' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.company_name' | translate}} </th>
                                <th> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.checkin' | translate}} </th>
                                <th> {{'reports.checkout' | translate}} </th>
                                <th> {{'reports.invoice_no' | translate}} </th>
                                <th class="text-center"> {{'reports.amount' | translate}} </th>
                                <th> {{'reports.payment_date' | translate}} </th>
                                <th> {{'reports.reference_id' | translate}} </th>
                                <th> {{'reports.file' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of reportData?.data; index as i">
                                <td> {{i+1}}. </td>
                                <td> {{item.guestName}} </td>
                                <td> {{item.companyName}} </td>
                                <td> {{item.roomNum}} </td>
                                <td> {{item.checkin}} </td>
                                <td> {{item.checkout}} </td>
                                <td> 
                                    <a [href]="item.url" target="_blank">
                                        {{item.invoiceNum}}
                                    </a>
                                </td>
                                <td class="text-right"> {{item.amount | number: '1.2-2'}} </td>
                                <td> {{item.btcPaymentDate}} </td>
                                <td> {{item.btcPaymentRef}} </td>
                                <td> 
                                    <a *ngIf="item.btcPaymentFile"
                                        [href]="item.btcPaymentFile" target="_blank">
                                        {{'reports.view' | translate}}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="7" style="text-align: right"> <b>{{'reports.total' | translate}} :</b> </td>
                                <td colspan="3"><b> {{reportData.totalAmount}} </b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Monthly Performance Report'">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData?.data?.length > 0"><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData?.data?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData?.data?.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th rowspan="2" class="text-center">{{'reports.month' | translate}}</th>
                                <th *ngFor="let pos of reportData?.posNames"
                                    [attr.rowspan]="pos==='Rooms' ? 1 : 2"
                                    [attr.colspan]="pos==='Rooms' ? 3 : 1"
                                    class="text-center">
                                    {{pos}}
                                </th>
                                <th rowspan="2" class="text-center">{{'reports.total' | translate}}</th>
                            </tr>
                            <tr>
                                <th class="text-center">{{'reports.sales' | translate}}</th>
                                <th class="text-center">{{'reports.occ' | translate}}</th>
                                <th class="text-center">{{'reports.arr' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of reportData?.data">
                                <td class="text-center">{{item.month}}</td>
                                <ng-container *ngFor="let s of item.data">
                                    <td *ngIf="s.posName !== 'Rooms'"
                                        class="text-center">
                                        {{s.sales | number: '1.0-0'}}
                                    </td>
                                    <ng-container *ngIf="s.posName === 'Rooms'">
                                        <td class="text-center">{{s.sales | number: '1.0-0'}}</td>
                                        <td class="text-center">{{s.occupancy}} % </td>
                                        <td class="text-center">{{s.arr | number: '1.0-0'}}</td>
                                    </ng-container>
                                </ng-container>
                                <td class="font-weight-bold text-center bg-light-grey">
                                    {{item.totalSales | number: '1.0-0'}}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold text-center bg-light-grey">
                                    {{'reports.total' | translate}}
                                </td>
                                <ng-container *ngFor="let item of reportData?.posTotal">
                                    <td *ngIf="item.name !== 'Rooms'"
                                        class="font-weight-bold text-center bg-light-grey">
                                        {{item.total | number: '1.0-0'}}
                                    </td>
                                    <ng-container  *ngIf="item.name === 'Rooms'">
                                        <td class="font-weight-bold text-center bg-light-grey">
                                            {{item.total | number: '1.0-0'}}
                                        </td>
                                        <td class="font-weight-bold text-center bg-light-grey">
                                            {{item.occupancy}} %
                                        </td>
                                        <td class="font-weight-bold text-center bg-light-grey">
                                            {{item.arr | number: '1.0-0'}}
                                        </td>
                                    </ng-container>
                                </ng-container>
                                
                                <td class="font-weight-bold text-center bg-light-grey">
                                    {{reportData?.total | number: '1.0-0'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Daily Forecast Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.data?.length > 0"><b>{{'reports.daily_forecast_report' | translate}}</b></h3>
                    <h3 *ngIf="reportData && reportData.data?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div class="table-responsive table-bordered">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th nowrap=""> {{'reports.date' | translate}} </th>
                                <th> {{'reports.occupancy' | translate}} </th>
                                <th> {{'reports.occupied' | translate}} </th>
                                <th> {{'reports.available' | translate}} </th>
                                <th> {{'reports.complimentary' | translate}} </th>
                                <th> {{'reports.maintenance' | translate}} </th>
                                <th> {{'reports.revenue' | translate}} ({{currencyCode}})</th>
                                <th> {{'reports.arr' | translate}} ({{currencyCode}})</th>
                                <th *ngFor="let sourceName of reportData.sources;">{{sourceName}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let forecast of reportData.data; index as i">
                                <td nowrap=""> {{forecast.date}} </td>
                                <td> {{forecast.cccupancy}} </td>
                                <td> {{forecast.roomsOccupied}} </td>
                                <td> {{forecast.avaliableRooms}} </td>
                                <td> {{forecast.complementary}} </td>
                                <td> {{forecast.outOfOrder}} </td>
                                <td> {{forecast.roomRevenue}} </td>
                                <td> {{forecast.arr}} </td>
                                <td *ngFor="let sourceValue of forecast.sources" >{{sourceValue}}  </td>
                            </tr>
                            <tr class="font-weight-bold">
                                <td style="text-align: right"> {{'reports.total' | translate}} : </td>
                                <td class="font-weight-bold" *ngFor="let roomTotal of reportData.roomsTotal;"> {{roomTotal}} </td>
                                <td class="font-weight-bold"> {{reportData.totalRevenue}} </td>
                                <td class="font-weight-bold"> {{reportData.totalArr}} </td>
                                <td class="font-weight-bold" *ngFor="let sourceTotal of reportData.totalSources;"> {{sourceTotal}} </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Monthly P&L' " >
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.months?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.months?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.months?.length > 0" class="table-responsive table-bordered">
                    <table  class="table-bordered" width="100%"  *ngIf="reportName == 'Monthly P&L' ">
                        <tbody>
                            <tr>
                                <td class="font-weight-bold bg-light-4f" nowrap="" *ngFor="let month of reportData.months">&emsp;{{month}}</td>
                                <td class="font-weight-bold bg-light-grey">&emsp;{{'reports.total' | translate}}</td>
                            </tr>
                            <tr class="font-weight-bold" *ngIf="reportData && reportData.revenues?.length > 0">
                                <td [attr.colspan]="monthsLength"> {{'reports.revenues' | translate}} :</td>
                            </tr>
                            <tr *ngFor="let revenue of reportData.revenues;  index as i">
                                <td nowrap="" *ngFor="let rev of revenue">&emsp;{{rev}}</td>
                            </tr>
                            <tr>
                                <td nowrap="" class="font-weight-bold bg-light-grey"> {{'reports.total' | translate}}</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let finalRev of reportData.finalTotalRevList">&emsp;{{finalRev}}</td>
                            </tr>
                            <tr class="font-weight-bold" *ngIf="reportData && reportData.expenses?.length > 0">
                                <td [attr.colspan]="monthsLength">{{'reports.expenses' | translate}} :</td>
                            </tr>
                            <tr *ngFor="let expense of reportData.expenses">
                                <td nowrap="" *ngFor="let exp of expense">&emsp;{{exp}}</td>
                            </tr>
                            <tr *ngIf="reportData && reportData.finalTotalExpList?.length > 0">
                                <td nowrap="" class="font-weight-bold bg-light-grey"> {{'reports.total' | translate}}</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let finalExp of reportData.finalTotalExpList">&emsp;{{finalExp}}</td>
                            </tr>
                            <tr  class="font-weight-bold" *ngIf="reportData && reportData.purchases?.length > 0">
                                <td [attr.colspan]="monthsLength">{{'reports.purchases' | translate}} :</td>
                            </tr>
                            <tr *ngFor="let purchanse of reportData.purchases">
                                <td nowrap="" *ngFor="let pur of purchanse">&emsp;{{pur}}</td>
                            </tr>
                            <tr *ngIf="reportData && reportData.finalTotalPurList?.length > 0">
                                <td nowrap="" class="font-weight-bold bg-light-grey"> {{'reports.total' | translate}}</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let finalPur of reportData.finalTotalPurList">&emsp;{{finalPur}}</td>
                            </tr>
                            <tr *ngIf="reportData && reportData.profits?.length > 0">
                                <td nowrap="" class="font-weight-bold bg-light-grey"> {{'reports.profit' | translate}} :</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let profit of reportData.profits">&emsp;{{profit}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="mid-section mt-4 bg-white" *ngIf="reportName == 'Contribution Report'">
            <div class="head-row">
                <h3 *ngIf="reportData.length > 0"><b>{{reportName}}</b></h3>
                <h3 *ngIf="reportData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
            </div>
            <div *ngIf="reportData.length > 0" class="table-responsive table-bordered">
                <table class="table-bordered" width="100%">
                    <thead class="thead-dark" >
                        <tr>
                            <th> </th>
                            <th> {{'reports.room_nights' | translate}} </th>
                            <th nowrap=""> {{'reports.revenues' | translate}} ({{currencyCode}}) </th>
                            <th> {{'reports.arr' | translate}}  ({{currencyCode}})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let other of reportData">
                            <tr class="font-weight-bold bg-light-grey">
                                <td>{{other.name}} :</td>
                                <td nowrap="">{{other.totalNights}} </td>
                                <td nowrap=""> {{other.totalAmount}} </td>
                                <td nowrap=""> {{other.totalArr}} </td>
                            </tr>
                            <tr *ngFor="let otherSer of other.data">
                                <td style="padding-left: 30px;"> {{otherSer.name}} </td>
                                <td> {{otherSer.roomNights}} </td>
                                <td > {{otherSer.business}} </td>
                                <td> {{otherSer.arr}} </td>
                            </tr>
                        </ng-container>
                        <ng-container>
                            <tr class="font-weight-bold bg-light-4f">
                                <td style="text-align: right"> {{'reports.total' | translate}} : </td>
                                <td> {{ContriGrandTotals.finalRns}} </td>
                                <td> {{ContriGrandTotals.finalRev}}</td>
                                <td> {{ContriGrandTotals.finalArr}} </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Group P&L' " >
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.hotels?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.hotels?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.hotels?.length > 0" class="table-responsive table-bordered">
                    <table class="PLHeaderFix" role="grid" width="auto" *ngIf="reportName == 'Group P&L' ">
                        <tbody>
                            <tr>
                                <td class="font-weight-bold bg-light-4f" *ngFor="let hotel of reportData.hotels">{{hotel}}</td>
                                <td class="font-weight-bold bg-light-grey">{{'reports.total' | translate}}</td>
                            </tr>
                            <tr class="font-weight-bold" *ngIf="reportData && reportData.revenues?.length > 0">
                                <td nowrap="" class="fixed">
                                    <a href="#revenues" data-toggle="collapse"> {{'reports.revenues' | translate}} :  </a>
                                </td>
                                <td *ngFor="let hotel of reportData.hotels" style="color: white;">space</td>
                                <!-- <td [attr.colspan]="hotelsLength" >  
                                    <a href="#revenues" data-toggle="collapse"> {{'reports.revenues' | translate}} </a>
                                </td> -->
                            </tr>
                            <tr *ngFor="let revenue of reportData.revenues;  index as i" class="collapse" id="revenues">
                                <td  class="fixed">{{reportData.revenueKeys[i]}}</td>
                                <td nowrap="" *ngFor="let rev of revenue">&emsp;{{rev}}</td>
                            </tr>
                            <tr>
                                <td nowrap="" class="font-weight-bold bg-light-grey"  class="totalFixed"> {{'reports.total' | translate}}</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let finalRev of reportData.finalTotalRevList">&emsp;{{finalRev}}</td>
                            </tr>
                            <tr class="font-weight-bold" *ngIf="reportData && reportData.expenses?.length > 0">
                                <td class="fixed">
                                    <a href="#expenses" data-toggle="collapse"> {{'reports.expenses' | translate}} :</a>
                                </td>
                                <td *ngFor="let hotel of reportData.hotels" style="color: white;">space</td>
                            </tr>
                            <tr *ngFor="let expense of reportData.expenses;  index as i" class="collapse" id="expenses">
                                <td nowrap="" class="fixed">{{reportData.expensesKeys[i]}}</td>
                                <td nowrap="" *ngFor="let exp of expense">&emsp;{{exp}}</td>
                            </tr>
                            <tr *ngIf="reportData && reportData.finalTotalExpList?.length > 0">
                                <td nowrap="" class="font-weight-bold bg-light-grey" class="totalFixed"> {{'reports.total' | translate}}</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let finalExp of reportData.finalTotalExpList">&emsp;{{finalExp}}</td>
                            </tr>
                            <tr  class="font-weight-bold" *ngIf="reportData && reportData.purchases?.length > 0">
                                <td class="fixed">
                                    <a href="#purchases" data-toggle="collapse"> {{'reports.purchases' | translate}} :</a>
                                </td>
                                <td *ngFor="let hotel of reportData.hotels" style="color: white;">space</td>
                            </tr>
                            <tr *ngFor="let purchanse of reportData.purchases;  index as i" class="collapse" id="purchases">
                                <td nowrap="" class="fixed">{{reportData.purchaseKeys[i]}}</td>
                                <td nowrap="" *ngFor="let pur of purchanse">&emsp;{{pur}}</td>
                            </tr>
                            <tr *ngIf="reportData && reportData.finalTotalPurList?.length > 0">
                                <td nowrap="" class="font-weight-bold bg-light-grey" class="totalFixed"> {{'reports.total' | translate}}</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let finalPur of reportData.finalTotalPurList">&emsp;{{finalPur}}</td>
                            </tr>
                            <tr *ngIf="reportData && reportData.profits?.length > 0">
                                <td nowrap="" class="font-weight-bold bg-light-grey" class="totalFixed"> {{'reports.profit' | translate}} :</td>
                                <td nowrap="" class="font-weight-bold bg-light-grey" *ngFor="let profit of reportData.profits">&emsp;{{profit}}</td>
                            </tr>
                            <tr></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Reviews Tracking Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.allocationData?.length > 0"><b>{{'reports.reviews_tracking_report' | translate}}</b></h3>
                    <h3 *ngIf="reportData && reportData.allocationData?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.allocationData?.length > 0">
                    <table role="grid" class="table-bordered"  width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> {{'reports.room_no' | translate}} </th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th nowrap=""> {{'reports.mobile' | translate}} </th>
                                <th nowrap=""> {{'reports.checkin' | translate}}  </th>
                                <th nowrap=""> {{'reports.checkout' | translate}}  </th>
                                <th nowrap=""> {{'reports.invoice_no' | translate}}  </th>
                                <th> {{'reports.source' | translate}}  </th>
                                <th> {{'reports.review_score' | translate}} </th>
                                <th> {{'reports.comments' | translate}} </th>
                                <th> {{'reports.send_message' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let review of reportData.allocationData; index as i">
                                <td nowrap=""> {{review.roomNo}} </td>
                                <td> {{review.guestName}} </td>
                                <td nowrap=""> {{review.mobile}} </td>
                                <td nowrap=""> {{review.checkIn}} </td>
                                <td nowrap=""> {{review.checkOut}} </td>
                                <td nowrap=""> 
                                    <a href= {{review.url}} target="_blank"> {{review.invoiceNo}}</a>
                                     </td>
                                <td> {{review.source}} </td>
                                <td> {{review.reviewScore}} </td>
                                <td>
                                    <p>{{review.reviewComment.text}}</p>
                                    <p class="text-success">{{review.reviewComment.ptext}}</p>
                                    <p class="text-danger">{{review.reviewComment.ntext}}</p>
                                </td>
                                <td> <button mat-icon-button color="primary" (click)="sendFeedBackLink(review.mobile, review.bookingId);"> <mat-icon>near_me</mat-icon> </button> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'IOT Occupancy Issues Report' || reportName=='Group IOT Occupancy Issues Report'">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && (reportData.ocupancyMismatchData?.length > 0 || reportData.deviceDisconnectedData?.length > 0)"><b>{{'reports.iot_occupancy_issues_report' | translate}}</b></h3>
                    <h3 *ngIf="reportData && (reportData.ocupancyMismatchData?.length == 0 && reportData.deviceDisconnectedData?.length == 0) "><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && (reportData.ocupancyMismatchData?.length > 0 || reportData.deviceDisconnectedData?.length > 0) ">
                    <table role="grid" class="table-bordered" width="100%" *ngIf="reportData && reportData.ocupancyMismatchData?.length > 0">
                        <thead class="thead-dark">
                            <tr>
                                <th>{{'reports.room_number' | translate}} </th>
                                <th nowrap=""> {{'reports.start_time' | translate}} </th>
                                <th nowrap=""> {{'reports.end_time' | translate}}  </th>
                                <th nowrap=""> {{'reports.occupancy_status' | translate}}  </th>
                                <th> {{'reports.device_status' | translate}}  </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr bgcolor="grey" style="color:white">
                                <td colspan="5" class="font-weight-bold">Occupancy MisMatch :</td>
                            </tr>
                            <ng-container *ngFor="let iot of reportData.ocupancyMismatchData">
                                <tr *ngIf="reportName == 'Group IOT Occupancy Issues Report'">
                                    <th colspan="5">{{iot.hotelName}} : </th>
                                </tr>
                                <tr *ngFor="let iotData of iot.data">
                                    <td>{{iotData.roomNum}} </td>
                                    <td nowrap=""> {{iotData.startDate}} </td>
                                    <td nowrap=""> {{iotData.endDate}} </td>
                                    <td nowrap=""> {{'reports.available' | translate}} </td>
                                    <td>{{'reports.on' | translate}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <br>
                    <table role="grid" class="table-bordered"  width="100%" *ngIf="reportData && reportData.deviceDisconnectedData?.length > 0">
                        <thead class="thead-dark">
                            <tr>
                                <th>{{'reports.room_number' | translate}} </th>
                                <th nowrap=""> {{'reports.start_time' | translate}} </th>
                                <th nowrap=""> {{'reports.end_time' | translate}}  </th>
                                <th nowrap=""> {{'reports.occupancy_status' | translate}}  </th>
                                <th> {{'reports.device_status' | translate}}  </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr bgcolor="grey"  style="color:white">
                                <td colspan="5" class="font-weight-bold">Device Disconnected :</td>
                            </tr>
                            <ng-container *ngFor="let iot of reportData.deviceDisconnectedData">
                                <tr *ngIf="reportName == 'Group IOT Occupancy Issues Report'">
                                    <th colspan="5">{{iot.hotelName}} : </th>
                                </tr>
                                <tr  *ngFor="let iotData of iot.data">
                                    <td>{{iotData.roomNum}} </td>
                                    <td nowrap=""> {{iotData.startDate}} </td>
                                    <td nowrap=""> {{iotData.endDate}} </td>
                                    <td nowrap=""> - </td>
                                    <td> - </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'Group Maintenance Report' || reportName=='Maintenance Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.length > 0"><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th nowrap=""> {{'reports.date' | translate}} </th>
                                <th> {{'reports.operation_type' | translate}} </th>
                                <th> {{'reports.area' | translate}} </th>
                                <th> {{'reports.room_number' | translate}} </th>
                                <th> {{'reports.description' | translate}} </th>
                                <th> {{'reports.priority' | translate}} </th>
                                <th> {{'reports.status' | translate}} </th>
                                <th> {{'reports.assigned_to' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let maintenance of reportData">
                                <tr *ngIf="reportName == 'Group Maintenance Report'">
                                    <th colspan="10">{{maintenance.hotelName}} : </th>
                                </tr>
                                <tr *ngFor="let maintenanceData of maintenance.data">
                                    <td nowrap=""> {{maintenanceData.date}} </td>
                                    <td> {{maintenanceData.operation}} </td>
                                    <td> {{maintenanceData.area}} </td>
                                    <td> {{maintenanceData.roomNo}} </td>
                                    <td> {{maintenanceData.description}} </td>
                                    <td> {{maintenanceData.priority}} </td>
                                    <td> {{maintenanceData.status}} </td>
                                    <td> {{maintenanceData.assigned}} </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'Room Wise Revenue Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.data?.length > 0"><b> Room Wise Revenue Report</b></h3>
                    <h3 *ngIf="reportData && reportData.data?.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div *ngIf="reportData && reportData.data?.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th nowrap=""> {{'reports.room_no' | translate}}</th>
                                <th> {{'reports.guest_name' | translate}} </th>
                                <th> {{'reports.pax' | translate}} </th>
                                <th> {{'reports.checkin' | translate}} </th>
                                <th> {{'reports.checkout' | translate}} </th>
                                <th> {{'reports.source' | translate}} </th>
                                <th> {{'reports.day_revenue' | translate}} ({{currencyCode}})</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let roomWise of reportData.data">
                                <tr>
                                    <td colspan="7"><b>{{roomWise.date}}</b></td>
                                </tr>
                                <tr *ngFor="let room of roomWise.data">
                                    <td nowrap=""> {{room.roomNo}} </td>
                                    <td> {{room.guestName}} </td>
                                    <td> {{room.pax}} </td>
                                    <td> {{room.checkIn}} </td>
                                    <td> {{room.checkOut}} </td>
                                    <td> {{room.source}} </td>
                                    <td> {{room.revenue}} </td>
                                </tr>
                                <tr class="font-weight-bold">
                                    <td colspan="6" style="text-align:right;">{{'reports.total' | translate}}</td>
                                    <td>{{roomWise.total}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'Date Wise Revenue Report' || reportName == 'Group Date Wise Revenue Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.dateWiseData?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.dateWiseData?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div *ngIf="reportData && reportData.dateWiseData?.length > 0" class="table-bordered table-responsive">
                    <table  width="100%">
                        <thead>
                            <tr class="text-center">
                                <th nowrap="" rowspan="2"> {{'reports.date' | translate}} </th>
                                <th nowrap="" rowspan="2"> {{'reports.occupancy' | translate}} </th>
                                <th nowrap="" rowspan="2"> {{'reports.total_revenue' | translate}}({{currencyCode}}) </th>
                                <th [attr.colspan]="posLength"> {{'reports.pos' | translate}} </th>
                                <th [attr.colspan]="segmentLength"> {{'reports.segment' | translate}} </th>
                                <th [attr.colspan]="taxLength"> {{'reports.taxes' | translate}} </th>
                            </tr>
                            <tr class="text-center">
                                <th nowrap="" *ngFor="let posName of reportData.posNames"> {{posName}}</th>
                                <th nowrap="" *ngFor="let segmentName of reportData.segmentNames"> {{segmentName}}</th>
                                <th nowrap="" *ngFor="let taxName of reportData.taxNames"> {{taxName}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dateWise of reportData.dateWiseData" class="text-center">
                                <td nowrap=""> {{dateWise.date}} </td>
                                <td nowrap=""> {{ dateWise.occPer }} % </td>
                                <td nowrap=""> {{dateWise.revenue}} </td>
                                <td nowrap="" *ngFor="let posValue of dateWise.posData"> {{posValue}}</td>
                                <td nowrap="" *ngFor="let segmentValue of dateWise.segmentData"> {{segmentValue}}</td>
                                <td nowrap="" *ngFor="let taxValue of dateWise.taxData"> {{taxValue}}</td>
                            </tr>
                            <tr class="font-weight-bold text-center">
                                <td>{{'reports.total' | translate}}</td>
                                <td nowrap="" *ngFor="let total of reportData.totals"> {{total}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Group IOT Device Monthly Status Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.data?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.data?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div *ngIf="reportData && reportData.data?.length > 0" class="table-bordered table-responsive">
                    <table  width="100%">
                        <thead>
                            <tr>
                                <th nowrap=""> Hotel Name</th>
                                <th nowrap=""> Occupancy Mismatch </th>
                                <th nowrap=""> Device Disconnected </th>
                                <th nowrap=""> Total </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let iot of reportData.data">
                                <td nowrap=""> {{iot.hotelName}} </td>
                                <td nowrap=""> {{iot.mismatchValue}} </td>
                                <td nowrap=""> {{ iot.disconnectValue }} </td>
                                <td nowrap=""> {{iot.total}} </td>
                            </tr>
                            <tr class="font-weight-bold">
                                <td>{{'reports.total' | translate}}</td>
                                <td nowrap=""> {{reportData.total.mismatch}} </td>
                                <td nowrap=""> {{reportData.total.disconnect}} </td>
                                <td nowrap=""> {{reportData.total.totalValue}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Group Cash Balance Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.data?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.data?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div *ngIf="reportData && reportData.data?.length > 0" class="table-bordered table-responsive">
                    <table  width="100%">
                        <thead>
                            <tr>
                                <th nowrap=""> Hotel Name</th>
                                <th nowrap=""> Amount </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let iot of reportData.data">
                                <td nowrap=""> {{iot.name}} </td>
                                <td nowrap=""> {{iot.amt}}  </td>
                            </tr>
                            <tr class="font-weight-bold">
                                <td>{{'reports.total' | translate}}</td>
                                <td nowrap=""> {{reportData.total}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Group Weekly Meeting Report' || reportName == 'Hotel Weekly Meeting Report' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData.hotels?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData.hotels?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div *ngIf="reportData && reportData.hotels?.length > 0" class="table-bordered table-responsive">
                    <table width="auto" class="headerFix">
                        <thead>
                            <tr>
                                <td class="font-weight-bold bg-light-4f"></td>
                                <td class="font-weight-bold bg-light-4f" *ngFor="let hotel of reportData.hotels"> {{hotel}} </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="font-weight-bold" *ngIf="reportData && reportData.revenueList?.length > 0" style="background-color:#c4e6f5;">
                                <td [attr.colspan]="hotelsLength + 1"> Sales :</td>
                            </tr>
                            <tr *ngFor="let revenue of reportData.revenueList;  index as i">
                                <td nowrap="" class="fixed">{{reportData.revenueKeys[i]}}</td>
                                <td nowrap="" *ngFor="let rev of revenue">{{rev}}</td>
                            </tr>
                            <tr class="font-weight-bold" *ngIf="reportData && reportData.revenueList?.length > 0" style="background-color:#c4e6f5;">
                                <td [attr.colspan]="hotelsLength + 1"> Reviews :</td>
                            </tr>
                            <tr class="font-weight-bold" *ngIf="reportData && reportData.revenueList?.length > 0">
                                <td [attr.colspan]="hotelsLength + 1"> Overall :</td>
                            </tr>
                            <tr *ngFor="let reviews of reportData.reviewList;  index as j">
                                <td nowrap="" class="fixed" style="padding-left: 20px;">{{reportData.reviewKeys[j]}}</td>
                                <td nowrap="" *ngFor="let review of reviews">{{review}}</td>
                            </tr>

                            <tr class="font-weight-bold">
                                <td [attr.colspan]="hotelsLength + 1"> <marquee># OTA : Reviews | Weekly Score | Overall Score</marquee>  </td>
                            </tr>
                            <tr  *ngIf="reportData && reportData.bookingList?.length > 0">
                                <td class="font-weight-bold fixed"> Booking.com :</td>
                                <td nowrap="" *ngFor="let hotel of reportData.hotels;  index as j">
                                    <span class="text-success">{{reportData.bookingList[0][j]}} </span> &nbsp;| <span class="text-success">{{reportData.bookingList[1][j]}}</span> &nbsp;|  <span class="text-success">{{reportData.bookingList[2][j]}}</span> 
                                </td>
                            </tr>
                            <tr  *ngIf="reportData && reportData.goibiboList?.length > 0">
                                <td class="font-weight-bold fixed"> Goibibo :</td>
                                <td nowrap="" *ngFor="let hotel of reportData.hotels;  index as j">
                                    <span class="text-success">{{reportData.goibiboList[0][j]}} </span> &nbsp;| <span class="text-success">{{reportData.goibiboList[1][j]}}</span> &nbsp;|  <span class="text-success">{{reportData.goibiboList[2][j]}}</span> 
                                </td>
                            </tr>
                            <tr  *ngIf="reportData && reportData.mmtList?.length > 0">
                                <td class="font-weight-bold fixed"> Makemytrip :</td>
                                <td nowrap="" *ngFor="let hotel of reportData.hotels;  index as j">
                                    <span class="text-success">{{reportData.mmtList[0][j]}} </span> &nbsp;| <span class="text-success">{{reportData.mmtList[1][j]}}</span> &nbsp;|  <span class="text-success">{{reportData.mmtList[2][j]}}</span> 
                                </td>
                            </tr>
                            <tr  *ngIf="reportData && reportData.googleList?.length > 0">
                                <td class="font-weight-bold fixed"> Google :</td>
                                <td nowrap="" *ngFor="let hotel of reportData.hotels;  index as j">
                                    <span class="text-success">{{reportData.googleList[0][j]}} </span> &nbsp;| <span class="text-success">{{reportData.googleList[1][j]}}</span> &nbsp;|  <span class="text-success">{{reportData.googleList[2][j]}}</span> 
                                </td>
                            </tr>

                            <tr class="font-weight-bold" *ngIf="reportData && reportData.operationList?.length > 0" style="background-color:#c4e6f5;">
                                <td [attr.colspan]="hotelsLength + 1"> Operations :</td>
                            </tr>
                            <tr *ngFor="let operation of reportData.operationList;  index as i">
                                <td nowrap="" class="fixed">{{reportData.operationKeys[i]}}</td>
                                <td nowrap="" *ngFor="let ope of operation">{{ope}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Group Availability Mismatch Report (PMS vs CM)' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData?.length > 0" ><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData && reportData?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div *ngIf="reportData && reportData?.length > 0" class="table-bordered table-responsive">
                    <table  width="100%">
                        <thead>
                            <tr>
                                <th nowrap=""> Hotel Name</th>
                                <th nowrap=""> PMS </th>
                                <th nowrap=""> CM </th>
                                <th nowrap=""> Difference </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let mismatch of reportData">
                                <td nowrap=""> {{mismatch.hotelName}} </td>
                                <td nowrap=""> {{mismatch.pmsInv}} </td>
                                <td nowrap=""> {{mismatch.cmInv }} </td>
                                <td nowrap="" [ngStyle]="mismatch.diff != 0 && {'color':'red'}"> {{mismatch.diff}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="mid-section mt-4 bg-white" *ngIf="reportName == 'Group Reconciliation Report' ">
            <div class="head-row">
                <h3 *ngIf="reportData.paymentsList?.length > 0"><b>{{reportName}}</b> </h3> 
                <h3 *ngIf="reportData.paymentsList?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b> </h3>
            </div>

            <div *ngIf="reportData.paymentsList?.length > 0" class="table-responsive table-bordered">
                <table role="grid" width="100%">
                    <thead class="thead-dark">
                        <tr>
                            <th nowrap=""> {{'reports.date' | translate}} </th>
                            <th> {{'reports.type_of_payment' | translate}} </th>
                            <th> {{'reports.source' | translate}} </th>
                            <th> {{'reports.guest_name' | translate}} </th>
                            <th> {{'reports.room_no' | translate}} </th>
                            <th nowrap=""> {{'reports.folio_no' | translate}} </th>
                            <th> Paid IN ({{currencyCode}})</th>
                            <th> Paid Out ({{currencyCode}})</th>
                            <th> {{'reports.comments' | translate}} </th>
                            <th> {{'reports.user' | translate}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let payment of reportData.paymentsList">
                            <tr>
                                <td colspan="9"><b>{{payment.name}}</b></td>
                            </tr>
                            <tr *ngFor="let paymentData of payment.data">
                                <td nowrap=""> {{paymentData.commonDate}} </td>
                                <td> {{paymentData.typeOfPayment}} </td>
                                <td> {{paymentData.source}} </td>
                                <td> {{paymentData.guestName}} </td>
                                <td> {{paymentData.roomNo}} </td>
                                <td nowrap=""> <a href= {{paymentData.invoiceUrl}} target="_blank"> {{paymentData.folioNo}}</a></td>
                                <td> {{paymentData.paidIn}} </td>
                                <td> {{paymentData.paidOut}} </td>
                                <td> {{paymentData.comment}} </td>
                                <td> {{paymentData.changedBy}} </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>


        <div class="content" *ngIf="reportName == 'Laundry Report'">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData.length > 0"><b>{{reportName}}</b></h3>
                    <h3 *ngIf="reportData.length == 0"><b>{{'reports.report_not_avaliable' | translate}}</b></h3>
                </div>
                <div class="" *ngIf="reportData.length > 0">
                    <table class="table-bordered" width="100%">
                        <thead class="thead-dark">
                            <tr>
                                <th> Item </th>
                                <th> Total Sent </th>
                                <th> Total Received </th>
                                <th> Current Balance </th>
                                <th> Total Items </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of reportData">
                                <td>{{data.item_name}} </td>
                                <td>{{data.total_sent}}</td>
                                <td>{{data.total_received}}</td>
                                <td>{{data.current_balance}}</td>
                                <td>{{data.total_item}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName === 'Group Laundry Report'">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData.length > 0"><b>{{ reportName }}</b></h3>
                    <h3 *ngIf="reportData.length == 0"><b>{{ 'reports.report_not_avaliable' | translate }}</b></h3>
                </div>
                <div style="overflow-x: auto;" *ngIf="reportData.length > 0">
                    <table class="PLHeaderFix" role="grid" width="auto" class="table-bordered">
                        <tbody>
                            <tr>
                                <th style="position: sticky; top: 0; z-index: 2;" class="font-weight-bold bg-light-4f"></th>
                                <td class="font-weight-bold bg-light-4f" *ngFor="let hotel of hotels">{{ hotel }}</td>
                                <td style="position: sticky; top: 0; z-index: 1;"class="font-weight-bold bg-light-grey">{{'reports.total' | translate}}</td>
                            </tr>

                            <ng-container *ngFor="let item of reportData">
                                <tr>
                                    <td style="position: sticky; left: 0; z-index: 2;" class="fixed">{{ item[0] }}</td>
                                    <td *ngFor="let hotel of hotels" >{{ item[1][hotel] || 0 }}</td>
                                    <td>{{ item[1]['total'] }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Cancelled Reports' && reportTabName == 'Deleted Reservations' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData?.reservations?.length > 0" ><b>{{reportName}} <small>({{reportTabName}})</small></b></h3>
                    <h3 *ngIf="reportData && reportData?.reservations?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div *ngIf="reportData && reportData?.reservations?.length > 0" class="table-bordered table-responsive">
                    <table  width="100%">
                        <thead>
                            <tr>
                                <th nowrap=""> Channel</th>
                                <th nowrap=""> Booking Id </th>
                                <th nowrap=""> Customer Name </th>
                                <th nowrap=""> Payment </th>
                                <th nowrap=""> Booked On </th>
                                <th nowrap=""> Checkin </th>
                                <th nowrap=""> Checkout </th>
                                <th nowrap=""> Room </th>
                                <th nowrap=""> Total Room Nights </th>
                                <th nowrap=""> # of Rooms </th>
                                <th nowrap=""> Mealplan </th>
                                <th nowrap=""> Price </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let booking of reportData.reservations">
                                <td nowrap=""> {{booking.channel}} </td>
                                <td nowrap=""> {{booking.booking_id}} </td>
                                <td nowrap=""> {{booking.customer_blurb }} </td>
                                <td nowrap=""> {{booking.pah }} </td>
                                <td nowrap=""> {{booking.booking_date}} </td>
                                <td nowrap=""> {{booking.checkin_date}} </td>
                                <td nowrap=""> {{booking.checkout_date}} </td>
                                <td nowrap=""> {{booking.roomId }} </td>
                                <td nowrap=""> {{booking.num_rooms}} </td>
                                <td nowrap=""> {{booking.num_room_nights}} </td>
                                <td nowrap=""> {{booking.mealPlan }} </td>
                                <td nowrap=""> {{booking.total_price }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="reportName == 'Cancelled Reports' && reportTabName == 'Skipped Invoice Details' ">
            <div class="mid-section mt-4 bg-white">
                <div class="head-row">
                    <h3 *ngIf="reportData && reportData?.missedInvoicesDict?.length > 0" ><b>{{reportName}} <small>({{reportTabName}})</small></b></h3>
                    <h3 *ngIf="reportData && reportData?.missedInvoicesDict?.length == 0"><b>{{'reports.report_not_avaliable' | translate}} </b></h3>
                </div>
                <div class="table-bordered table-responsive" *ngIf="reportData && reportData?.missedInvoicesDict">
                    <table width="100%">
                        <thead>
                            <tr>
                                <th nowrap=""> Invoice Prefix</th>
                                <th nowrap=""> Missing Numbers</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let missed of reportData.missedInvoicesDict | keyvalue">
                                <td nowrap=""> {{missed.key}} </td>
                                <td nowrap=""> {{missed.value}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>
