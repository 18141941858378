<div>
    <div class="aio_heading">
      <h2>Order View - {{posName}}</h2>

        <div>
            <button mat-raised-button class="primary_btn mr-2"
                (click)="openOrder()">
                Create Order
            </button>

            <button mat-raised-button class="primary_btn"
                routerLink="/pos/{{hotelId}}">
                Back
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
  
    <div class="container-fluid common_content">

        <div class="table_container">

            <div class="tables">
                <ng-container  *ngFor="let order of orders; let i=index">

                    <div class="table" [matMenuTriggerFor]="tableCard">

                        <div class="header"
                            [ngClass]="{
                                'billed': order.billed_date, 
                                'unbilled': !order.billed_date
                            }">
                        <span>{{order.posExtraData?.tableNum}}</span>
                        
                            <span *ngIf="order && order?.status !== 'Billed'">
                                <svg (click)="orderReadyToggle(order); $event.stopPropagation()"
                                [matTooltip]="order?.status === 'ready' ? 'Mark Not Ready' : 'Mark Ready'"
                                xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" id="order-ready-icon">
                                <path [ngStyle]="order?.status === 'ready' && {'fill': '#03c303'}"
                                    d="M88 0C74.7 0 64 10.7 64 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C120.5 112.3 128 119.9 128 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C119.5 47.7 112 40.1 112 24c0-13.3-10.7-24-24-24zM32 192c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96H288c53 0 96-43 96-96h16c61.9 0 112-50.1 112-112s-50.1-112-112-112H352 32zm352 64h16c26.5 0 48 21.5 48 48s-21.5 48-48 48H384V256zM224 24c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C232.5 112.3 240 119.9 240 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C231.5 47.7 224 40.1 224 24z" />
                                </svg>
                            </span>
                        </div>

                        
                        <!-- TABLE BOX -->
                        <div>{{order.minutesElapsed}} mins</div>
                        <div>{{order.total_amount | currency: currency}}</div>

                        
                        <!-- TABLE CARD -->
                        <mat-menu #tableCard="matMenu">
                            <div class="table_card">
                                <div class="info_section">
                                    <div>
                                        <div>{{order.order_id}}</div>
                                        <div>Staff: {{order.posExtraData?.staff}}</div>

                                        <div>Details: {{order.details}}</div>
                                        <div>Created: {{order.date | date: 'dd/MM hh:mm a'}}</div>
                                    </div>

                                    <div class="service_used">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Qty</th>
                                                    <th class="text-right">Amt</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let service of order.services_used">
                                                    <td>{{service.service_name}}</td>
                                                    <td>{{service.quantity}}</td>
                                                    <td class="text-right">{{service.discounted_amount ? service.discounted_amount : service.total_amount}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <div>
                                        <div>Balance: {{order.balance}}</div>
                                        <div>Total: {{order.total_amount}}</div>
                                    </div>
                                </div>

                                <div class="actions">
                                    <button mat-raised-button (click)="openOrder(order)">Edit</button>
                                    <button mat-raised-button (click)="viewBill(order)">View</button>
                                    <button mat-raised-button (click)="deleteOrder(order, i)"
                                        *ngIf="order.status !== 'Billed'">
                                        Delete
                                    </button>
                                    <button mat-raised-button (click)="print(order)">Print</button>
                                    <button mat-raised-button (click)="openOrder(order, null, true)"
                                        *ngIf="order.status !== 'Billed'">
                                        Bill
                                    </button>
                                    <button mat-raised-button (click)="goToAddPayment(order)">Payment</button>
                                </div>
                            </div>
                        </mat-menu>
                        <!-- TABLE CARD END-->

                    </div>
                </ng-container>
            </div>

        </div>

    </div>
</div>