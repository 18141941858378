<div *ngIf="!showEmployees">
  <h1>You Do Not Have Access To This Page</h1>
</div>
<div *ngIf="showEmployees">
  <div class="d-flex justify-content-between">
    <div>
      <label for="searching">Search:</label>
      <input type="text" class="form-control form-control-sm" (keyup)="applyFilter($event)" id="searching">
    </div>
      <div class="col-sm-2">
        <label for="employeeStatus">Employee Status</label>
        <select
          class="form-control form-control-sm"
          [(ngModel)]="employeeStatus"
          (change) = "fetchAllEmployeesInfo(employeeStatus)"
        >
          <option value="Active">Active</option>
          <option value="Resigned">Resigned</option>
        </select>
      </div>
    <div class="d-flex">
      <button  style="margin-top: 28px;" mat-stroked-button class="btn-sm pr-border mr-2" [hidden]="level=='hotel'" (click)="downloadData(groupId)">Download Group Employee Details</button>
      <button  style="margin-top: 28px;" mat-stroked-button class="btn-sm pr-border mr-2" (click)="downloadData()">Download Hotel Employee Details</button>
      <button  style="margin-top: 28px;" mat-stroked-button class="btn-sm pr-border mr-2" (click)="createNewEmployee()" [hidden]="!showSalary">Add Employee</button>
    </div>
    
  </div>
  
  <br>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="table_wrapper">
  
      <ng-container matColumnDef="name" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
  
      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
        <td mat-cell *matCellDef="let element"> {{element.department}} </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>
  
      <ng-container matColumnDef="joinDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Join Date </th>
        <td mat-cell *matCellDef="let element"> {{element.joinDate  | date:'MMM d, yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Phone </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Email ID </th>
        <td mat-cell *matCellDef="let element"> {{element.emailId}} </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID Details</th>
        <td mat-cell *matCellDef="let element"><mat-icon (click) = "openIds(element.photoIds)" *ngIf="element.photoIds.length > 0">visibility</mat-icon> </td>
      </ng-container>

      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Comments</th>
        <td mat-cell *matCellDef="let element" class="truncate-cell" data-title = "{{element.comments}}"> {{element.comments}} </td>
      </ng-container>

      <ng-container matColumnDef="take_action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell class="tkaction" *matCellDef="let element">
          <div class="example-button-row" >
            <span title="Edit"><button type = "button" mat-icon-button (click)="editEmployee(element.employeeId)">
              <mat-icon >edit</mat-icon>
            </button></span>
            <span title="Delete" [hidden]="!showSalary"><button type = "button" mat-icon-button (click)="deleteEmployee(element.employeeId)">
              <mat-icon>delete</mat-icon>
            </button></span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    
    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>
