import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StayviewComponent } from './stayview.component';
import { AllocationBarComponent } from './allocation-bar/allocation-bar.component';

import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { AllocationMenuModule } from './allocation-menu/allocation-menu.module';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { RouterModule } from '@angular/router';
import { SwapRoomComponent } from './swap-room/swap-room.component';
import { SendVoucherEmailComponent } from './send-voucher-email/send-voucher-email.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { DiscountLogComponent } from './service-details/discount-log/discount-log.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    StayviewComponent,
    AllocationBarComponent,
    EditInvoiceComponent,
    SwapRoomComponent,
    SendVoucherEmailComponent,
    DiscountLogComponent,
  ],
  imports: [
    CommonModule, MatDividerModule, MatIconModule, MatListModule, MatFormFieldModule, MatInputModule,
    MatDatepickerModule, MatTabsModule, MatMenuModule, MatButtonModule, AllocationMenuModule, FormsModule, ReactiveFormsModule,
    RouterModule, TranslateModule, NgSelectModule, MatDialogModule
  ]
})
export class StayviewModule { }
