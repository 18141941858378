import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderComponent } from './order/order.component';
import { Globals } from 'src/app/core/globals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/user.service';
import { PosService } from 'src/app/core/pos.service';
import { CommunicationService } from 'src/app/core/communication.service';
import { PaymentsDetailsComponent } from '../stayview/payment-details/payment-details.component';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { DatePipe } from '@angular/common';
import { PosReportComponent } from './pos-report/pos-report.component';
import { BillComponent } from './bill/bill.component';
import { SelectPrinterComponent } from 'src/app/_shared/common_modals/select-printer/select-printer.component';
import { PrintService } from 'src/app/core/print.service';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss'],
})
export class PosComponent implements OnInit {
  hotelId: string;
  currentUser: string;
  currencyCode: string;

  today = new Date().toISOString().slice(0, 10);
  firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  toDate: Date;
  fromDate: Date;
  posName: string;
  config: any;
  posNames = [];
  serviceCategories: any[];
  posDashData: any;
  searchInput: string;
  currentPosConfig: any;
  currentOrder: any;
  unBilledData: any[];
  billedData: any[];
  tables = [];
  reportName = 'Item Consumption';

  printers: string[];
  selectedPrinter: any;
  isDefaultPrinterSelected = false;
  jspmDownload = {
    error: false,
    link: 'https://neodynamic.com/downloads/jspm/?v=3',
  };
  selectedOrder: any;
  userRole: string;
  userAccess: any;
  userHasAccess = true;
  userFeatures: any;
  showDeleteServiceOption = true;
  showDeleteOrderOption = true;
  showAddPaymentOption = true;
  showPOSBillOption = true;
  showEditOrderOption = true;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private globals: Globals,
    private route: ActivatedRoute,
    private posService: PosService,
    private printService: PrintService,
    public commS: CommunicationService,
    public datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.globals.currentUser.subscribe((user) => (this.currentUser = user));
    this.route.params.subscribe((params) => {
      this.hotelId = params.hotelId;
      this.getCurrentDate();
      this.globals.changeHotelID(this.hotelId);
    });
    this.globals.getCurrencyCode(this.hotelId).subscribe((data) => {
      localStorage.setItem('currencyCode', data);
      this.currencyCode = data;
    });

    this.commS.posOrderChange.subscribe((data) => {
      if (data === 'change') {
        this.getOrders();
      }
    });

    this.commS.balanceChange.subscribe((ev) => {
      if (ev !== 'null') {
        this.getOrders();
      }
    });
    this.posService.selectedPrinter.subscribe((pName) => {
      this.selectedPrinter = pName;
    });
    this.posService.print.subscribe((event) => this.mainPrintPromt(event));
    this.posService.kotPrint.subscribe((event) => this.promptKotPrint(this.selectedOrder));
    this.jspmWSStatus();
  }

  getPosConfig() {
    this.posService.getPosConfig(this.hotelId).subscribe(res => {
      this.userAccess = res?.conf?.userAccess;
      this.getConfig();
    });
  }

  checkAccess() {
    if (this.userRole !== 'admin' &&
      this.userAccess?.hasOwnProperty(this.posName) && 
      this.userAccess[this.posName]?.length &&
      !this.userAccess[this.posName].includes(this.currentUser)) {

        const dialogBody = {
          title: 'Access Denied',
          body: `You don't have access to ${this.posName}`,
          cancelBtn: 'OK',
        };
        this.dialog.open(DialogComponent, {
          data: dialogBody
        });
        this.userHasAccess = false;
        return false;   
    }
    this.userHasAccess = true;
    return true;
  }

  getOrders() {
    if (!this.posName || !this.userHasAccess) {
      return;
    }
    this.posService.getPosOrders(this.hotelId, this.posName, this.fromDate, this.toDate, this.today)
      .subscribe((data) => {
        this.posDashData = data;
        this.unBilledData = data.unbilled;
        this.posService.currentOrders = this.unBilledData;
        this.billedData = data.billed;
        this.billedData.forEach((ele) => {
          ele.payments.forEach((p) => {
            if (p.type_of_payment === 'Bill to Room') {
              ele.billedToRoom = true;
            }
          });
        });
      });
  }

  openOrder(mode: string, order?: any) {
    if (!this.userHasAccess) {
      return;
    }
    this.selectedOrder = order;
  
    let data: any;
    data = {
      mode,
      hotelId: this.hotelId,
      posName: this.posName,
      user: this.currentUser,
      servicesUsed: order?.services_used,
      details: order?.details,
      serviceCategories: this.serviceCategories,
      balance: order?.balance,
      orderId: order?.order_id,
      payments: order?.payments,
      invoiceId: order?.invoice_id,
      printers: this.printService.printers,
      currencyCode: this.currencyCode,
      invoiceNum: order?.invoice_num,
      complimentary: order?.is_complimentary,
    };
    if (mode === 'view' || mode === 'edit' || mode === 'bill') {
      this.userService.getInvoiceById(this.hotelId, order.invoice_id, 'True')
        .subscribe((data1) => {
          this.currentOrder = data1;
          data.currentOrder = this.currentOrder;
          data.config = this.currentPosConfig;
          data.showDeleteServiceOption = this.showDeleteServiceOption;
          this.dialog.open(OrderComponent, {
            width: '1000px',
            data: JSON.parse(JSON.stringify(data)),
          });
        });
    } else {
      this.dialog.open(OrderComponent, {width: '1000px', data });
    }
  }

  openComplimentary() {
    const data = {
      mode: 'new',
      hotelId: this.hotelId,
      posName: this.posName,
      user: this.currentUser,
      serviceCategories: this.serviceCategories,
      printers: this.printService.printers,
      currencyCode: this.currencyCode,
      complimentary: true,
    };

    this.dialog.open(OrderComponent, {
      width: '1000px',
      data: JSON.parse(JSON.stringify(data)),
    });
  }

  getCurrentDate() {
    this.userService.getCurrentDate(this.hotelId).subscribe(res => {
      this.toDate = res?.currentDate;
      this.fromDate = res?.currentDate;
      this.getUserFeatures();
    })
  }

  getConfig() {
    this.userService.getProductConfig(this.hotelId).subscribe((data) => {
      this.globals.setConfig(data.conf);
      this.config = data;
      if (!this.config.conf.hasOwnProperty('posData') ||this.config.conf.posData.length === 0) {
        return;
      }
      this.config.conf.posData.forEach((ele) => {
        this.posNames.push(ele.posName);
      });
      if (localStorage.getItem('posName') && this.posNames.includes(localStorage.getItem('posName'))) {
        this.posName = localStorage.getItem('posName');
      } else if (this.posNames?.length > 0) {
        this.posName = this.posNames[0];
      } else {
        return;
      }
      this.onPosChange(this.posName);
    });
  }

  onPosChange(name) {
    localStorage.setItem('posName', name);
    if (this.checkAccess()) {
      this.config.conf.posData.forEach((ele) => {
        if (ele.posName === name) {
          this.posService.currentPosConfig = ele;
          this.serviceCategories = ele.categories;
          this.currentPosConfig = ele;
        }
      });
      this.getOrders();
      this.getTables();
    }
  }

  onSearch(keyword, order) {
    if (keyword && keyword.length > 0) {
      const key = keyword.toLowerCase();
      switch (order) {
        case 'unbilled':
          this.unBilledData = this.posDashData?.unbilled.filter((data) => {
            return (
              data.details?.toLowerCase().indexOf(key) > -1 ||
              data.order_id?.toLowerCase().indexOf(key) > -1
            );
          });
          break;
        case 'billed':
          this.billedData = this.posDashData?.billed.filter((data) => {
            return (
              data.details?.toLowerCase().indexOf(key) > -1 ||
              data.order_id?.toLowerCase().indexOf(key) > -1 ||
              data.invoice_num?.toLowerCase().indexOf(key) > -1
            );
          });
          break;
      }
    } else {
      if (order === 'unbilled') {
        this.unBilledData = this.posDashData?.unbilled;
      } else if (order === 'billed') {
        this.billedData = this.posDashData?.billed;
      }
    }
  }

  deleteOrder(invoiceId, orderId) {
    const dialogBody = {
      title: 'DELETE ORDER',
      body: `Are you sure you want to delete this order?`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: dialogBody,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.posService
          .deletePosOrder(
            this.hotelId,
            this.posName,
            invoiceId,
            this.currentUser,
            orderId
          )
          .subscribe((data) => {
            if (data && 'error' in data) {
              this.dialog.open(DialogComponent, {
                data: { title: 'ERROR', body: data.error, cancelBtn: 'Ok' },
              });
            }
            this.getOrders();
          });
      }
    });
  }

  goToAddPayment(invoiceId, balance, billed) {
    this.dialog.open(PaymentsDetailsComponent, {
      disableClose: true,
      data: {
        allocId: null,
        invoiceId,
        mode: 'newEntry',
        balance,
        pos: true,
        billed,
        posName: this.posName,
      },
    });
  }

  openReport(reportName: string) {
    if (!this.userHasAccess) {
      return;
    }
    let reportData: any;
    if (reportName === 'Item Consumption') {
      reportData = this.getItemConsumptionReport();
    } else if (reportName === 'Payments') {
      reportData = this.getPaymentsReport();
    }
    this.dialog.open(PosReportComponent, {
      data: {
        reportName,
        startDate: this.fromDate,
        endDate: this.toDate,
        reportData,
      },
    });
  }

  getItemConsumptionReport() {
    const data = [];
    const invoiceTypes = ['billed', 'unbilled'];
    for (const type of invoiceTypes) {
      for (const item of this.posDashData[type]) {
        if (item.services_used) {
          for (const service of item.services_used) {
            if (data.length === 0) {
              data.push(this.pushService(service));
              continue;
            }
            let dup = false;
            for (const d of data) {
              if (d.service_name === service.service_name) {
                const serviceAmt = service.discounted_amount
                  ? service.discounted_amount
                  : service.service_amount;
                dup = true;
                d.quantity += service.quantity;
                d.service_amount += serviceAmt;
                d.tax += service.tax.total;
                d.total_amount += service.total_amount;
                break;
              }
            }
            if (!dup) {
              data.push(this.pushService(service));
            }
          }
        }
      }
    }
    return data;
  }

  pushService(service) {
    const serviceAmt = service.discounted_amount
      ? service.discounted_amount
      : service.service_amount;
    const data = {
      service_name: service.service_name,
      quantity: service.quantity,
      service_amount: serviceAmt,
      tax: service.tax.total,
      total_amount: service.total_amount,
    };
    return data;
  }

  getPaymentsReport() {
    const data: any = {};
    const invoiceTypes = ['billed', 'unbilled'];
    for (const type of invoiceTypes) {
      for (const item of this.posDashData[type]) {
        for (const payment of item.payments) {
          if (payment.type_of_payment in data) {
            data[payment.type_of_payment] += payment.amount;
          } else {
            data[payment.type_of_payment] = payment.amount;
          }
        }
      }
    }
    const dataArr = [];
    for (const payment in data) {
      if (data.hasOwnProperty(payment)) {
        dataArr.push({ type_of_payment: payment, amount: data[payment] });
      }
    }
    return dataArr;
  }

  viewBill(order) {
    order.services_used.data = order.services_used;
    const data = { order, config: this.currentPosConfig };
    this.dialog.open(BillComponent, { data });
  }

  jspmWSStatus() {
    const status = this.printService.jspmWSStatus();
    if (status) {
      return true;
    } else {
      this.jspmDownload.error = true;
      return false;
    }
  }

  promptKotPrint(order) {
    if (!this.jspmWSStatus()) {
      return;
    }

    this.userService.getInvoiceById(this.hotelId, order.invoice_id, 'True')
    .subscribe((data1) => {
      this.currentOrder = data1;
      printKotOrder();
    });

    const printKotOrder = () => {
      const posKotDefaultPrinter = localStorage.getItem('posKotDefaultPrinter');
    
      if (!posKotDefaultPrinter || posKotDefaultPrinter === 'None' || posKotDefaultPrinter === 'none' || posKotDefaultPrinter === 'null' ) {
        const dref = this.dialog.open(SelectPrinterComponent, { 
          data: {
            printers: this.printService.printers, 
            printerType: 'posKotDefaultPrinter'
          }});
    
        dref.afterClosed().subscribe(res => {
          if (res.success) {
            this.selectedPrinter = localStorage.getItem('posKotDefaultPrinter');
            this.printService.printer = this.selectedPrinter;
            this.escPosPrint('edit')
          }
        })
  
      } else {
        this.selectedPrinter = posKotDefaultPrinter;
        this.printService.printer = this.selectedPrinter;
        this.escPosPrint('edit')
      }
    }
  }

  printKot(order, printer) {
    const data = order;
    const conf = this.currentPosConfig;
    let slNo = 1;
  
    const cpj = new JSPM.ClientPrintJob();
    cpj.clientPrinter = new JSPM.InstalledPrinter(printer);
    const esc = '\x1B'; // ESC byte in hex notation
    const newLine = '\x0A'; // LF byte in hex notation
    let cmds = esc + '@'; // Initializes the printer (ESC @)

    data.services_used.forEach((item) => {
      let name = item.service_name;
      name = name.slice(0, 15);

      let space1 = 20 - name?.length;
      space1 = space1 > 0 ? space1 : space1 * -1;
      const woRateItem = slNo + '.' + name + ' '.repeat(space1) + item.quantity;

      cmds += woRateItem;
      cmds += newLine;
      slNo++;
    });

    cpj.printerCommands = cmds;
    console.log(cmds);
    cpj.sendToClient();
  }

  printBilledOrder(order) {
    const getInvoiceAndPrint = () => {
      this.userService.getInvoiceById(this.hotelId, order.invoice_id, 'True')
      .subscribe((data1) => {
        this.currentOrder = data1;
        this.escPosPrint('view');
      });
    }
  
    const posDefaultPrinter = localStorage.getItem('posDefaultPrinter')
    if (!posDefaultPrinter) {
      const dref = this.dialog.open(SelectPrinterComponent, { data: {printers: this.printService.printers, printerType: 'posDefaultPrinter'} });
      dref.afterClosed().subscribe(res => {
        if (res.success) {
          this.selectedPrinter = localStorage.getItem('posDefaultPrinter');
          this.printService.printer = this.selectedPrinter;
          getInvoiceAndPrint();
        }
      })
    } else {
      this.selectedPrinter = posDefaultPrinter;
      this.printService.printer = this.selectedPrinter;
      getInvoiceAndPrint();
    }
  
  }

  mainPrintPromt(mode: 'view' | 'edit') {
    const posDefaultPrinter = localStorage.getItem('posDefaultPrinter')

    if (!posDefaultPrinter || posDefaultPrinter === 'None' || posDefaultPrinter === 'none' || posDefaultPrinter === 'null' ) {
      const dref = this.dialog.open(SelectPrinterComponent, { 
        data: {
          printers: this.printService.printers, 
          printerType: 'posDefaultPrinter'
        }});
  
      dref.afterClosed().subscribe(res => {
        if (res.success) {
          this.selectedPrinter = localStorage.getItem('posKotDefaultPrinter');
          this.printService.printer = this.selectedPrinter;
          this.escPosPrint(mode)
        }
      })

    } else {
      this.selectedPrinter = posDefaultPrinter;
      this.printService.printer = this.selectedPrinter;
      this.escPosPrint(mode);
    }
  }

  escPosPrint(mode) {
    if (!this.jspmWSStatus()) {
      return;
    }
    this.posService.escPosPrint(mode, this.currentOrder, this.currentPosConfig);
  }

  // user access features
  getUserFeatures() {
    this.userService.userFeatures$.subscribe(res => {
      if (res) {
        this.userRole = res?.userRole;
        this.getPosConfig();
      }

      this.userFeatures = res?.live;
      if (this.userFeatures?.hasOwnProperty('deletePOSService')) {
        this.showDeleteServiceOption = this.userFeatures.deletePOSService;
      }
      if (this.userFeatures?.hasOwnProperty('deletePOSOrder')) {
        this.showDeleteOrderOption = this.userFeatures.deletePOSOrder;
      }
      if (this.userFeatures?.hasOwnProperty('editPOSOrder')) {
        this.showEditOrderOption = this.userFeatures.editPOSOrder;
      }
      if (this.userFeatures?.hasOwnProperty('addPOSPayment')) {
        this.showAddPaymentOption = this.userFeatures.addPOSPayment;
      }
      if (this.userFeatures?.hasOwnProperty('posBill')) {
        this.showPOSBillOption = this.userFeatures.posBill;
      }
    })
  }

  orderReadyToggle(order) {
    const newStatus = order.status === 'ready' ? 'Unbilled': 'ready';

    const dialogBody = {
      title: 'Change Status',
      body: `Are you sure you want to ${newStatus === 'ready' ? 'Mark ready ?': 'Mark not ready ?'}`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: dialogBody,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.posService.orderReadyStatusToggle(order.invoice_id, newStatus, this.hotelId).subscribe(res => {
          if (res?.success) {
            order.status = newStatus;
          }
        })
      }
    })
  }

  
  getTables() {
    this.posService.getTables(this.posName, this.hotelId).subscribe(res => {
      if (res?.success) {
        this.tables = res?.data;
      }   
    })
  }

  openLogsPage(){
    if (!this.userHasAccess) {
      return;
    }
    this.router.navigate(['/pos-logs/'+this.hotelId],{state: {posLogs: true, posNames: this.posNames}, queryParams :{user: this.currentUser }});
  }

  tableView() {
    if (!this.userHasAccess) {
      return;
    }
    const orders = {billed: this.billedData, unbilled: this.unBilledData}
    this.router.navigate([`/pos-table-view/${this.hotelId}`], 
      {queryParams: {
          posName: this.posName, 
          tables: JSON.stringify(this.tables),
          orders: JSON.stringify(orders),
          posConfig: JSON.stringify(this.currentPosConfig)
        }
      });
  }

  orderView() {
    if (!this.userHasAccess) {
      return;
    }
    const orders = {billed: this.billedData, unbilled: this.unBilledData}
    this.router.navigate([`/pos-order-view/${this.hotelId}`], 
      {queryParams: {
          posName: this.posName, 
          orders: JSON.stringify(orders),
        }
      });
  }
}
