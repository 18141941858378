<div>
    <div class="heading flex-space-bw">
        <h2>Accounts</h2>
    </div>
    <mat-divider></mat-divider>
    
    <div class="container" *ngIf="accountsConfig?.isEnabled">
        <div class="tabs">
            <ul>
                <ng-container *ngFor="let tab of tabItems; let i = index">
                    <li
                        (click)="changeCurrentTab(i);"
                        [ngClass]="{'active-tab': currentTab === tab.key || currentTab === 'gstr1' && tab.key === 'taxes'}">
                        {{tab.name}}
                    </li>
                </ng-container>
            </ul>
        </div>

        <div class="content">
            <app-receivables *ngIf="currentTab === 'receivables'"
                [accountsConfig]="accountsConfig">
            </app-receivables>

            <app-payables *ngIf="currentTab === 'payables'">
            </app-payables>

            <app-taxes *ngIf="currentTab === 'taxes'">
            </app-taxes>

            <app-gstr1 *ngIf="currentTab === 'gstr1'">
            </app-gstr1>

            <app-reconcilation *ngIf="currentTab === 'reconciliation'">
            </app-reconcilation>
        </div>
    </div>

    <ng-container *ngIf="!accountsConfig?.isEnabled && !accountsConfig.loading">
        <div class="text-danger mt-3 h4">
            Accounts is disabled for your hotel
        </div>
    </ng-container>
</div>