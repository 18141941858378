<div>
  <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
          <h2 *ngIf="mode == 'new'">Create Laundry</h2>
          <h2 *ngIf="mode == 'edit'">Edit Laundry</h2>
          <h2 *ngIf="mode == 'summary'">Laundry Summary</h2>
          <h2 *ngIf="mode == 'stock'">Laundry Item Stock</h2>
      </div>
      <div class="navbar-end">
          <button mat-stroked-button class="btn-sm pr-border backButton" (click)="close()">
          Back
          </button>
          <button *ngIf="submitting == false" [hidden]="mode == 'summary' || mode == 'stock'" [disabled]="mode == 'new' && listOfItems.length == 0" type="submit" mat-stroked-button class="btn-sm pr-border" (click)="submit()">Submit</button>
          <button *ngIf="submitting == true" type="submit" mat-stroked-button class="btn-sm pr-border" disabled>Submitting...</button>
      </div>
  </nav>
  
  <mat-divider></mat-divider>
  <div class="form-row mt-4" *ngIf="mode == 'summary'">
    <div class="form-group col-md-8 col-sm-8 col-xs-8">
        <label for="selectedDate">Select Date:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="selectedDate" [(ngModel)]="selectedDate" 
            name="selectedDate"/>
    </div>
    <div class="form-group col-md-2 col-sm-2 col-xs-2">
      <button [disabled]="!selectedDate || downloadDisabled" type="submit" mat-stroked-button class="btn-sm pr-border addButton" (click)="downloadSummary()">Download</button>
    </div>
    
  </div>
  <div class="form-row mt-4" *ngIf="mode !== 'summary' && mode !=='stock'">
    <div class="form-group col-md-2 col-sm-2 col-xs-2" *ngIf = "status == 'Sent'">
        <label for="sentDate">Sent Date:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="sentDate" [(ngModel)]="sentDate" 
            name="sentDate"/>
    </div>
    <div class="form-group col-md-2 col-sm-2 col-xs-2" *ngIf = "status == 'Received'">
        <label for="receivedDate">Received Date:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="receivedDate" [(ngModel)]="receivedDate"
            name="receivedDate"/>
    </div>
    <div class="form-group col-md-2 col-sm-2 col-xs-2">
        <label for="item">Item:</label>
        <select class="form-control form-control-sm" [(ngModel)]="item" required>
            <option *ngFor="let item of laundryItems" [value]="item">
                {{ item }}
            </option>
        </select>
    </div>
    <div class="form-group col-md-1 col-sm-1 col-xs-1">
        <label for="quantity">Quantity:</label>
        <input type="number" class="form-control form-control-sm" id="quantity" [(ngModel)]="quantity" min="1" name="quantity" value="" required/>
    </div>
    <div class="form-group col-md-1 col-sm-1 col-xs-1">
        <label for="status">Status:</label>
        <select class="form-control form-control-sm" [(ngModel)]="status" id="status">
            <option value="Sent" selected>Sent</option>
            <option value="Received">Received</option>
        </select>
    </div>
    <div class="form-group col-md-3 col-sm-3 col-xs-3">
        <label for="comments">Comments:</label>
        <input type="text" class="form-control form-control-sm" id="comments" [(ngModel)]="comments" name="comments" value="" />
    </div>
    <div class="form-group col-md-1 col-sm-1 col-xs-1">
      <button (click)="addListOfItems()" [hidden]="mode != 'new'" [disabled]="!item" mat-stroked-button class="addButton">Add Item</button>
    </div>
  </div>

  <span *ngIf="mode !== 'summary' && mode !=='stock'">* Change status to received to select received date *</span>
  
  <div *ngIf="listOfItems && listOfItems.length > 0">
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr class="text-center">
          <th>Sr. No</th>
          <th>Item</th>
          <th>Quantity</th>
          <th>Status</th>
          <th>Sent Date</th>
          <th>Received date</th>
          <th>Comments</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of listOfItems; let indexOfelement = index"
          class="text-center"
        >
          <td>{{ indexOfelement + 1 }}</td>
          <td>{{ data.item }}</td>
          <td>{{ data.quantity }}</td>
          <td>{{ data.status }}</td>
          <td>{{ data.sentDate }}</td>
          <td>{{ data.receivedDate }}</td>
          <td>{{ data.comments }}</td>
          <td>
            <mat-icon class="actions" (click)="removeItemFromList(indexOfelement)">
              delete
            </mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="laundryStockInfo && laundryStockInfo.length > 0">
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr class="text-center">
          <th>Sr. No</th>
          <th>Item Name</th>
          <th>Total Items</th>
          <th>Sent Items</th>
          <th>Current Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of laundryStockInfo; let indexOfelement = index"
          class="text-center"
        >
          <td>{{ indexOfelement + 1 }}</td>
          <td>{{ data.itemName }}</td>
          <td>{{ data.totalItems }}</td>
          <td>{{ data.totalItems - data.currentBalance}}</td>
          <td>{{ data.currentBalance }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>    

