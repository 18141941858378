<div *ngIf="!showAttendance">
  <h1>You Do Not Have Access To This Page</h1>
</div>
<div *ngIf="showAttendance">
  <div class="d-flex justify-content-between">
    <div class="form-row">
      <div class="form-group col-md-4 col-sm-4">
        <label for="month">Select Month:</label>
        <input type="month" class="form-control form-control-sm" id="month" [(ngModel)]="month" (change)="selectedMonth()" min="2022-09" />
      </div>
      <div class="form-group col-md-4 col-sm-4">
        <label for="department">Select Department:</label>
        <select id="department" class="form-control form-control-sm" [(ngModel)]="selectedDepartment" (change)="selectedDept()">
          <option value="All">All</option>
          <option *ngFor="let option of attendanceData" [value]="option.department"> {{ option.department }}</option>
        </select>
      </div>
      <div class="form-group col-md-4 col-sm-4">
        <label for="employeeStatus">Employee Status:</label>
        <select id="employeeStatus" class="form-control form-control-sm" [(ngModel)]="employeeStatus" (change)="selectedMonth()">
          <option value="Active"> Active</option>
          <option value="Resigned"> Resigned</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      
      <div>
        <button mat-stroked-button class="btn-sm pr-border mr-2" style="margin-bottom: 1px" [hidden]="level == 'hotel' || !allowSalaryProcessing" (click)="downloadSalary(groupId)">
          Process Salary Group
        </button>
        <button *ngIf = "level == 'hotel'" style="margin-top: 30px" mat-stroked-button class="btn-sm pr-border mr-2" [hidden]="!allowSalaryProcessing" (click)="downloadSalary()">
          Process Salary Hotel
        </button>
        <button *ngIf = "level != 'hotel'"  mat-stroked-button class="btn-sm pr-border mr-2" [hidden]="!allowSalaryProcessing" (click)="downloadSalary()">
          Process Salary Hotel
        </button>
      </div>
      <div>
        <button  mat-stroked-button class="btn-sm pr-border mr-2" style="margin-bottom: 1px" [hidden]="level == 'hotel'" (click)="downloadAttendance(groupId)">
          Download Attendance Group
        </button>
        <button *ngIf = "level == 'hotel'" style="margin-top: 30px" mat-stroked-button class="btn-sm pr-border mr-2" (click)="downloadAttendance()">
          Download Attendance Hotel
        </button>
        <button *ngIf = "level != 'hotel'" mat-stroked-button class="btn-sm pr-border mr-2" (click)="downloadAttendance()">
          Download Attendance Hotel
        </button>
      </div>
      <button mat-stroked-button class="btn-sm pr-border mr-2 upload-button" (click)="updateAttendance(employeesAttendance)">
        Update
      </button>
    </div>
  </div>
  <br />
  <table class="table table_wrapper">
    <thead class="thead-dark">
      <th style="height: 30px; padding-left: 10px; padding-right: 10px">
        Employees
      </th>

      <th style="padding-top: 0px; padding-bottom: 0px">
        <table class="table" style="margin-bottom: 0%">
          <tr>
            <th *ngFor="let date of allDates">
              {{ date | date : " dd " }}
              {{ date | date : " EEE " }}
            </th>
          </tr>
        </table>
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let data of employeesAttendance; let i = index">
        <th
          class="names-col"
          style="height: 30px; padding-left: 10px; padding-right: 10px"
        >
          {{ data.name }}
        </th>
        <td style="padding-top: 0px; padding-bottom: 0px">
          <table class="table">
            <tr>
              <td *ngFor="let date of data.attendance" style="height: 20px">
                <select style="width: 40px" [(ngModel)]="date.shortform" [disabled]="date.disable">
                  <option value=" "></option>
                  <option
                    *ngFor="let option of attendanceTypes"
                    [value]="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
