
<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand" *ngIf = "!showBackbutton">
      Enter PMS Operations Data
    </div>
    <div class="navbar-brand" *ngIf = "showBackbutton">
        Modify PMS Operations Data
    </div>
    <div class="navbar-end" *ngIf = "showBackbutton">
        <div class="navbar-item">
            <button class="buttonStyle" (click)="back()">
                Back
            </button>
        </div>
    </div>
</nav>
<hr>
<div class="mat-elevation-z8">
    <div id="row0">

    </div>
    <h2>Daily </h2>
    <div id="row1">
        
    </div>
    <!-- <br> -->
    <h2>Weekly </h2>
    <div id="row2">

    </div>
    <br>
    <h2>Monthly </h2>
    <div id="row3">

    </div>
    <br>
    <br>
    <button (click)="submit()" mat-raised-button class="submitButton">submit</button>
</div>


