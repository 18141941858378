<div *ngIf="!showSalary">
    <h1>You Do Not Have Access To This Page.</h1>
</div>
<div *ngIf="showSalary">
    <div class="d-flex justify-content-between">
        <div class="form-row">
            <div class="form-group col-md-12 col-sm-12">
                <label for="month">Select Month:</label>
                <input type="month" class="form-control form-control-sm" id="month" (change)="selectedMonth()" [(ngModel)]="month"  min="2022-09">
            </div>
        </div>
        <div class="d-flex">
            <button  style="margin-top: 28px;" mat-stroked-button class="btn-sm pr-border mr-2" [hidden]="level=='hotel'" (click)="generateBankTransFile(groupId)">Generate Bank Transfer File Group</button>
            <button  style="margin-top: 28px;" mat-stroked-button class="btn-sm pr-border mr-2" (click)="generateBankTransFile()">Generate Bank Transfer File Hotel</button>
            <button  style="margin-top: 28px;" mat-stroked-button class="btn-sm pr-border mr-2" [hidden]="level=='hotel'" (click)="downloadSalary(groupId)">Download Salary Group</button>
            <button  style="margin-top: 28px;" mat-stroked-button class="btn-sm pr-border mr-2" (click)="downloadSalary()">Download Salary Hotel</button>
        </div>
    </div>
    <br>
    <div class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol}}</mat-header-cell>
                <mat-cell *matCellDef="let element "> {{element[disCol]}}
                </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>
