<div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <h2 *ngIf="mode == 'new'">Create Entry</h2>
            <h2 *ngIf="mode == 'edit'">Edit Entry</h2>
        </div>
        <div class="navbar-end">
            <button mat-stroked-button class="btn-sm pr-border backButton" (click)="close()">
            Back
            </button>
        </div>
    </nav>
    
    <mat-divider></mat-divider>

    <div class="form-row mt-4">
        <div class="form-group col-md-2 col-sm-2 col-xs-2">
            <label for="date">Date:</label>
            <input type="date" class="form-control form-control-sm" onkeydown="return false" id="date" [(ngModel)]="date" name="date"/>
        </div>
        <div class="form-group col-md-3 col-sm-3 col-xs-3">
            <label for="itemDetails">Item Details:</label>
            <input type="text" class="form-control form-control-sm" id="itemDetails" [(ngModel)]="itemDetails" name="itemDetails" value="" required/>
        </div>
        
        <div class="form-group col-md-1 col-sm-1 col-xs-1">
            <label for="status">Status:</label>
            <select class="form-control form-control-sm" [(ngModel)]="status" id="status">
                <option value="Lost" selected>Lost</option>
                <option value="Found">Found</option>
            </select>
        </div>
        <div class="form-group col-md-1 col-sm-1 col-xs-1">
            <label for="itemStatus">Item Status:</label>
            <select class="form-control form-control-sm" [(ngModel)]="itemStatus" id="itemStatus">
                <option value="Open" selected>Open</option>
                <option value="Close">Close</option>
            </select>
        </div>
        
        <div class="form-group col-md-5 col-sm-5 col-xs-5">
            <label for="comments">Comments:</label>
            <input type="text" class="form-control form-control-sm" id="comments" [(ngModel)]="comments" name="comments" value="" />
        </div>
    </div>
    
    <div class="ml-auto">
        <button (click)="submit()" [disabled]="!itemDetails" mat-stroked-button class="btn-sm">Submit</button>
      </div>
</div>