<div class="tab-container">
    
    <div class="container">

        <div class="row pt-1 pb-2 bg-white">
            <div class="col-sm-2">
                <label for="From Date">From Date:</label>
                <input type="date" class="form-control" id="from-date" max="{{endDate}}" [(ngModel)]="startDate">
            </div>
            <div class="col-sm-2">
                <label for="To Date">To Date:</label>
                <input type="date" class="form-control" id="to-date" min="{{startDate}}" [(ngModel)]="endDate">
            </div>
            <div class="col-sm-2">
                <label for="To Date">Payment Mode</label>
                <select class="form-control" [(ngModel)]="selectedMode">
                    <option *ngFor="let item of paymentModes" [value]="item">{{item}}</option>
                </select>
            </div>

            <div *ngIf="reconcilationData?.configLevel === 'chain'"
                class="col-sm-2">
                <label for="To Date">Hotel:</label>
                <select class="form-control"
                    [(ngModel)]="selectedHotel">
                    <option value="">All</option>
                    <option *ngFor="let hotel of hotels" [value]="hotel.id">
                        {{hotel.name}}
                    </option>
                </select>
            </div>

            <div class="col-sm-2 submit-btn">
                <button mat-raised-button class="primary_btn" (click)="getData()">Submit</button>
            </div>
        </div>

        <table class="table mt-3">
            <thead class="thead-dark">
                <tr>
                    <th>Date</th>
                    <th>POS Name</th>
                    <th *ngIf="reconcilationData?.configLevel === 'chain'">Hotel</th>
                    <th>Invoice No.</th>
                    <th>Vendor / Company</th>
                    <th>Comments</th>
                    <th>Image</th>
                    <th>User</th>
                    <th class="text-right">Paid In</th>
                    <th class="text-right">Paid Out</th>
                </tr>
            </thead>
    
            <tbody>
                <tr class="dark-row">
                    <td>Total</td>
                    <td [attr.colspan]="reconcilationData?.configLevel === 'chain' ? 8 : 7" class="text-right">
                        {{reconcilationData?.totalPaidIn | number: '1.2-2'}}
                    </td>
                    <td class="text-right">{{reconcilationData?.totalPaidOut | number: '1.2-2'}}</td>
                </tr>

                <tr *ngFor="let item of reconcilationData?.payments">
                    <td>{{item.date | date: 'dd MMM YY'}}</td>
                    <td>{{item.posName}}</td>
                    <td *ngIf="reconcilationData?.configLevel === 'chain'">{{item.hotelName}}</td>
                    <td>{{item.invoiceNum ? item.invoiceNum : item.folioNum}}</td>
                    <td>{{item.vendorName}} {{item.companyName}}</td>
                    <td>{{item.comments}}</td>
                    <td>
                        <a [href]="item.imgUrl" target="_blank">
                            <img *ngIf="item.imgUrl" [src]="item.imgUrl" width="30" height="30">
                        </a>
                    </td>
                    <td>{{item.user}}</td>
                    <td class="text-right">
                        <ng-container *ngIf="item.paidIn">
                            {{item.amount | number: '1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">{{item.paidIn ? '' : item.amount | number: '1.2-2'}}</td>
                </tr>
          
            </tbody>
        </table>

    </div>

    <div class="d-flex justify-content-end">
        <nav>
            <ul class="pagination">
                <li class="page-item">
                    <a class="page-link" (click)="previousPage()">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link active-page">
                        {{currentPage}}
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" (click)="nextPage()">Next</a>
                </li>
            </ul>
          </nav>
    </div>

</div>