import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { Globals } from 'src/app/core/globals.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-create-issue',
  templateUrl: './create-issue.component.html',
  styleUrls: ['./create-issue.component.scss'],
})
export class CreateIssueComponent implements OnInit {
  issueForm: FormGroup;
  formHeading = '';
  loading = false;
  imgfile: File;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateIssueComponent>,
    public fb: FormBuilder,
    private maintService: MaintenanceIssueService,
    public global: Globals,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.setFormHeading();
    this.initIssueForm(this.data?.issueData);

    this.areaValidator();

    if (this.data.mode === 'issue-update') {
      this.disableForIssueUpdate();
    }
  }

  setFormHeading() {
    let formHeading = '';
    if (this.data.mode === 'new') {
      formHeading = 'Create Issue';
    } else if (this.data.mode === 'edit') {
      formHeading = 'Edit Issue';
    } else if (this.data.mode === 'issue-update') {
      formHeading = 'Update Issue';
    }

    this.formHeading = formHeading;
  }

  areaValidator() {
    this.issueForm.controls.area.valueChanges.subscribe((value) => {
      if (value === 'Room') {
        this.issueForm.controls.roomNo.setValidators(Validators.required);
        this.issueForm.controls.commonArea.clearValidators();
      } else if (value === 'Common Area') {
        this.issueForm.controls.commonArea.setValidators(Validators.required);
        this.issueForm.controls.roomNo.clearValidators();
      }
      this.issueForm.controls.commonArea.updateValueAndValidity();
      this.issueForm.controls.roomNo.updateValueAndValidity();
    });
  }

  disableForIssueUpdate() {
    const editableFeilds = [
      'priority',
      'status',
      'assignedTo',
      'comment',
      'commentBy',
    ];

    Object.keys(this.issueForm.controls).forEach((key) => {
      if (!editableFeilds.includes(key)) {
        this.issueForm.controls[key].disable();
      }
    });
  }

  initIssueForm(data?) {
    this.issueForm = this.fb.group({
      heading: [data?.heading ? data.heading : '', Validators.required],
      area: [data?.area ? data.area : '', Validators.required],
      typeOfOperation: [
        data?.typeOfOperation ? data.typeOfOperation : '',
        Validators.required,
      ],
      roomNo: [data?.roomNo ? data.roomNo : ''],
      status: [data?.status ? data.status : 'New', Validators.required],
      commonArea: [data?.commonArea ? data.commonArea : null],
      priority: [data?.priority ? data.priority : 'low', Validators.required],
      assignedTo: [data?.assignedTo ? data.assignedTo : ''],
      description: [data?.description ? data.description : ''],
      comment: [''],
      commentBy: [''],
      imgUrl: [data?.imgUrl ? data.imgUrl : '']
    });
  }

  closeDialog(val?) {
    this.dialogRef.close(val);
  }

  submitClick() {
    if (this.imgfile) {
      this.uploadAndSubmit();
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.loading = true;
    if (this.data.mode === 'new') {
      this.createIssue();
    } else if (this.data.mode === 'edit') {
      this.editIssue();
    } else if (this.data.mode === 'issue-update') {
      this.updateIssue();
    }
  }

  createIssue() {
    this.maintService
      .newIssue(this.data.hotelId, this.issueForm.value)
      .subscribe(
        (data) => {
          if (data?.success) {
            this.global.snackBarFunc(data.message);
          }
          this.closeDialog({ success: true });
        },
        (err) => {
          this.closeDialog();
          this.global.snackBarFunc('Error');
        }
      );
  }

  editIssue() {
    this.maintService
      .editIssue(
        this.data.issueData.hotelId,
        this.data.issueData.id,
        this.issueForm.value
      )
      .subscribe(
        (data) => {
          if (data?.success) {
            this.global.snackBarFunc(data.message);
          }
          this.closeDialog({ success: true });
        },
        (err) => {
          this.closeDialog(false);
          this.global.snackBarFunc('Error');
        }
      );
  }

  updateIssue() {
    this.maintService
      .issueUpdate(
        this.data.issueData.hotelId,
        this.data.issueData.id,
        this.issueForm.value
      )
      .subscribe((data) => {
        if (data?.success) {
          this.closeDialog({ success: true });
        }
      });
  }

  uploadAndSubmit() {
    this.loading = true;
    const ts = new Date().getTime();
    this.userService
      .uploadFile(this.imgfile, `${this.data.hotelId}/${ts}-${this.imgfile.name}`)
      .subscribe(
        (img) => {
          this.issueForm.controls.imgUrl.setValue(img.url);
          this.onSubmit();
        },
        (err) => {}
      );
  }

  fileChange(e) {
    this.imgfile = e.files[0];
  }

  openTab(url) {
    window.open(url, '_blank')
  }
}
