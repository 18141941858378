<div>
    <div class="aio_heading">
      <h2>Dashboard</h2>
    </div>
    <mat-divider></mat-divider>
  
    <div class="container-fluid common_content">

        <div class="section_container">

            <div class="section_box">
                <div class="heading">Occupancy (today) </div>
                
                <div class="lg-value" style="color: #00c587;">
                    {{dashData.occupancyToday}} %
                </div>
            </div>

            <div class="section_box">
                <div class="heading">ADR (today)</div>
                
                <div class="lg-value" >
                    <span style="color: #7a0000;">{{currencyCode}}</span>&nbsp;
                    <span>{{dashData.adrToday}}</span>
                </div>
            </div>

            <div class="section_box">
                <div class="heading">Arrival</div>
                    
                <div class="content">
                    <div [hidden]="!arrivalChart">
                        <canvas id="arrivalCanvas"></canvas>
                    </div>
                </div>
            </div>
    
            <div class="section_box">
                <div class="heading">Checkout</div>
                    
                <div class="content">
                    <div [hidden]="!checkoutChart">
                        <canvas id="checkoutCanvas"></canvas>
                    </div>
                </div>
            </div>

            <div class="section_box">
                <div class="heading">Room Status</div>
                    
                <div class="content">
                    <div [hidden]="!roomStatusChart">
                        <canvas id="roomStatusCanvas"></canvas>
                    </div>

                </div>
            </div>

            <div class="section_box">
                <div class="heading">Available Rooms</div>
                    
                <div class="content available-room-content">
                    <ul class="mt-4">
                        <li *ngFor="let room of dashData.roomWiseSummary; let i=index">
                            <span>{{room.displayName}} - </span>
                            <span>{{room.data.available}}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="section_box">
                <div class="heading">Revenue (today)</div>
                    
                <div class="content">
                    <div [hidden]="!revenueTodayChart">
                        <canvas id="revenueTodayCanvas"></canvas>
                    </div>
                </div>
            </div>

            <div class="section_box">
                <div class="heading">Balance (Payments)</div>
                
                <div class="lg-value" >
                    <span style="color: #32f701;">{{currencyCode}}</span>&nbsp;
                    <span>{{dashData.balanceToday}}</span>
                </div>
            </div>

            <div class="section_box lg">
                <div class="heading">Revenue Yearly</div>
                    
                <div class="content">
                    <div [hidden]="!revenueYearlyChart">
                        <canvas id="revenueYearlyCanvas"></canvas>
                    </div>
                </div>
            </div>

            <div class="section_box lg">
                <div class="heading">Occupancy & ADR</div>
                    
                <div class="content">
                    <div [hidden]="!occuAdrChart">
                        <canvas id="occuAdrCanvas"></canvas>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>