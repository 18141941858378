<div>
    <div class="aio_heading">
      <h2>Table View - {{posName}}</h2>

        <button mat-raised-button class="primary_btn"
            routerLink="/pos/{{hotelId}}">
            Back
        </button>
    </div>
    <mat-divider></mat-divider>
  
    <div class="container-fluid common_content">

        <div class="table_container">
            <div *ngFor="let category of tables">
                <div class="category_row">
                    <span *ngIf="category.category">{{category.category}}</span>
                </div>

                <div class="tables">
                    <ng-container  *ngFor="let table of category.tables">

                        <div class="table" [matMenuTriggerFor]="tableCard"
                            (click)="tableClick(table)">

                            <div class="header"
                                [ngClass]="{
                                    'billed': table.orderInfo?.billed_date, 
                                    'unbilled': table.orderInfo && !table.orderInfo.billed_date,
                                    'empty': !table.orderInfo
                                }">
                                <span>{{table.tableNum}}</span>
                                
                                <span *ngIf="table.orderInfo && table.orderInfo?.status !== 'Billed'">
                                    <svg (click)="orderReadyToggle(table.orderInfo); $event.stopPropagation()"
                                    [matTooltip]="table.orderInfo?.status === 'ready' ? 'Mark Not Ready' : 'Mark Ready'"
                                    xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" id="order-ready-icon">
                                    <path [ngStyle]="table.orderInfo?.status === 'ready' && {'fill': '#03c303'}"
                                        d="M88 0C74.7 0 64 10.7 64 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C120.5 112.3 128 119.9 128 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C119.5 47.7 112 40.1 112 24c0-13.3-10.7-24-24-24zM32 192c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96H288c53 0 96-43 96-96h16c61.9 0 112-50.1 112-112s-50.1-112-112-112H352 32zm352 64h16c26.5 0 48 21.5 48 48s-21.5 48-48 48H384V256zM224 24c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C232.5 112.3 240 119.9 240 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C231.5 47.7 224 40.1 224 24z" />
                                    </svg>
                                </span>
                            </div>
                            
                            <!-- TABLE BOX -->
                            <ng-container *ngIf="table.orderInfo">
                                <div>{{table.orderInfo.minutesElapsed}} mins</div>
                                <div>{{table.orderInfo.total_amount | currency: currency}}</div>
                            </ng-container>

                            
                            <!-- TABLE CARD -->
                            <mat-menu #tableCard="matMenu">
                                <div class="table_card" *ngIf="table.orderInfo">
                                    <div class="info_section">
                                        <div>
                                            <div>{{table.orderInfo.order_id}}</div>
                                            <div>Staff: {{table.orderInfo.posExtraData?.staff}}</div>

                                            <div>Details: {{table.orderInfo.details}}</div>
                                            <div>Created: {{table.orderInfo.date | date: 'dd/MM hh:mm a'}}</div>
                                        </div>

                                        <div class="service_used">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Qty</th>
                                                        <th class="text-right">Amt</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr *ngFor="let service of table.orderInfo.services_used">
                                                        <td>{{service.service_name}}</td>
                                                        <td>{{service.quantity}}</td>
                                                        <td class="text-right">{{service.discounted_amount ? service.discounted_amount : service.total_amount}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                        <div>
                                            <div>Balance: {{table.orderInfo.balance}}</div>
                                            <div>Total: {{table.orderInfo.total_amount}}</div>
                                        </div>
                                    </div>

                                    <div class="actions">
                                        <button mat-raised-button (click)="openOrder(table.orderInfo)">Edit</button>
                                        <button mat-raised-button (click)="viewBill(table.orderInfo)">View</button>
                                        <button mat-raised-button (click)="deleteOrder(table)"
                                            *ngIf="table.orderInfo.status !== 'Billed'">
                                            Delete
                                        </button>
                                        <button mat-raised-button (click)="print(table.orderInfo)">Print</button>
                                        <button mat-raised-button (click)="openOrder(table.orderInfo, null, true)"
                                            *ngIf="table.orderInfo.status !== 'Billed'">
                                            Bill
                                        </button>
                                        <button mat-raised-button (click)="goToAddPayment(table.orderInfo)">Payment</button>
                                    </div>
                                </div>
                            </mat-menu>
                            <!-- TABLE CARD END-->

                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>
</div>